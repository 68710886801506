import React from 'react';
import ReactDOM from 'react-dom';
import MeetupApp from './MeetupApp'

//import { CometChat } from "@cometchat-pro/chat";
//import { COMETCHAT_CONFIG } from './consts';


// Fix vh units on mobile:
function setVH() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setVH();
window.addEventListener('resize', setVH);

// Force the page to reload after 3 hours
if (!localStorage.disablePageRefresh) {
    setTimeout(() => {
        window.location.reload(true);
    }, 1000 * 60 * 60 * 3);
} else {
    console.log('Forced page refresh disabled');
}

// CometChat initialization
//const appID = COMETCHAT_CONFIG.APP_ID;
//const region = COMETCHAT_CONFIG.REGION;
//
//const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
//CometChat.init(appID, appSetting).then(
    //() => {
        ////console.log("Sucessfully initialized CometChat");
        //if(CometChat.setSource) {
            //CometChat.setSource("ui-kit", "web", "reactjs");
        //}
    //},
    //error => {
        //console.log("Error initializing CometChat:", error);
    //}
//);


interface RunConfig {
    root: HTMLElement;
}

ReactDOM.render(
    <MeetupApp />,
    document.getElementById('root')
);

