import React from 'react';

import { ID } from '../../../models';
import { UserContext } from '../../../controllers/UserController';
import { CurrentConversationState } from '../../MeetupView/controllers/ConversationController';
import {
    VideoCallPeer,
    PeerMediaType,
    VideoSubscriptionInfo,
    SubscriptionLevel,
    MediaQualityLevel,
    MediaState,
} from './models';
import {
    VideoCallClient,
    PeerMediaCallback,
} from './clients/VideoCallClient';


interface MediaTypeStatus {
    available: boolean;
    subscriptionLevel: SubscriptionLevel;
}

interface PeerMediaStatus {
    audio: MediaTypeStatus;
    video: MediaTypeStatus;
}

// Always use a fresh instance to prevent modifications from overwriting the
// default values
const getDefaultMediaStatus = () => ({
    audio: {
        available: false,
        subscriptionLevel: SubscriptionLevel.OFF,
    },
    video: {
        available: false,
        subscriptionLevel: SubscriptionLevel.OFF,
    },
});

interface MediaSubscriptions {
    processing: boolean;
    subscriptions: Map<number, MediaQualityLevel>;
}

interface PeerSubscriptions {
    audio: MediaSubscriptions;
    video: MediaSubscriptions;
}

const PEER_MEDIA_TYPES: PeerMediaType[] = ["audio", "video"];

function usePeerReceiver(
    conversationState: CurrentConversationState,
    client: VideoCallClient,
): [boolean, Map<ID, VideoCallPeer>] {
    const conversationId = conversationState.conversation?.id ?? null;
    const conversationParticipantIds = conversationState.conversation?.participantIds;

    const [mediaStatus, setMediaStatus] = React.useState<Map<ID, PeerMediaStatus>>(new Map());
    const [media, setMedia] = React.useState<Map<ID, MediaState>>(new Map());
    const [subscriptions, setSubscriptions] = React.useState<Map<ID, PeerSubscriptions>>(new Map());
    const [, setNextSubscriptionId] = React.useState(0);

    const [mediaAvailabilityInitialized, setMediaAvailabilityInitialized] = React.useState(false);
    const [mediaInitialized, setMediaInitialized] = React.useState(false);

    const isJoined = conversationState.joinStatus === "success";
    React.useEffect(() => {
        setMediaAvailabilityInitialized(false);
        setMediaInitialized(false);

        setMediaStatus(new Map());
        setMedia(new Map());
        setSubscriptions(new Map());
        setNextSubscriptionId(0);

        // TODO: possibly move this back to the respective hook cleanup methods
        client.onPeerMediaAvailabiiltyChanged(null);
        client.onPeerMediaChanged(null);
    }, [client, conversationId]);


    const updateMedia: PeerMediaCallback = React.useCallback((
        userId, mediaType, mediaTrack,
    ) => {
        setMedia(media => {
            const peerMedia = {...(media.get(userId) ?? {})};
            peerMedia[mediaType] = mediaTrack as any;
            const newMedia = new Map(media);
            newMedia.set(userId, peerMedia);
            return newMedia;
        });
        setSubscriptions(subscriptions => {
            const userSubscriptions = subscriptions.get(userId);
            if (userSubscriptions && userSubscriptions[mediaType].processing) {
                userSubscriptions[mediaType].processing = false;
                return new Map(subscriptions);

                //const updatedUserSubscription = {...userSubscriptions};
                //updatedUserSubscription[mediaType] = {
                    //processing: false,
                    //subscriptions: userSubscriptions[mediaType].subscriptions,
                //};
                //const newSubscriptions = new Map(subscriptions);
                //newSubscriptions.set(userId, updatedUserSubscription);
                //return newSubscriptions;
            }
            return subscriptions;
        });
    }, []);
    React.useEffect(() => {
        client.onPeerMediaChanged(updateMedia);
        setMediaInitialized(true);

        //return () => client.onPeerMediaChanged(null);
    }, [client, conversationId, updateMedia]);


    const updateMediaStatus = React.useCallback((
        userId: ID,
        mediaType: PeerMediaType,
        available: boolean,
        subscriptionLevel?: SubscriptionLevel,
    ): void => {
        setMediaStatus(mediaStatus => {
            const peerMediaStatus = mediaStatus.get(userId)
                ?? getDefaultMediaStatus();
            const mediaTypeStatus = peerMediaStatus[mediaType];
            mediaTypeStatus.available = available;

            if (!available) subscriptionLevel = SubscriptionLevel.OFF;
            if (subscriptionLevel !== undefined) mediaTypeStatus.subscriptionLevel = subscriptionLevel;

            if (subscriptionLevel === SubscriptionLevel.OFF) {
                updateMedia(userId, mediaType, undefined);
            }

            const newMediaStatus = new Map(mediaStatus);
            newMediaStatus.set(userId, peerMediaStatus);
            return newMediaStatus;
        });
    }, [updateMedia]);
    /* Subscribe to media availability changes */
    React.useEffect(() => {
        client.onPeerMediaAvailabiiltyChanged(updateMediaStatus);
        setMediaAvailabilityInitialized(true);

        //return () => client.onPeerMediaAvailabiiltyChanged(null);
    }, [client, conversationId, updateMediaStatus]);


    /* Update the client subscriptions when the peer subscriptions change */
    React.useEffect(() => {
        for (const [userId, peerSubscriptions] of subscriptions.entries()) {
            const peerMediaStatus: PeerMediaStatus = mediaStatus.get(userId)
                    ?? getDefaultMediaStatus();

            for (const mediaType of PEER_MEDIA_TYPES) {
                const mediaSubscriptions = peerSubscriptions[mediaType];
                if (mediaSubscriptions.processing)
                    // There is already a subscription in progress, don't start
                    // another one concurrently
                    continue;

                let maxSubscribedLevel = SubscriptionLevel.OFF;
                for (const subscriptionLevel of mediaSubscriptions.subscriptions.values()) {
                    maxSubscribedLevel = Math.max(
                        subscriptionLevel, maxSubscribedLevel
                    );
                }
                const mediaTypeStatus = peerMediaStatus[mediaType];
                if (
                    maxSubscribedLevel === SubscriptionLevel.OFF
                    && mediaTypeStatus.subscriptionLevel > SubscriptionLevel.OFF
                ) {
                    // There is no subscription but media was subscribed to
                    // previously, unsubscribe
                    updateMediaStatus(userId, mediaType, true, SubscriptionLevel.OFF);
                    client.unsubscribePeerMedia(userId, mediaType);

                } else if (
                    maxSubscribedLevel !== SubscriptionLevel.OFF // ~ > OFF
                    && maxSubscribedLevel !== mediaTypeStatus.subscriptionLevel
                    && mediaTypeStatus.available
                ) {
                    mediaSubscriptions.processing = true;
                    // Media is available and a higher or lower subscription level
                    // when what's currently present is required, (re)subscribe
                    updateMediaStatus(userId, mediaType, true, maxSubscribedLevel);
                    client.subscribePeerMedia(
                        userId, mediaType, maxSubscribedLevel
                    );
                }
            }
        }
    }, [client, updateMedia, updateMediaStatus, subscriptions, mediaStatus]);


    const subscribe = React.useCallback((
        userId: ID,
        mediaType: PeerMediaType,
        requestedLevel: MediaQualityLevel,
    ): number => {
        let subscriptionId = 0;
        setNextSubscriptionId(nextSubscriptionId => {
            subscriptionId = nextSubscriptionId;
            return nextSubscriptionId + 1;
        });

        //let alreadySubscribed = false;
        setSubscriptions(subscriptions => {
            const newSubscriptions = new Map(subscriptions);

            const peerSubscriptions: PeerSubscriptions = subscriptions.get(userId) ?? {
                audio: {
                    processing: false,
                    subscriptions: new Map(),
                },
                video: {
                    processing: false,
                    subscriptions: new Map(),
                },
            };
            peerSubscriptions[mediaType].subscriptions.set(
                subscriptionId, requestedLevel
            );
            newSubscriptions.set(userId, peerSubscriptions);

            return newSubscriptions;
        });

        return subscriptionId;
    }, []);

    const unsubscribe = React.useCallback((
        userId: ID,
        mediaType: PeerMediaType,
        subscriptionId: number
    ) => {
        setSubscriptions(subscriptions => {
            const peerSubscriptions = subscriptions.get(userId);

            let deletedSubscription = true;
            if (
                !peerSubscriptions
                || !peerSubscriptions[mediaType].subscriptions.delete(subscriptionId)
            ) {
                deletedSubscription = false;
                console.warn(`Trying to cancel non-existing ${mediaType} subscription${subscriptionId}`);
            }

            if (deletedSubscription && peerSubscriptions) {
                const newSubscriptions = new Map(subscriptions);
                subscriptions.set(userId, peerSubscriptions);
                return newSubscriptions;
            } else {
                return subscriptions;
            }
        });
    }, []);


    const user = React.useContext(UserContext)!;
    const selfUserId = user.participantIdentity.id;
    const [peers, setPeers] = React.useState<Map<ID, VideoCallPeer>>(new Map());
    React.useEffect(() => {
        setPeers(peers => {
            const newPeers: Map<ID, VideoCallPeer> = new Map();
            let updateNecessary = false;

            for (const userId of conversationParticipantIds ?? []) {
                if (userId === selfUserId)
                    // Don't include this user
                    continue;

                const peerMedia = media.get(userId);
                const prevPeer = peers.get(userId);

                if (prevPeer && prevPeer.media === peerMedia) {
                    // Nothing changed, don't update
                    newPeers.set(userId, prevPeer);
                } else if (prevPeer) {
                    updateNecessary = true;
                    newPeers.set(userId, {
                        ...prevPeer,
                        media: peerMedia ?? {},
                    });
                } else {
                    updateNecessary = true;
                    // TODO: skip the user
                    newPeers.set(userId, {
                        userId: userId,
                        media: peerMedia ?? {},
                        subscribeAudio: () => subscribe(
                            userId, "audio", SubscriptionLevel.HIGH
                        ),
                        // TODO: compute the actual quality level
                        subscribeVideo: (subscriptionLevel: VideoSubscriptionInfo) => subscribe(
                            userId, "video",  subscriptionLevel,
                        ),
                        unsubscribeAudio: (subscriptionId: number) => unsubscribe(
                            userId, "audio", subscriptionId
                        ),
                        unsubscribeVideo: (subscriptionId: number) => unsubscribe(
                            userId, "video", subscriptionId
                        ),
                    });
                }
            }

            return (updateNecessary || newPeers.size !== peers.size)
                ? newPeers : peers;
        });
    }, [
        selfUserId,
        conversationParticipantIds,
        media,
        subscribe,
        unsubscribe,
    ]);

    return [mediaAvailabilityInitialized && mediaInitialized, peers];
}


export default usePeerReceiver;
