import React from 'react';

import { CurrentConversationState } from '../../MeetupView/controllers/ConversationController';
import { LocalMediaTrack, UserMediaState, hasMedia } from './models';
import { VideoCallClient } from './clients/VideoCallClient';
import { useCallFullyJoined } from './VideoCallUtils';


function usePeerSender(
    conversationState: CurrentConversationState,
    client: VideoCallClient,
    userMedia: UserMediaState, 
) {
    const callFullyJoined = useCallFullyJoined(conversationState, client);
    const conversationId = conversationState.conversation?.id ?? null;
    const numConversationParticipants = conversationState.conversation?.participantIds.length ?? 0;
    const othersPresent = numConversationParticipants > 1;
    const shouldPublish = callFullyJoined && othersPresent;

    const publishMedia = React.useCallback((
        mediaTrack: LocalMediaTrack<any> | undefined,
    ) => {
        if (!shouldPublish || !hasMedia(mediaTrack))
            return;

        //console.log("publishing media track", mediaTrack);
        client.publishUserMedia(mediaTrack);
        // TODO: could there be a race condition where an unpublish event is
        // triggered before publishing has finished?
        return () => {
            client.unpublishUserMedia(mediaTrack);
        }
    }, [client, shouldPublish]);

    React.useEffect(() => {
        //updatePeerMedia('audio', userMedia.audio);
        return publishMedia(userMedia.audio);
    }, [conversationId, userMedia.audio, publishMedia]);
    React.useEffect(() => {
        //updatePeerMedia('video', userMedia.video);
        return publishMedia(userMedia.video);
    }, [conversationId, userMedia.video, publishMedia]);
    React.useEffect(() => {
        //updatePeerMedia('screen', userMedia.screen);
        return publishMedia(userMedia.screen, );
    }, [conversationId, userMedia.screen, publishMedia]);
}

export default usePeerSender;
