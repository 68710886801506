import React from 'react';

import { UserContext } from '../../../controllers/UserController';
import { ConversationContext } from '../../MeetupView/controllers/ConversationController';
import { UserMediaContext, UserMediaActionType } from '../../MeetupView/controllers/UserMediaController';
import { setScreenSharing } from '../../../controllers/BackendConnection';


function useScreenShareState() {
    const user = React.useContext(UserContext)!;
    const userId = user.participantIdentity.id;

    const conversation = React.useContext(ConversationContext).conversation;
    const conversationId = conversation?.id ?? null;
    const screenSharingParticipantId = conversation?.screenSharingParticipantId
            ?? null;
    const screenSharingParticipantRef = React.useRef(screenSharingParticipantId);
    React.useEffect(() => {
        screenSharingParticipantRef.current = screenSharingParticipantId;
    }, [screenSharingParticipantId]);

    const [userMediaState, userMediaDispatch] = React.useContext(UserMediaContext);

    /*
     * This effect turns off screen sharing by this user when someone else
     * starts sharing their screen.
     */
    React.useEffect(() => {
        if (
            screenSharingParticipantId !== null
            && screenSharingParticipantId !== userId
        ) {
            // The screen sharing participant changed, i.e. someone else started
            // sharing their screen.
            // The user should disable screen sharing if enabled because only one
            // person at a time can share their screen.
            userMediaDispatch({
                actionType: UserMediaActionType.SET_SCREEN_SHARE,
                enabled: false,
            });
        }
    }, [userId, screenSharingParticipantId, userMediaDispatch]);

    /*
     * This effect notifies the server about screen share changes when the user
     * changes switches screen sharing on or off.
     */
    React.useEffect(() => {
        if (!conversationId)
            return;

        const screenSharingParticipantId = screenSharingParticipantRef.current;
        if (
            userMediaState.screen === undefined
            && screenSharingParticipantId === userId
        ) {
            // The user was sharing the screen and now stopped, notify
            setScreenSharing(conversationId, false)
        } else if (
            userMediaState.screen !== undefined
            && screenSharingParticipantId !== userId
        ) {
            // The user started sharing their screen and was not the
            // screen sharing participant before, notify
            setScreenSharing(conversationId, true)
        }
    }, [userId, userMediaState.screen, conversationId]);
}

export default useScreenShareState;
