import React from 'react';
import useDimensions from "react-cool-dimensions";

import { ID } from '../../../../models';
import { VideoCallPeer, SubscriptionLevel } from '../../controllers/models';
import PeerItem, { computePeerItemProps } from '../PeerItem/PeerItem';
import SelfView from '../SelfView/SelfView';
import { InfoScreen, InfoType } from '../../../InfoScreen/InfoScreen';
import './style.scss';


interface PeerGridProps {
    activeSpeakerId: ID;
    screenSharingPeerId?: ID;
    userId: ID;
    peers: VideoCallPeer[];
}

// If more than this amount of peers is present, we subscribe to the low
// quality video streams instead of the high quality ones
const LOW_QUALITY_SUBSCRIPTION_THRESHOLD = 5;

// PeerGrid is the main video display for Talky. It matches remoteMedia to
// peers and then renders a PeerGridItem for each peer in the room.
const PeerGrid: React.FC<PeerGridProps> = (props: PeerGridProps) => {
    const {
        userId,
        peers,
    } = props;
    const nPeers = peers.length;

    const { ref, width, height } = useDimensions<HTMLDivElement>();
    // TODO: possibly throttle the computation
    const [nVideoRows, nVideoCols] = React.useMemo(() => computeGridRowsColumns(
        { width, height }, nPeers,
    ), [width, height, nPeers]);

    let selfView: React.ReactElement | null = null;
    const videoRows: React.ReactElement[] = [];
    if (nPeers <= 2) {
        for (const peer of peers) {
            if (peer.userId === userId) {
                selfView = <SelfView userPeer={peer} />
            } else {
                videoRows.push(
                    <div
                        className='peer-grid-row'
                        key={peer.userId}
                        style={{height: '100%'}}
                    >
                        <PeerItem
                            key={peer.userId}
                            {...computePeerItemProps(
                                props, peer, SubscriptionLevel.HIGH
                            )}
                        />
                    </div>
                );
            }
        }
    } else {
        const subscriptionLevel = nPeers > LOW_QUALITY_SUBSCRIPTION_THRESHOLD
            ? SubscriptionLevel.LOW : SubscriptionLevel.HIGH;
        const rowHeight = `${100 / nVideoRows}%`;
        for (let iRow = 0; iRow < nVideoRows; iRow++) {
            const rowStartParticipant = iRow * nVideoCols;
            const rowEndParticipant = Math.min(
                rowStartParticipant + nVideoCols, peers.length,
            );
            const rowParticipants = peers.slice(
                rowStartParticipant, rowEndParticipant,
            );
            const rowKey = rowParticipants
                    .map(participant => participant.userId)
                    .join(",");
            videoRows.push(
                <div
                    className='peer-grid-row'
                    key={rowKey}
                    style={{height: rowHeight}}
                >
                    {rowParticipants.map(peer => (
                        <PeerItem
                            key={peer.userId}
                            {...computePeerItemProps(
                                props, peer, subscriptionLevel, true
                            )}
                        />
                    ))}
                </div>
            );
        }
    }

    //console.log(`width: ${gridDimensions.width}, height: ${gridDimensions.height}`);
    //console.log(`nRows: ${nVideoRows}, nCols: ${nVideoCols}`);

    return (
        <div
            className='peer-grid'
            ref={ref}
        >
            {selfView}
            {videoRows.length > 0
                ? videoRows
                : <InfoScreen
                    message={"There isn't anyone here (yet!)"}
                    infoType={InfoType.NOTICE}
                />
            }
        </div>
    );
};

// 16:9 spect ratio
const ASPECT_RATIO = 1.777777;

export function computeGridRowsColumns(
    gridDimensions: { width: number; height: number; },
    numParticipants: number
): [number, number] {
    let maxScale = 0;
    let bestNRows = 0;
    let bestNCols = 0;

    // TODO: implement early stopping
    for (let nRows = 1; nRows <= numParticipants; nRows++) {
        const nCols = Math.ceil(numParticipants / nRows);
        const rowScale = gridDimensions.height / nRows;
        const colScale = gridDimensions.width / (ASPECT_RATIO * nCols);
        const rowColScale = Math.min(rowScale, colScale);
        if (rowColScale > maxScale) {
            maxScale = rowColScale;
            bestNRows = nRows;
            bestNCols = nCols;
        }
    }

    return [bestNRows, bestNCols];
}

export default PeerGrid;
