import React from 'react';
import { useForm } from "react-hook-form";

import './style.scss';


interface PasswordInputViewProps {
    passwordRequired: boolean;
    submit: (password: string) => void;
}

interface FormValues {
    password: string;
}

export const PasswordInputView: React.FC<PasswordInputViewProps> = (props => {
    const {register, handleSubmit, formState: { errors }} = useForm<FormValues>();

    return (
        <form
            className='password-container'
            onSubmit={handleSubmit(values => props.submit(values.password))}
        >
            <div className='checkin-text-input-container'>
                <label
                    htmlFor='password'
                    className='form-label'
                >
                    Password
                </label>
                <input
                    {...register("password", { required: true })}
                    type='password'
                    className={
                        errors.password
                            ? 'form-input form-error'
                            : 'form-input'
                    }
                />
                {errors.password && (
                    <div className='form-error-feedback'>
                        Please enter the meetup password.
                    </div>
                )}

                <button
                    type='submit'
                    className='checkin-button'
                >
                    Verify
                </button>
            </div>
        </form>
    );
});
