import React from 'react';
import { CSSTransition } from 'react-transition-group';

import EditIcon from 'material-icons-svg/components/baseline/Edit';

import { Conversation } from '../../models';
import { ConversationContext } from '../MeetupView/controllers/ConversationController';
import {
    useConversationMedia,
    UserMediaActionType,
    UserMediaContext,
} from '../MeetupView/controllers/UserMediaController';
import useVideoCall from './controllers/VideoCallController';
import useUserActivity from './controllers/UserActivityController';
import { usePlaybackState } from './controllers/PlaybackController';
import { hasMedia, VideoCallPeer } from './controllers/models';
import { InfoScreen, InfoType } from '../InfoScreen/InfoScreen';
import PeerGrid from './components/PeerGrid/PeerGrid';
import SpeakerView from './components/SpeakerView/SpeakerView';
import RoomControls from './components/RoomControls/RoomControls';
import MediaControls from './components/MediaControls/MediaControls';
import NameConversationControl from '../NameConversationControl/NameConversationControl';
import './style.scss';


function VideoChatRoom(): React.ReactElement {
    const currentConversation = React.useContext(ConversationContext);
    const [userMediaState,] = useConversationMedia();
    const [clientConnectionState, videoCallPeers] = useVideoCall({
        mediaState: userMediaState
    });

    const [audioAccessGranted, setAudioAccessGranted] = React.useState(hasMedia(userMediaState.audio));
    React.useEffect(() => {
        if (hasMedia(userMediaState.audio)) {
            setAudioAccessGranted(true);
        }
    }, [userMediaState.audio]);

    const [controlsVisible, setControlsVisible] = React.useState(true);
    const [userActive, userActivityListener] = useUserActivity(3000);
    React.useEffect(() => {
        setControlsVisible(userActive);
    }, [userActive]);

    // TODO: move style declarations
    let content: React.ReactElement|null;
    switch(currentConversation.joinStatus) {
        case "joining":
            content = <JoiningScreen />;
            break;
        case "success":
            if (!currentConversation.conversation || clientConnectionState !== "CONNECTED") {
                // TODO: show leaving message if the user is leaving
                // Still in the pre-join state
                content = <JoiningScreen />;
            } else if (!audioAccessGranted) {
                if (userMediaState.audio === undefined) {
                    content = (
                        <InfoScreen
                            message={"Waiting for media permissions..."}
                            infoType={InfoType.LOADING}
                        />
                    );
                } else {
                    const message = userMediaState.audio === "NOT_ALLOWED"
                        ? "Audio permission not granted.\nPlease enable audio permissions for this website and reload."
                        : "Audio recording not supported.";
                    content = (
                        <InfoScreen
                            message={message}
                            infoType={InfoType.ERROR}
                        />
                    );
                }
            } else {
                content = (
                    <Room
                        key={currentConversation.conversation.id}
                        conversation={currentConversation.conversation}
                        videoCallPeers={videoCallPeers}
                        controlsVisible={controlsVisible}
                    />
                );
            }
            break;
    }

    const [isFullscreen, setFullscreen] = React.useState(false);
    return (
        <div
            className={(isFullscreen ? 'fullscreen' : 'non-fullscreen')
                + ' video-chat-room-container'
            }
            onPointerMove={userActivityListener}
            onPointerUp={userActivityListener}
        >
            {content}
            <CSSTransition
                in={controlsVisible}
                timeout={500}
                classNames='transition-fade'
            >
                <RoomControls
                    onClose={() => { setControlsVisible(false); }}
                    isFullscreen={isFullscreen}
                    toggleFullscreen={() =>
                        setFullscreen(isFullscreen => !isFullscreen)
                    }
                />
            </CSSTransition>
        </div>
    );
}

function JoiningScreen(): React.ReactElement {
    return (
        <InfoScreen
            message={"Joining..."}
            infoType={InfoType.LOADING}
        />
    );
}


interface RoomProps {
    conversation: Conversation;
    videoCallPeers: VideoCallPeer[];
    controlsVisible: boolean;
}

// TODO: get rid of null option
const Room: React.FC<RoomProps> = (props => {
    const { conversation, videoCallPeers, controlsVisible, } = props;
    const [userMedia, userMediaDispatcher] = React.useContext(UserMediaContext);
    const playbackState = usePlaybackState({
        userMedia: userMedia,
        peers: videoCallPeers,
    });
    const [isRenameControlVisible, setRenameControlVisible] = React.useState(false);

    const header = (
        <div className='video-chat-header'>
            {props.conversation?.name}
            <div
                className='edit-button'
                onClick={() => setRenameControlVisible(true)}
            >
                <EditIcon className='svg-icon-light'/>
            </div>
        </div>
    );

    const peerView = playbackState.isSpeakerView
        ? <SpeakerView
            {...playbackState}
            controlsVisible={controlsVisible}
        />
        : <PeerGrid {...playbackState}/>;

    const screenSharingInfo = hasMedia(userMedia.screen)
        ? (
            <div className='screen-sharing-info'>
                <span>You are sharing your screen.</span>
                <div
                    className='screen-sharing-end-button'
                    onClick={() => userMediaDispatcher({
                        actionType: UserMediaActionType.SET_SCREEN_SHARE,
                        enabled: false,
                    })}
                >
                    Stop
                </div>
            </div>
        )
        : null;

    // TODO: handle and show own and other participants' disconnection
    return (
        <>
            {peerView}
            <CSSTransition
                in={controlsVisible}
                timeout={500}
                classNames='transition-fade'
            >
                <div>
                    {header}
                    <MediaControls
                        mediaState={userMedia}
                        mediaDispatcher={userMediaDispatcher}
                        isSpeakerView={playbackState.isSpeakerView}
                        toggleSpeakerView={playbackState.toggleSpeakerView}
                    />
                </div>
            </CSSTransition>
            {screenSharingInfo}
            <NameConversationControl
                visible={isRenameControlVisible}
                onClose={() => setRenameControlVisible(false)}
                conversationId={conversation.id}
            />
        </>
    );
});

export default VideoChatRoom;

