import React from 'react';
import ReconnectingWebsocket from 'reconnecting-websocket';

import { ID } from '../models';
import { ConnectionClient } from '../utils/ConnectionMonitoredClient';


const MODEL_TYPE_KEY = "type";
const ACTION_KEY = "action";
const DATA_KEY = "data";

export type ChangeAction = "initial" | "create" | "update" | "delete";
export const START_UPDATES_ACTION = "start-updates";

interface  RealtimeMessage {
    type: string;
    action: ChangeAction;
    data: any;
}

type Listener = (message: RealtimeMessage) => void;

const PROTOCOL = "Meetup-RTC-Protocol";
const CONNECTION_TIMEOUT = 15000;

const PING_ACTION = "ping";
const PING_INTERVAL = 10000;
const PING_MESSAGE = JSON.stringify({[ACTION_KEY]: PING_ACTION});


export class BackendRTC extends ConnectionClient {
    //private connection: WebSocket|null = null;
    private connection: ReconnectingWebsocket|null = null;
    private listeners: Map<string, Listener>|null = null;
    private pingTask: NodeJS.Timeout | null = null;

    constructor() {
        super();

        this.notifyConnectionStateChange("DISCONNECTED");
    }

    connect(meetupId: ID): Promise<void> {
        this.notifyConnectionStateChange("CONNECTING");

        const url = `${process.env.REACT_APP_WS_API_HOST}/${meetupId.toString()}/`;

        return new Promise((resolve, reject) => {
            //const websocketConnection = new WebSocket(url, protocol);
            const websocketConnection = new ReconnectingWebsocket(url, PROTOCOL, {
                connectionTimeout: CONNECTION_TIMEOUT,
            });
            if (websocketConnection === null) {
                console.error("Cannot create websocket connection.");
                this.notifyConnectionStateChange("DISCONNECTED");

                reject();
            }
            websocketConnection.onopen = (_) => {
                this.connection = websocketConnection;
                this.listeners = new Map();

                if (this.pingTask === null) {
                    this.pingTask = setInterval(() => {
                        if (!this.connection)
                            return;

                        this.connection!.send(PING_MESSAGE);
                    }, PING_INTERVAL);
                }

                this.notifyConnectionStateChange("CONNECTED");
                resolve();
            };

            // TODO: handle close events
            websocketConnection.onclose = (_) => {
                //this.close();
                this.notifyConnectionStateChange("DISCONNECTED");
            };
        });
    }

    close() {
        if (this.isConnected()) {
            this.connection!.close();
        }
        if (this.pingTask) {
            clearInterval(this.pingTask);
            this.pingTask = null;
        }
        this.connection = null;
        this.listeners = null;
    }

    public addListener(messageType: string, callback: Listener) {
        if (!this.isConnected()) {
            console.error("Cannot register realtime backend listener, connection not initialized.");
            return;
        }

        this.listeners!.set(messageType, callback);
        this.connection!.onmessage = (event: any) => {
            const message = JSON.parse(event.data);

            const callback = this.listeners!.get(message[MODEL_TYPE_KEY]);
            if (callback === undefined) {
                console.warn("Received message for unregistered listener", message);
            } else {
                callback(message);
            }
        }
    }

    public deleteListener(messageType: string) {
        if (!this.isConnected()) {
            console.error("Cannot register realtime backend listener, connection not initialized.");
            return;
        }

        if (!this.listeners!.has(messageType)) {
            console.warn("Trying to clear non-existing listener for", messageType)
        } else {
            this.listeners!.delete(messageType);
        }
    }

    public send(messageType: string, action: string, message?: any) {
        if (!this.isConnected()) {
            console.error("Cannot send realtime message to backend, connection not initialized.");
            return;
        }

        const sendMessage = {
            [MODEL_TYPE_KEY]: messageType,
            [ACTION_KEY]: action,
            [DATA_KEY]: message,
        };
        this.connection!.send(JSON.stringify(sendMessage))
    }
}

export function useSetupBackendRTC(meetupId: ID|undefined, userId: ID|undefined): BackendRTC|null {
    const [backendRtc, setBackendRtc] = React.useState<BackendRTC|null>(null);

    React.useEffect(() => {
        if (!meetupId || !userId)
            return;

        const connection = new BackendRTC();
        connection.connect(meetupId).then(() => {
            setBackendRtc(connection);
        });
        return () => {
            connection.close();
            setBackendRtc(null);
        };
    }, [meetupId, userId]);

    return backendRtc;
}

export const BackendRTCContext = React.createContext<BackendRTC|null>(null);
BackendRTCContext.displayName = 'BackendRTCContext';

export function useBackendRTC() {
    const backendRTC = React.useContext(BackendRTCContext);
    return backendRTC;
}
