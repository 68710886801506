import React from 'react';
import useDimensions from "react-cool-dimensions";


//import { ID, Conversation } from '../../models';
import { ID, Conversation } from 'models';
import { MeetupContext } from '../../controllers/MeetupController';
import { UserContext } from '../../controllers/UserController';
import {
    ConversationContext,
    useConversationNavigation,
} from '../MeetupView/controllers/ConversationController';
import { SimNode, Position } from './models';
import MingleSimulation from './components/MingleSimulation/MingleSimulation';
import ConversationGroup, { JoinStatus } from './components/ConversationGroup/ConversationGroup';
import ParticipantAvatar from './components/ParticipantAvatar/ParticipantAvatar';
import ParticipantMenu from './components/ParticipantMenu/ParticipantMenu';
//import Grid from '../Grid/Grid';
import './style.scss';


const MENU_TOP_THRESHOLD = 130;

const MingleArea: React.FC = () => {
    const meetup = React.useContext(MeetupContext);

    const user = React.useContext(UserContext);
    const selfUserId = user!.participantIdentity.id;

    const conversationStatus = React.useContext(ConversationContext);
    const curConversation = conversationStatus.conversation;
    const curConversationId = curConversation?.id ?? null;

    const { ref, width, height } = useDimensions<HTMLDivElement>();

    const participants = React.useMemo(() => {
        return new Array(...meetup.participants.values()).concat([
            //{
                //id: "183092039",
                //name: "John 1",
                //avatarUrl: "",
                //conversationId: "50429490",
            //},
            //{
                //id: "238811",
                //name: "John 2",
                //avatarUrl: "",
                //conversationId: "50429490",
            //},
            //{
                //id: "41359034",
                //name: "John 3",
                //avatarUrl: "",
                //conversationId: "50429490",
            //},
        ])
    }, [meetup.participants]);

    const conversations = React.useMemo(() => {
        return new Array(...meetup.conversations.values()).concat([
            //{
                //id: "5892014810",
                //name: "Test Conv",
                //capacity: 7,
                //participantIds: [],
                //screenSharingParticipantId: null,
                //chatChannelId: 5832,
            //},
            //{
                //id: "50429490",
                //name: "Test Conv 2",
                //capacity: 7,
                //participantIds: ["41359034", "238811", "183092039"],
                //screenSharingParticipantId: null,
                //chatChannelId: 5842,
            //}
        ]);
    }, [meetup.conversations]);


    const [userNodes, setUserNodes] = React.useState<Map<ID, SimNode>>(new Map());
    const [convNodes, setConvNodes] = React.useState<Map<ID, SimNode>>(new Map());
    
    const [menuParticipantId, setMenuParticipantId] = React.useState<ID | null>(null);
    const menuParticipantRef = React.useRef<HTMLDivElement>(null);
    const participantAvatars = participants.map(participant => {
        const participantNode = userNodes.get(participant.id);
        const participantPos = participantNode === undefined
            ? {x: width / 2, y: height / 2}
            : {x: participantNode.x ?? 0, y: participantNode.y ?? 0};

        const participantMenuCallback = (
            curConversation === null
            || (participant.conversationId !== curConversationId
                && curConversation.participantIds.length < curConversation.capacity)
        )
            ? setMenuParticipantId : undefined;
        return (
            <PlacementContainer
                key={`p-${participant.id}`}
                id={`user-${participant.id}`}
                position={participantPos}
            >
                <ParticipantAvatar
                    participant={participant}
                    includeName={true}
                    setMenu={participantMenuCallback}
                    avatarRef={
                        participant.id === menuParticipantId
                        ? menuParticipantRef : undefined
                    }
                />
            </PlacementContainer>
        );
    });

    const [, navigateToConversation] = useConversationNavigation();
    const joinConversation = React.useCallback((conversationId: ID | null) => {
        navigateToConversation(conversationId);
    }, [navigateToConversation]);

    const userNode = userNodes.get(selfUserId);
    const conversationCircles = conversations.map(conversation => {
        const conversationNode = convNodes.get(conversation.id);
        if (conversationNode !== undefined) {
            return (
                <PositionedConversation
                    key={`c-${conversation.id}`}
                    conversation={conversation}
                    conversationNode={conversationNode}
                    userNode={userNode}
                    curConversationId={curConversationId}
                    joinCallback={joinConversation}
                />
            );
        }
    });

    let interactionMenu = null;
    if (menuParticipantId !== null) {
        const menuParticipant = meetup.participants.get(menuParticipantId);
        const menuParticipantNode = userNodes.get(menuParticipantId);
        const menuParticipantPos = {
            x: menuParticipantNode?.x ?? 0,
            y: menuParticipantNode?.y ?? 0,
        };
        const isTop = menuParticipantPos.y > MENU_TOP_THRESHOLD;
        interactionMenu = (
            <PlacementContainer
                id={'interaction-menu'}
                position={menuParticipantPos}
            >
                <ParticipantMenu
                    participant={menuParticipant}
                    setMenu={setMenuParticipantId}
                    isTop={isTop}
                    avatarRef={menuParticipantRef}
                />
            </PlacementContainer>
        );
    }

    return (
        <div
            className='mingle-area'
            ref={ref}
        >
            {conversationCircles}
            {participantAvatars}
            <MingleSimulation
                participants={participants}
                conversations={conversations}
                selfUserId={selfUserId}
                width={width}
                height={height}
                setUserNodes={setUserNodes}
                setConvNodes={setConvNodes}
                setConv={navigateToConversation}
            />
            {interactionMenu}
        </div>
    );
}


interface PositionedConversationProps {
    conversation: Conversation;
    conversationNode: SimNode;
    userNode?: SimNode;
    curConversationId: ID | null;
    joinCallback: (conversationId: ID | null) => void;
}

const PositionedConversation: React.FC<PositionedConversationProps> = (props) => {
    const {
        conversation,
        conversationNode,
        userNode,
        curConversationId,
        joinCallback,
    } = props;

    const conversationPos = {
        x: conversationNode.x ?? 0,
        y: conversationNode.y ?? 0,
    };

    const userDist = (
        userNode
        && userNode.x !== undefined
        && userNode.y !== undefined
    )
        ? Math.hypot(
            userNode.x - conversationPos.x, userNode.y - conversationPos.y,
        )
        : undefined;
    let joinStatus: JoinStatus;
    if (curConversationId === conversation.id) {
        if (userDist && userDist >= conversationNode.r) {
            joinStatus = "leaving";
        } else {
            joinStatus = "in";
        }
    } else {
        if (userDist && userDist < conversationNode.r) {
            joinStatus = "joining";
        } else {
            joinStatus = "out";
        }
    }

    return (
        <PlacementContainer
            id={`conversation${conversation.id}`}
            position={conversationPos}
        >
            <ConversationGroup
                conversation={conversation}
                joinStatus={joinStatus}
                joinConversation={joinCallback}
                radius={conversationNode.r}
            />
        </PlacementContainer>
    );
}


interface PlacementContainerProps {
    id: string;
    position: Position;
}


const PlacementContainer: React.FC<PlacementContainerProps> = (props) => {
    const { id, position } = props;

    return (
        <div
            key={id}
            className='placement-container'
            style={{
                left: position.x,
                top: position.y,
            }}
        >
            {props.children}
        </div>
    );
};


export default MingleArea;
