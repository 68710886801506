import React from 'react';

import {
    ID,
    Meetup,
    Participant,
    Conversation,
    PARTICIPANT_KEY,
    CONVERSATION_KEY,
} from '../models';
import * as Backend from './BackendConnection';
import { BackendRTC } from './BackendRTC';
import { UpdateAction, useBackendRTCBackedMap } from './BackendRTCUtils';


export interface MeetupInfo {
    id: ID;
    name: string;
    urlId: string;
    startTime: string;
    endTime: string;
    hasPassword: boolean;

    // TODO: load these only later once the participant checked in
    chatspaceId: string;
    rootChatChannelId: number;
}

export function useMeetupInfo(urlId: string|null): MeetupInfo|null {
    const [meetupInfo, setMeetupInfo] = React.useState<MeetupInfo|null>(null);

    React.useEffect(() => {
        if (urlId) {
            Backend.resolveMeetup(urlId).then(
                result => setMeetupInfo(result),
                error => {
                    console.log("Error loading data:", error);
                    setMeetupInfo(null);
                }
            );
        }
    }, [urlId]);

    return meetupInfo;
}


interface ConversationInfo {
    id: ID;
    name?: string;
    capacity: number;
    screenSharingParticipantId: string | null;
    chatChannelId: number;
}

type ConversationParticipantIds = ID[];

export function useMeetup(
    meetupInfo: MeetupInfo|null,
    backendRTC: BackendRTC|null,
): Meetup|null {
    const [conversationParticipants, setConversationParticipants]
            = React.useState<Map<ID, ConversationParticipantIds>>(new Map());
    const curParticipants = React.useRef(new Map());

    const participants = useBackendRTCBackedMap(
        backendRTC, PARTICIPANT_KEY, parseParticipant, [updateConversationParticipants(
            curParticipants, setConversationParticipants
        )]
    );
    React.useEffect(() => {
        curParticipants.current = participants;
    }, [participants]);

    // We separate conversation information and conversation participants
    // because the latter ones are sent via participant updates
    const conversationInfo = useBackendRTCBackedMap(
        backendRTC, CONVERSATION_KEY, parseConversation,
    );
    const conversations = React.useMemo<Map<ID, Conversation>>(() => {
        const newConversations = new Map();
        for (const [id, info] of conversationInfo.entries()) {
            newConversations.set(id, {
                ...info,
                participantIds: conversationParticipants.get(id) ?? [],
            });
        }
        return newConversations;
    }, [conversationInfo, conversationParticipants]);

    const [meetup, setMeetup] = React.useState<Meetup|null>(null);
    React.useEffect(() => {
        if (meetupInfo) {
            setMeetup({
                ...meetupInfo,
                participants: participants,
                conversations: conversations,
            });
        } else {
            setMeetup(null);
        }
    }, [meetupInfo, participants, conversations]);

    return meetup;
}

export function parseParticipant(data: any, prevParticipant?: Participant): Participant {
    const conversationId = data.conversationId !== undefined
        ? data.conversationId : prevParticipant?.conversationId;
    return {
        id: data.id.toString(),
        name: data.name ?? prevParticipant?.name,
        avatarUrl: data.avatarUrl ?? prevParticipant?.avatarUrl,
        conversationId: conversationId ? conversationId.toString() : null,
    };
}

export function parseConversation(data: any, prevConversation?: ConversationInfo): ConversationInfo {
    return {
        id: data.id.toString(),
        name: data.name ?? prevConversation?.name,
        capacity: data.capacity ?? prevConversation?.capacity,
        screenSharingParticipantId: (data.screenSharingParticipantId === undefined)
            ? prevConversation?.screenSharingParticipantId
            : (data.screenSharingParticipantId?.toString() ?? null),
        chatChannelId: data.chatChannelId ?? prevConversation?.chatChannelId,
    };
}

type ResultsUpdater<T> = (callback: ((results: Map<ID, T>) => Map<ID, T>)) => void;

function updateConversationParticipants(
    participantsRef: {current: Map<ID, Participant>},
    setConversationParticipants: ResultsUpdater<ConversationParticipantIds>,
): ((action: UpdateAction<Participant>) => void) {
    return (action) => {
        setConversationParticipants(conversationParticipants => {
            const updatedConversationParticipants = new Map(conversationParticipants);
            const changedParticipants: {
                id: ID;
                conversationId: ID|null|undefined;
            }[] = action.data.map(item => parseParticipant(item));
            
            for (const participant of changedParticipants) {
                const participantId = participant.id;

                const prevConversationId = (participantsRef.current
                        .get(participantId)?.conversationId) ?? null;
                const newConversationId = participant.conversationId ?? null;

                if (prevConversationId === newConversationId)
                    continue;

                const prevConversationParticipants = prevConversationId
                        ? updatedConversationParticipants.get(prevConversationId)
                        : null;
                const newConversationParticipants = newConversationId
                        ? (updatedConversationParticipants.get(newConversationId) ?? [])
                        : null;

                if (prevConversationParticipants) {
                    const prunedParticipants = new Array(...prevConversationParticipants);
                    const index = prunedParticipants.indexOf(participantId);
                    console.assert(index > -1, "Participant was not in previous conversation.");
                    prunedParticipants.splice(index, 1);
                    updatedConversationParticipants.set(
                        prevConversationId!, prunedParticipants,
                    );
                }

                if (newConversationParticipants) {
                    const newParticipants = [
                        ...newConversationParticipants, participantId,
                    ];
                    updatedConversationParticipants.set(
                        newConversationId!, newParticipants,
                    );
                }
            }
            return updatedConversationParticipants;
        });
    }
}


export const DEFAULT_MEETUP = {
    id: '0',
    name: '',
    conversations: new Map(),
    participants: new Map(),
    //setConversations: (_ => {}),
} as Meetup;

export const MeetupContext = React.createContext<Meetup>(DEFAULT_MEETUP);
MeetupContext.displayName = 'MeetupContext';

