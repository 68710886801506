import React from 'react';

import { ID } from '../models';
import { BackendRTC, ChangeAction, START_UPDATES_ACTION } from './BackendRTC';
import { useConnectionState } from '../utils/ConnectionMonitoredClient';


interface IdItem {
    id: ID;
}

type ItemParser<T> = (data: any, prevItem?: T) => T;

export interface UpdateAction<T> {
    action: ChangeAction;
    data: T[];
}

export function useBackendRTCBackedMap<T extends IdItem>(
    backendRTC: BackendRTC|null,
    itemKey: string,
    itemParser: ItemParser<T>,
    additionalUpdateCallbacks?: ((action: UpdateAction<T>) => void)[]
): Map<ID, T> {
    const [items, itemsDispatch] = React.useReducer<ItemsUpdater<T>>(
        getItemReducer(itemParser), new Map<ID, T>()
    );

    const connectionState = useConnectionState(backendRTC);
    React.useEffect(() => {
        //console.log("listener update, connection state:", connectionState, itemKey);
        if (backendRTC === null || connectionState !== "CONNECTED")
            return;

        backendRTC.addListener(itemKey, update => {
            if (additionalUpdateCallbacks) {
                for (const callback of additionalUpdateCallbacks) {
                    callback(update);
                }
            }
            itemsDispatch(update);
        });
        // Signal that we're ready to receive state updates
        backendRTC.send(itemKey, START_UPDATES_ACTION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backendRTC, connectionState, itemKey]);

    return items;
}


type ItemsState<T> = Map<ID, T>;

type ItemsUpdater<T> = (state: ItemsState<T>, action: UpdateAction<T>) => ItemsState<T>;

function getItemReducer<T extends IdItem>(
    itemParser: ItemParser<T>
): ItemsUpdater<T> {
    return (state, action) => {
        switch (action.action) {
            case "initial":
                const initialItems = action.data.map(item => itemParser(item));
                return new Map([...arrayToDictVals(initialItems)]);
            case "create":
                const newItems = action.data.map(item => itemParser(item));
                return new Map([...state, ...arrayToDictVals(newItems)]);
            case "update":
                // TODO: check that all changed keys are present
                const changedData: T[] = action.data.map(item =>
                    itemParser(item, state.get(item.id.toString()))
                );
                //item.map(key => item[key] ?? ({
                    //...state.get(item.id)!,
                    //...itemParser(item),
                //}));
                return new Map([...state, ...arrayToDictVals(changedData)]);
            case "delete":
                // TODO: check that the deleted items are present
                const prunedItems = new Map(state);
                action.data.forEach(item => prunedItems.delete(item.id.toString()));
                return prunedItems;
        }
    }
}

function arrayToDictVals<T extends IdItem>(array: T[]): [ID, T][] {
    return array.map(item => [item.id, item]);
}
