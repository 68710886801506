import React from 'react';
import useDimensions from "react-cool-dimensions";

import MicrophoneOffIcon from 'material-icons-svg/components/baseline/MicOff';
//import ScreenShareIcon from 'material-icons-svg/components/baseline/ScreenShare';

import { ID } from '../../../../models';
import { MeetupContext } from '../../../../controllers/MeetupController';
import { VideoCallPeer, VideoSubscriptionInfo, SubscriptionLevel, MediaQualityLevel } from '../../controllers/models';
//import { PlaybackState } from '../../controllers/PlaybackController';
import { useStreamFromMediaState } from '../../../MeetupView/controllers/UserMediaController';
import './style.scss';


interface PeerItemProps {
    peer: VideoCallPeer;
    videoQualityLevel: VideoSubscriptionInfo;
    isUser: boolean;
    isActiveSpeaker?: boolean;
    hideFooter?: boolean;
}

const PeerItem: React.FC<PeerItemProps> = (props) => {
    const {
        peer,
        videoQualityLevel,
        isUser,
        isActiveSpeaker,
    } = props;
    const mediaState = peer.media;
    useMediaSubscription(peer, true, videoQualityLevel);

    const meetup = React.useContext(MeetupContext);
    // TODO: use fallback participant
    const participant = meetup.participants.get(peer.userId)!;

    const audioOn = mediaState.audio !== undefined;
    const volumeMonitor = (audioOn && mediaState.audio?.volume) || null;

    const videoOn = mediaState.video !== undefined;
    const screenSharingOn = mediaState.screen !== undefined;
    const hasVideo = screenSharingOn || videoOn;

    const [videoElement, setVideoElement] = React.useState<HTMLVideoElement | null>(null);
    useStreamOnMediaElement<HTMLVideoElement>(peer, videoElement);

    const { width, height } = useDimensions<HTMLVideoElement>({
        ref: {current: videoElement},
    });
    const videoWidth = videoElement?.videoWidth || width;
    const videoHeight = videoElement?.videoHeight || height;
    const [
        renderedVideoWidth, renderedVideoHeight,
        footerXOffset, footerYOffset,
    ] = React.useMemo(() => {
        // Compute the scaled video size
        const scaleFactor = (videoWidth > 0 && videoHeight > 0)
            ? Math.min(width / videoWidth, height / videoHeight) : 1;
        const renderedVideoWidth = scaleFactor * videoWidth;
        const renderedVideoHeight = scaleFactor * videoHeight;
        return [
            renderedVideoWidth, renderedVideoHeight,
            (width - renderedVideoWidth) / 2, (height - renderedVideoHeight) / 2,
        ];
    }, [width, height, videoWidth, videoHeight]);

    let userInfo: React.ReactElement | null;
    if (props.hideFooter) {
        userInfo = null;
    } else {
        let participantName;
        if (isUser) {
            participantName = screenSharingOn ? "Your screen" : "You";
        } else {
            participantName = participant.name + (screenSharingOn ? "'s screen" : "");
        }

        const nameLabel = (
            <div
                className={'peer-item-footer ' + (hasVideo ? 'with-video' : 'without-video')}
                style={hasVideo ? {left: footerXOffset, bottom: footerYOffset} : {}}
            >
                <VolumeIndicator volumeMonitor={volumeMonitor}/>
                <span className='participant-name'>
                    {participantName}
                </span>
            </div>
        );
        if (hasVideo) {
            userInfo = nameLabel;
        } else {
            userInfo = (
                <div className='no-video-container'>
                    <img
                        className='no-video-avatar-icon'
                        src={participant.avatarUrl}
                        alt={`${participant.name} icon`}
                    />
                    {nameLabel}
                </div>
            );
        }
    }

    return (
        <div
            className='peer-item-container'
        >
            <video
                className={'video-element'
                    + ((isUser && !screenSharingOn) ? ' mirror-video' : '')
                }
                ref={setVideoElement}
                autoPlay
                muted={isUser}
            />
            {userInfo}
            {isActiveSpeaker &&
                <div
                    className='active-speaker-indicator'
                    style={{
                        width: renderedVideoWidth, height: renderedVideoHeight,
                        left: footerXOffset, top: footerYOffset,
                    }}
                />
            }
        </div>
    );
}


interface AudioOnlyPeerItemProps {
    peer: VideoCallPeer;
    isUser: boolean;
}

export const AudioOnlyPeerItem: React.FC<AudioOnlyPeerItemProps> = (props) => {
    const peer = props.peer;
    useMediaSubscription(peer, true, SubscriptionLevel.OFF);

    const [audioElement, setAudioElement] = React.useState<HTMLAudioElement | null>(null);
    useStreamOnMediaElement<HTMLAudioElement>(peer, audioElement);
    return (
        <audio
            className='audio-only-container'
            ref={setAudioElement}
            autoPlay
            muted={props.isUser}
        />
    );
}


function useMediaSubscription(peer: VideoCallPeer, audio: boolean, videoQuality: SubscriptionLevel) {
    const subscribeAudio = peer.subscribeAudio;
    const unsubscribeAudio = peer.unsubscribeAudio;
    const subscribeVideo = peer.subscribeVideo;
    const unsubscribeVideo = peer.unsubscribeVideo;

    React.useEffect(() => {
        const audioSubscription = audio ? subscribeAudio() : undefined;
        const videoSubscription = videoQuality !== SubscriptionLevel.OFF
            ? subscribeVideo(videoQuality) : undefined;

        return () => {
            if (audioSubscription) unsubscribeAudio(audioSubscription);
            if (videoSubscription) unsubscribeVideo(videoSubscription);
        };
    }, [
        audio, videoQuality,
        subscribeAudio, unsubscribeAudio,
        subscribeVideo, unsubscribeVideo
    ]);
}


function useStreamOnMediaElement<T extends (HTMLAudioElement | HTMLVideoElement)>(
    peer: VideoCallPeer,
    mediaElement: T | null,
) {
    const mediaState = peer.media;
    const peerStream = useStreamFromMediaState(mediaState);

    React.useLayoutEffect(() => {
        if (!mediaElement)
            return;

        mediaElement.srcObject = peerStream;
    }, [mediaElement, peerStream]);
}


interface VolumeIndicatorProps {
    volumeMonitor: (() => number) | null;
}

const VOLUME_CHECK_FREQUENCY = 500;

const VolumeIndicator: React.FC<VolumeIndicatorProps> = (props) => {
    const volumeMonitorRef = React.useRef(props.volumeMonitor);
    React.useEffect(() => {
        volumeMonitorRef.current = props.volumeMonitor
    }, [props.volumeMonitor]);
    const [volume, setVolume] = React.useState(0);
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (volumeMonitorRef.current) {
                setVolume(volumeMonitorRef.current());
            } else {
                setVolume(0);
            }
        }, VOLUME_CHECK_FREQUENCY);

        return () => clearInterval(interval);
    }, []);

    const size = 100 * volume;
    let indicator;
    if (props.volumeMonitor) {
        indicator = (
            <div
                className='volume-indicator'
                style={{
                    width: `${size}%`,
                    height: `${size}%`,
                    transition: `width height ${VOLUME_CHECK_FREQUENCY}ms`,
                }}
            >
            </div>
        );
    } else {
        indicator = (
            <MicrophoneOffIcon className='indicator-icon'/>
        );
    }

    return (
        <div className='volume-indicator-container'>
            {indicator}
        </div>
    );
}


export function computePeerItemProps(
    playbackState: { userId: ID, activeSpeakerId: ID },
    peer: VideoCallPeer,
    videoQualityLevel: MediaQualityLevel,
    highlightActiveSpeaker?: boolean,
): PeerItemProps {
    return {
        peer: peer,
        videoQualityLevel: videoQualityLevel,
        isUser: peer.userId === playbackState.userId,
        isActiveSpeaker: highlightActiveSpeaker
            && peer.userId === playbackState.activeSpeakerId,
    };
}


export default PeerItem;
