import AgoraRTC, {
    IAgoraRTCClient,
    ILocalTrack,
    IAgoraRTCRemoteUser,
    UID,
    IRemoteAudioTrack,
} from 'agora-rtc-sdk-ng';

import { ID } from '../../../../models';
import {
    VideoCallClient,
    PeerMediaAvailabilityCallback,
    PeerMediaCallback
} from './VideoCallClient';
import { ConnectionClient } from '../../../../utils/ConnectionMonitoredClient';
import {
    getMediaType,
    PeerMediaType,
    MediaQualityLevel,
    SubscriptionLevel,
    AudioTrack,
    VideoTrack,
    MediaTrack,
} from '../models';
import { getAgoraToken } from '../../../../controllers/BackendConnection';

const ENABLE_DUAL_STREAMS = false;

// Bitrates are in Kbps
const HIGH_QUALITY_MIN_BITRATE = 0;
const HIGH_QUALITY_MAX_BITRATE = 4048;

const LOW_QUALITY_WIDTH = 192;
const LOW_QUALITY_HEIGHT = 108;
const LOW_QUALITY_FPS = 10;
const LOW_QUALITY_BITRATE = 100;


class AgoraClient extends ConnectionClient implements VideoCallClient {

    private agoraClient: IAgoraRTCClient;
    private outgoingTrackMapping: Map<MediaTrack, ILocalTrack>;
    private peers: Map<ID, IAgoraRTCRemoteUser>;
    //private incomingMedia: Map<IAgoraRTCRemoteUser, Set<"audio" | "video">>;
    //private incomingMediaCallback: PeerMediaCallback | undefined;
    private peerMediaAvailabilityCallback: PeerMediaAvailabilityCallback | null = null;
    private peerMediaCallback: PeerMediaCallback | null = null;

    constructor() {
        super();

        AgoraRTC.setLogLevel(2);

        this.agoraClient = AgoraRTC.createClient({mode: 'rtc', codec: 'av1'});
        if (ENABLE_DUAL_STREAMS) {
            this.agoraClient.enableDualStream();
            this.agoraClient.setLowStreamParameter({
                width: LOW_QUALITY_WIDTH, height: LOW_QUALITY_HEIGHT,
                framerate: LOW_QUALITY_FPS, bitrate: LOW_QUALITY_BITRATE,
            });
        }

        this.outgoingTrackMapping = new Map();
        this.peers = new Map();
        //this.incomingMedia = new Map();

        this.agoraClient.on('user-joined', peer => {
            this.peers.set(convertAgoraId(peer.uid), peer);
        });
        this.agoraClient.on('user-left', peer => {
            this.peers.delete(convertAgoraId(peer.uid));
        });

        this.agoraClient.on('user-published', async (peer, mediaType) => {
            //const peerMedia = this.incomingMedia.get(peer) ?? new Set();
            //peerMedia.add(mediaType);
            //this.incomingMedia.set(peer, peerMedia);
            //this.incomingMediaCallback && this.subscribeToPeer(peer, mediaType);
            this.peerMediaAvailabilityCallback && this.peerMediaAvailabilityCallback(
                convertAgoraId(peer.uid), mediaType, true,
            );
        });
        this.agoraClient.on('user-unpublished', async (peer, mediaType: "audio" | "video") => {
            //const publishedMedia = this.incomingMedia.get(peer);
            //if (publishedMedia) {
                //publishedMedia.delete(mediaType);
                //this.incomingMedia.set(peer, publishedMedia);
            //}
            //this.incomingMediaCallback && this.incomingMediaCallback(
                //convertAgoraId(peer.uid), mediaType, undefined,
            //);
            this.peerMediaAvailabilityCallback && this.peerMediaAvailabilityCallback(
                convertAgoraId(peer.uid), mediaType, false,
            );
        });

        //this.agoraClient.on('stream-type-changed', async (uid: UID, streamType) => {});
        //this.agoraClient.on('stream-fallback', (
            //uid: UID, isFallbackOrRecover: "fallback" | "recover"
        //) => {
            //
        //});

        this.notifyConnectionStateChange(this.agoraClient.connectionState);
    }

    async join(userId: ID, conversationId: ID) {
        this.assertJoined(false);
        console.assert(
            this.peerMediaAvailabilityCallback !== null,
            "Joining without media availability callback being set.",
        );
        console.assert(
            this.peerMediaCallback !== null,
            "Joining without media callback being set.",
        );

        this.notifyConnectionStateChange("CONNECTING");

        return getAgoraToken(conversationId).then(
            response => this.agoraClient.join(
                response.appId,
                conversationId.toString(),
                response.token,
                userId.toString()
            ).then(
                _ => {
                    this.agoraClient.on(
                        "connection-state-change",
                        (curState, _) => this.notifyConnectionStateChange(curState),
                    )
                    this.notifyConnectionStateChange("CONNECTED");
                },
                _ => this.notifyConnectionStateChange("DISCONNECTED"),
            ),
        );
    }
    leave() {
        this.notifyConnectionStateChange("DISCONNECTING");

        this.outgoingTrackMapping.clear();
        this.peers.clear();
        //this.incomingMedia.clear();
        this.peerMediaAvailabilityCallback = null;
        this.peerMediaCallback = null;

        this.agoraClient.leave().then(
            _ => this.notifyConnectionStateChange("DISCONNECTED")
            // TODO: which value should be set when disconnecting fails?
        );
    }

    publishUserMedia(mediaTrack: MediaTrack) {
        this.assertJoined(true);

        let agoraTrack = undefined;
        const mediaType = getMediaType(mediaTrack);
        switch (mediaType) {
            case "audio":
                agoraTrack = AgoraRTC.createCustomAudioTrack({
                    //encoderConfig: 'music_standard',
                    encoderConfig: 'speech_standard',
                    mediaStreamTrack: mediaTrack.mediaStreamTrack,
                });
                break;
            case "video":
            case "screen":
                agoraTrack = AgoraRTC.createCustomVideoTrack({
                    bitrateMax: HIGH_QUALITY_MAX_BITRATE,
                    bitrateMin: HIGH_QUALITY_MIN_BITRATE,
                    mediaStreamTrack: mediaTrack.mediaStreamTrack,
                });
                break;
        }
        // TODO: don't republish all tracks all the time
        // TODO: unpublish tracks when they're disabled
        if (agoraTrack) {
            this.outgoingTrackMapping.set(mediaTrack, agoraTrack);
            this.agoraClient.publish(agoraTrack);
        }
    }

    unpublishUserMedia(mediaTrack: MediaTrack) {
        if (!this.isConnected())
            return;

        const agoraTrack = this.outgoingTrackMapping.get(mediaTrack);
        if (!agoraTrack) {
            throw TypeError("Media track has not been published.");
        }
        this.agoraClient.unpublish(agoraTrack);
    }

    onPeerMediaAvailabiiltyChanged(callback: PeerMediaAvailabilityCallback | null) {
        //if (callback === null) {
            //this.assertJoined(false);
        //}
        this.peerMediaAvailabilityCallback = callback;
        //if (!this.peerMediaAvailabilityCallback)
            //return;
//
        //for (const [peer, mediaTypes] of this.incomingMedia.entries()) {
            //for (const mediaType of mediaTypes) {
                //this.peerMediaAvailabilityCallback(
                    //convertAgoraId(peer.uid), mediaType, true,
                //);
            //}
        //}
    }

    onPeerMediaChanged(callback: PeerMediaCallback | null) {
        //if (callback === null) {
            //this.assertJoined(false);
        //}
        this.peerMediaCallback = callback;
    }

    subscribePeerMedia(
        userId: ID, mediaType: PeerMediaType, level: MediaQualityLevel,
    ) {
        const peer = this.peers.get(userId);
        if (!peer) {
            console.error("Cannot subscribe to peer, not found.");
            return;
        }
        this.subscribeToPeer(peer, mediaType, level).then(
            track => this.peerMediaCallback && this.peerMediaCallback(
                convertAgoraId(peer.uid), mediaType, track
            ),
            error => console.log("Error subscribing to user media:", error),
        );
        //else {
            //return new Promise((_, reject) => reject());
        //}
    }

    unsubscribePeerMedia(userId: ID, mediaType: PeerMediaType) {
        const peer = this.peers.get(userId);
        if (peer) {
            this.agoraClient.unsubscribe(peer, mediaType);
            //.then(
                //_ => this.peerMediaCallback && this.peerMediaCallback(
                    //convertAgoraId(peer.uid), mediaType, undefined, 
                //),
            //);
        }
    }

    private async subscribeToPeer<T extends (AudioTrack | VideoTrack)>(
        peer: IAgoraRTCRemoteUser, mediaType: PeerMediaType, level: MediaQualityLevel,
    ): Promise<T> {
        if (mediaType === "video") {
            await this.agoraClient.setRemoteVideoStreamType(
                peer.uid, level === SubscriptionLevel.HIGH ? 0 : 1,
            );
            await this.agoraClient.setStreamFallbackOption(
                peer.uid, 2,
                // = AUDIO_ONLY, i.e. fall back to low-quality video stream and then
                // audio stream only if the network conditions get worse
            );
        }
        const result = this.agoraClient.subscribe(peer, mediaType).then(
            track => {
                switch (mediaType) {
                    case "audio":
                        const audioTrack: AudioTrack = {
                            mediaStreamTrack: track.getMediaStreamTrack(),
                            volume: () => (track as IRemoteAudioTrack).getVolumeLevel()
                        };
                        return audioTrack as T;
                    case "video":
                        const videoTrack: VideoTrack = {
                            mediaStreamTrack: track.getMediaStreamTrack(),
                            isScreen: false,
                        };
                        return videoTrack as T;
                }
            },
        );

        return result;
    }

    private assertJoined(shouldBeJoined: boolean) {
        if (this.isConnected() !== shouldBeJoined) {
            throw TypeError(`Cannot perform this operation with join state ${this.isConnected()}`);
        }
    }
}

function convertAgoraId(uid: UID): ID {
    //return Number(uid);
    return uid.toString();
}


export default AgoraClient;
