import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { ID, Participant } from '../../../../models';
import { UserContext } from '../../../../controllers/UserController';
import { InvitationContext } from '../../../MeetupView/controllers/InvitationController';
import { SidebarContext } from '../../../MeetupSidebar/SidebarController';
import useDetectClickOutside from '../../../../utils/OutsideClickDetector';
import './style.scss';


interface ParticipantMenuProps {
    participant?: Participant;
    isTop: boolean;
    setMenu: (participantId: ID | null) => void;
    avatarRef: React.RefObject<HTMLDivElement>;
}

const ParticipantMenu: React.FC<ParticipantMenuProps> = (props) => {
    const { participant, isTop, setMenu, avatarRef } = props;

    const user = React.useContext(UserContext);
    const isSelfParticipant = participant?.id === user?.participantIdentity.id;
    const invitationState = React.useContext(InvitationContext);
    const { setVisibleTab } = React.useContext(SidebarContext);

    const menuVisible = participant !== undefined;
    const menuRef = React.useRef<HTMLDivElement>(null);
    useDetectClickOutside(() => setMenu(null), menuRef, avatarRef);
    
    const participantInteractionMenu = participant !== undefined
        ? (
            <div
                className={'participant-interaction-menu '
                    + (isTop ? 'is-top' : 'is-bottom')
                }
                ref={menuRef}
            >
                <div className='menu-content'>
                    {isSelfParticipant
                        ? <p className='menu-text'>It's<br/>you!</p>
                        : <div
                            className='menu-button menu-text'
                            onClick={() => {
                                invitationState.invitationSender(
                                    [participant.id],
                                    user?.participantIdentity.conversationId ?? undefined
                                );
                                setMenu(null);
                                setVisibleTab("invitations");
                            }}
                        >
                            Let's<br/>chat!
                        </div>
                    }
                </div>
                <div
                    className={'menu-tip ' + (isTop ? 'is-top' : 'is-bottom')}
                />
            </div>
        ) : null;
    

    return (
        <CSSTransition
            in={menuVisible}
            appear
            mountOnEnter
            timeout={250}
            classNames='transition-fade'
        >
            {participantInteractionMenu}
        </CSSTransition>
    );
}


export default ParticipantMenu;
