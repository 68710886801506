import React from "react";

import { Channel } from '../../controllers/models';
import { ChatContext } from '../../controllers/ChatController';
import ChannelItem from '../ChannelItem/ChannelItem';
import { InfoScreen, InfoType } from '../../../InfoScreen/InfoScreen';

import "./style.scss";


const ChannelList: React.FC = () => {
    const chatState = React.useContext(ChatContext);
    const channelCategories = chatState.channelCategories;
    const channels = chatState.channels;

    const channelList: React.ReactElement[] | null= React.useMemo(() => {
        if (!channels) {
            // The channel list is still loading
            return null;
        }

        const createChannelItem = (
            channel: Channel,
            isGlobalChannel?: boolean,
        ) => (
            <ChannelItem
                displayType="list"
                key={channel.id}
                channel={channel}
                users={chatState.users}
                chatUserId={chatState.chatUserId}
                isGlobalChannel={isGlobalChannel ?? false}
                isCurrentConversationChannel={
                    channel.id === channelCategories.currentConversationChannelId
                }
                isSelectedChannel={
                    channel.id === channelCategories.selectedChannelId
                }
                onSelectChannel={chatState.onSelectChannel}
            />
        );

        const channelList: React.ReactElement[] = [];
        if (channelCategories.globalChannelId) {
            const globalChannel = channels?.get(channelCategories.globalChannelId);
            globalChannel && channelList.push(createChannelItem(
                globalChannel, true,
            ));
        }
        if (channelCategories.currentConversationChannelId) {
            const currentConversationChannel = channels?.get(channelCategories.currentConversationChannelId);
            currentConversationChannel && channelList.push(createChannelItem(
                currentConversationChannel,
            ));
        }
        channelCategories.otherChannelIds?.forEach(channelId => {
            const channel = channels?.get(channelId);
            channel && channelList.push(createChannelItem(channel));
        });

        return channelList;
    }, [
        chatState.users,
        chatState.chatUserId,
        chatState.onSelectChannel,
        channelCategories,
        channels,
    ]);

    if (channelList) {
        return (
            <div className='channel-list'>
                {channelList}
            </div>
        );
    } else {
        return (
            <InfoScreen
                message={"Loading Chat..."}
                infoType={InfoType.LOADING}
            />
        );
    }
}

export default ChannelList;
