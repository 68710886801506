import React from 'react';
import LoaderIcon from 'react-spinners/PuffLoader';
import WarningIcon from 'material-icons-svg/components/baseline/Warning';
import ErrorIcon from 'material-icons-svg/components/baseline/Error';

import './style.scss';

export enum InfoType {
    NOTICE,
    LOADING,
    WARNING,
    ERROR,
}

const ICON_SIZE = '15rem';

interface InfoScreenProps {
    message: string;
    infoType: InfoType;
}

export const InfoScreen: React.FC<InfoScreenProps> = (props) => {
    let icon;
    switch (props.infoType) {
        case InfoType.LOADING:
            icon = <LoaderIcon
                size={ICON_SIZE}
                color='#3399ff'
            />
            break;
        case InfoType.WARNING:
            icon = <WarningIcon
                className='info-screen-icon'
                fill='#ffcc66'
            />
            break;
        case InfoType.ERROR:
            icon = <ErrorIcon
                className='info-screen-icon'
                fill='rgba(255, 80, 80, 128)'
            />;
            break;
    }
    return (
        <div className='info-screen-container'>
            <div className='info-screen-center'>
                <h2 className='info-screen-message'>
                    {props.message}
                </h2>
                {icon}
            </div>
        </div>
    );
}

