import React from 'react';
import {
    gql,
    useQuery,
    useSubscription,
    OnSubscriptionDataOptions
} from '@apollo/client';

import { UID, SID } from '../../../models';
import { Message } from './models';


// The channelId argument is used to indicate that data for a channel has been
// loaded, so that channels without messages can be shown as empty rather
// than still loading.
type NewMessagesCallback = React.RefObject<(newMessages: Message[], channelId?: SID) => void>;

const MESSAGES_QUERY = gql`
    query OnLoadMessages($chatspaceId: uuid!, $channelId: Int!, $sentThreshold: timestamptz!) {
        messages: chat_message(
            where: {_and: [
                {chatspace_id: {_eq: $chatspaceId}},
                {channel_id: {_eq: $channelId}},
                {sent_at: {_lte: $sentThreshold}},
            ]},
            order_by: {sent_at: asc},
        ) {
            id
            channelId: channel_id,
            senderId: sender_id,
            sentAt: sent_at
            type
            content
        }
    }
`;

interface MessageQueryResult {
    messages: (Message & {
        sentAt: string;
    })[];
}

interface MessageQueryVariables {
    chatspaceId: UID;
    channelId: SID;
    sentThreshold: string;
}

// TODO: use pagination
export function usePreviousMessages(
    chatspaceId: UID,
    channelId: SID | undefined,
    sentThreshold: Date,
    callback: NewMessagesCallback,
) {
    const [loadedChannels, setLoadedChannels] = React.useState<Set<SID>>(new Set([]));

    const messagesLoadedCallback: (data?: MessageQueryResult) => void = React.useCallback((data) => {
        if (!data || !callback.current || !channelId)
            return;

        // TODO: there could be race conditions, add the channel id as soon as loading starts
        setLoadedChannels(loadedChannels => {
            if (loadedChannels.has(channelId))
                return loadedChannels;

            const newLoadedChannels = new Set(loadedChannels);
            newLoadedChannels.add(channelId);
            return newLoadedChannels;
        });

        callback.current(data.messages.map(message => ({
            ...message,
            sentAt: new Date(message.sentAt),
        })), channelId);
    }, [channelId, callback]);

    useQuery<MessageQueryResult, MessageQueryVariables>(
        MESSAGES_QUERY,
        {
            variables: {
                chatspaceId: chatspaceId,
                channelId: channelId!,
                sentThreshold: sentThreshold.toISOString(),
            },
            onCompleted: messagesLoadedCallback,
            skip: channelId === undefined || loadedChannels.has(channelId),
        }
    );
}


const NEW_MESSAGES_SUBSCRIPTION = gql`
    subscription OnMessageReceived($chatspaceId: uuid!, $lastFetched: timestamptz!) {
        messages: chat_message(
            where: {_and: [
                {chatspace_id: {_eq: $chatspaceId}},
                {sent_at: {_gt: $lastFetched}},
            ]},
            order_by: {sent_at: asc},
        ) {
            id,
            channelId: channel_id,
            senderId: sender_id,
            sentAt: sent_at
            type
            content
        }
    }
`;

interface MessageSubscriptionVariables {
    chatspaceId: UID;
    lastFetched: string;
    //channelId: SID;
}

// TODO: use subscribe for more with messages query
export function useNewMessages(
    chatspaceId: UID, timeThreshold: Date, callback: NewMessagesCallback,
) {
    //const lastFetchedTime  = React.useRef(new Date());
    const [lastFetchedTime, setLastFetchedTime] = React.useState<Date>(timeThreshold);
    const newMessagesCallback: (options: OnSubscriptionDataOptions<MessageQueryResult>) => void = React.useCallback(({ subscriptionData }) => {
        const data = subscriptionData.data;
        if (!data || data.messages.length === 0)
            return;

        // Update the last-read timestamp
        setLastFetchedTime(lastFetchedTime => {
            if (!callback.current)
                return lastFetchedTime;

            let maxNewTimestamp = lastFetchedTime;
            const newMessages: Message[] = [];

            for (const message of data.messages) {
                const sentTime = new Date(message.sentAt);
                //console.assert(
                    //sentTime > maxNewTimestamp,
                    //"Inconsistent message timestamp"
                //);
                if (sentTime > maxNewTimestamp) {
                    maxNewTimestamp = sentTime;
                    newMessages.push({
                        ...message,
                        sentAt: sentTime,
                    });
                }
            }
            callback.current(newMessages);

            return maxNewTimestamp;
        });
    }, [callback]);

    useSubscription<MessageQueryResult, MessageSubscriptionVariables>(
        NEW_MESSAGES_SUBSCRIPTION,
        {
            variables: {
                chatspaceId: chatspaceId,
                lastFetched: lastFetchedTime.toISOString(),
                //channelId: chatState.selectedChannel?.id ?? 0,
            },
            onSubscriptionData: newMessagesCallback,
        }
    );
}
