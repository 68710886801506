import { Force } from 'd3-force';
import {
    QuadtreeInternalNode,
    QuadtreeLeaf,
    quadtree,
} from 'd3-quadtree';

import { ID } from '../../../../models';
import { SimNode, SimLink, Position } from '../../models';


function isLeaf<T>(node: QuadtreeInternalNode<T> | QuadtreeLeaf<T>): node is QuadtreeLeaf<T> {
    return node.length === undefined;
}

export function forceCollide(
    collisionStrength: number, nodeSeparation: number,
): Force<SimNode, SimLink> {
    let nodes: SimNode[] = [];
    //const maxRadius = Math.max(USER_NODE_RADIUS, CONVERSATION_NODE_RADIUS) + NODE_SEPARATION;
    let maxRadius: number;

    function force() {
        const quadTree = quadtree(nodes, d => d.x ?? 0, d => d.y ?? 0);

        for (const d of nodes) {
            if (d.x === undefined || d.y === undefined) continue;
            const dx = d.x;
            const dy = d.y;
            const dr = d.r;

            const r = dr + maxRadius;
            const nx1 = dx - r;
            const ny1 = dy - r;
            const nx2 = dx + r;
            const ny2 = dy + r;
            quadTree.visit((q, x1, y1, x2, y2) => {
                if (isLeaf(q)) do {
                    if (!isLeaf(q)) continue;
                    // Don't collide with yourself
                    if (q.data === d) continue;

                    // Don't collide with the conversation that you're a part of
                    const oneIsConversation = d.type === "conv" || q.data.type === "conv";
                    if (
                        oneIsConversation
                        && d.conversationId === q.data.conversationId
                    ) continue;
                    //console.log("colliding", d, q);
                    //debugger;

                    // Don't collide with conversations while being dragged, else
                    // drag and drop wouldn't be possible
                    if (
                        oneIsConversation
                        && (
                            (d.fx != null && d.fy != null)
                            || (q.data.fx != null && q.data.fy != null)
                        )
                    ) continue;

                    if (q.data.x === undefined || q.data.y === undefined) continue;

                    const qr = q.data.r;

                    //const r = dr + qr + (d.data.group === q.data.data.group ? padding1 : padding2);
                    const r = dr + qr + nodeSeparation;
                    const qx = q.data.x;
                    const qy = q.data.y;
                    let x = dx - qx;
                    let y = dy - qy;
                    let l = Math.hypot(x, y);
                    if (l === 0) l = 1;
                    if (l < r) {
                        l = (l - r) / l * collisionStrength;
                        x *= l;
                        y *= l;
                        d.x = dx - x;
                        d.y = dy - y;
                        q.data.x = qx + x;
                        q.data.y = qy + y;
                    }
                } while (q = (q as any).next);

                return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
            });
        }
    }

    //force.initialize = _ => maxRadius = d3.max(nodes = _, d => d.r) + Math.max(padding1, padding2);
    //force.initialize = () => {
        //maxRadius = 
    //}
    force.initialize = (_: SimNode[]) => {
        nodes = _;
        maxRadius = nodes.reduce(
            (maxRadius, node) => Math.max(maxRadius, node.r), 0,
        );
    };

    return force;
}


export function forceConversationCluster(
    clusterStrength: number
): Force<SimNode, SimLink> {
    let nodes: SimNode[];

    function force(alpha: number) {
        const strength = alpha * clusterStrength;

        const convCenters = new Map<ID, Position>(nodes
            .filter(node => node.type === "conv")
            .map(conv => [conv.id, {x: conv.x ?? 0, y: conv.y ?? 0}])
        );

        for (const d of nodes) {
            if (
                !d.conversationId
                || d.type === "conv"
            ) continue;
            if (
                d.x === undefined
                || d.y === undefined
                || d.vx === undefined
                || d.vy === undefined
            ) continue;
            
            const convCenter = convCenters.get(d.conversationId);
            if (!convCenter) continue;
            const convX = convCenter.x;
            const convY = convCenter.y;

            const dist = Math.hypot(d.x - convX, d.y - convY);
            d.vx -= (d.x - convX) * strength * dist;
            d.vy -= (d.y - convY) * strength * dist;
        }
    }

    force.initialize = (_: SimNode[]) => {
        nodes = _;
    };

    return force;
}
