import {
    SimulationNodeDatum,
    SimulationLinkDatum,
} from 'd3-force';

import { ID } from '../../models';


export type SimNode = SimulationNodeDatum & {
    id: ID;
    type: "user" | "conv";
    conversationId: ID | null;
    r: number;
    posInitialized?: boolean;
};

export type SimLink = SimulationLinkDatum<SimNode>;


export interface Position {
    x: number;
    y: number;
}


export const CONVERSATION_NODE_BASE_RADIUS = 100;
