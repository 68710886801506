import { API_HOST, CSRF_TOKEN_NAME } from '../consts';
import { ID } from '../models';
import { MeetupInfo } from './MeetupController';
import { CheckinUser, UserStub } from './UserController';


let _csrfToken: string | null = null;

export async function getCsrfToken(): Promise<string> {
    if (_csrfToken === null) {
        const response = await fetch(`${API_HOST}/csrf/`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await response.json();
        _csrfToken = data.csrfToken;
    }
    return _csrfToken!;
}

async function fetchAuth(url: string, method='POST', body: any=undefined): Promise<Response> {
    return await fetch(`${API_HOST}/${url}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //...((method === 'POST' || method === 'PUT')
                //&& {[CSRF_TOKEN_NAME]: await getCsrfToken()}),
            [CSRF_TOKEN_NAME]: await getCsrfToken(),
        },
        credentials: 'include',
        body: JSON.stringify(body),
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response;
    });
}

async function fetchJson(url: string, method='POST', body: any=undefined): Promise<any> {
    const response = await fetchAuth(url, method, body);
    return await response.json();
}

export async function resolveMeetup(urlId: string): Promise<MeetupInfo|null> {
    const data = fetchJson(`meetups/resolve/?url_id=${urlId}`, 'GET');
    return data.then(
        (data: any) => {
            return {
                id: data.id.toString(),
                name: data.name,
                urlId: urlId,
                startTime: data.startTime,
                endTime: data.endTime,
                hasPassword: data.hasPassword,
                chatspaceId: data.chatspaceId,
                rootChatChannelId: data.rootChatChannelId,
            };
        },
        error => {
            return null;
        }
    );
}

export async function loadUser(meetupId: ID): Promise<UserStub> {
    const response = fetchJson(`sessions/info/?mid=${meetupId}`, 'GET');
    return parseUserResponse(response);
}

export async function getIceInfo(): Promise<any> {
    const response = await fetchJson('xirsys-auth/', 'GET');
    return response;
}

interface AgoraTokenResponse {
    appId: string;
    token: string;
}

export async function getAgoraToken(conversationId: ID): Promise<AgoraTokenResponse> {
    const response = fetchJson(`conversations/${conversationId}/agora_auth/`, 'PUT');
    return response.then(
        (success: any) => ({
            appId: success.appId,
            token: success.token,
        })
    );
}

export async function getChatToken(): Promise<string> {
    const response = fetchJson(`chat-auth/`, 'GET');
    return response.then((success: any) => success.token);
}

export async function verifyPassword(meetupId: ID, password: string): Promise<boolean> {
    const response = await fetchJson(`meetups/${meetupId}/verify_password/`,
            'PUT', {password: password})
    return response.password_ok;
}

export async function checkInUser(meetupId: ID, checkinUser: CheckinUser): Promise<UserStub> {
    const response = fetchJson(`sessions/checkin/`, 'POST',
            {meetup: meetupId, ...checkinUser});
    return parseUserResponse(response);
}

async function parseUserResponse(data: Promise<any>): Promise<UserStub> {
    return data.then(
        (data: any) => {
            return {
                participantId: data.id.toString(),
                chatId: data.chatParticipantId,
            }
        },
        // TODO: check status code
        //error => null
    );
}

export async function nameConversation(conversationId: string | undefined, name: string | null) {
    fetchJson(
        `conversations/${conversationId ?? 0}/name_conversation/`,
        'POST',
        { name: name }
    );
}

export async function setScreenSharing(
    conversationId: string,
    isScreenSharing: boolean
) {
    fetchJson(
        `conversations/${conversationId}/set_screen_sharing/`,
        'PUT',
        { isScreenSharing: isScreenSharing }
    );
}
