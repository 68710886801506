import React from 'react';

import { CurrentConversationState } from '../../MeetupView/controllers/ConversationController';
import { VideoCallClient } from './clients/VideoCallClient';
import { useConnectionState } from '../../../utils/ConnectionMonitoredClient';


export function useCallFullyJoined(
    conversationState: CurrentConversationState,
    client: VideoCallClient,
): boolean {
    const clientConnectionState = useConnectionState(client);

    return React.useMemo(() => (
        conversationState.conversation !== undefined
        && conversationState.joinStatus === 'success'
        && clientConnectionState === 'CONNECTED'
    ), [conversationState, clientConnectionState]);
}



const N_FFT_BINS = 512;
const LOWEST_FREQUENCY_IDX = 4;
const SMOOTHING_VALUE = 0.1;
// Subtract this to make the local volume values comparable with those from
// the Agora API.
// TODO: find a better way to ensure compatibility
const AGORA_COMPATIBILITY_OFFSET = -10;

// Dummy function because volume monitoring seems to introduce lag in the
// audio stream and right now it's only needed for the speaker view where it's
// fine to only show others as speakers.
// TODO: make it faster and reintroduce it if necessary
export function useVolumeMeter(): (audioTrack: MediaStreamTrack) => (() => number) {
    return (_: MediaStreamTrack) => () => -1;
}

//const audioContext = new AudioContext();
//
//export function useVolumeMeter(): (audioTrack: MediaStreamTrack) => (() => number) {
    //const createVolumeMonitor: (
        //audioTrack: MediaStreamTrack
    //) => () => number = React.useCallback((audioTrack) => {
        //const audioStream = new MediaStream([audioTrack]);
        //const sourceNode = audioContext.createMediaStreamSource(audioStream);
//
        //const analyserNode = audioContext.createAnalyser();
        //analyserNode.fftSize = N_FFT_BINS;
        //analyserNode.smoothingTimeConstant = SMOOTHING_VALUE;
        //analyserNode.maxDecibels = 0;
        //sourceNode.connect(analyserNode);
//
        //const volumeMonitor = () => getMaxVolume(analyserNode);
        //// TODO: should we disconnect the nodes?
        ////const cleanup = async () => {
            ////console.log("cleaning up");
            ////analyserNode.disconnect();
            ////sourceNode.disconnect();
        ////};
//
        //return volumeMonitor;
    //}, []);
//
    //return createVolumeMonitor;
//}
//
//const fftBins = new Uint8Array(N_FFT_BINS);
//
//// Adapted from https://github.com/otalk/hark/blob/master/hark.js
//function getMaxVolume(analyser: AnalyserNode): number {
    //analyser.getByteFrequencyData(fftBins);
//
    //let avgVolume = 0;
    //for (let i = LOWEST_FREQUENCY_IDX; i < N_FFT_BINS; i++) {
        //const frequencyValue = fftBins[i];
        //avgVolume += frequencyValue;
    //}
    //avgVolume /= (N_FFT_BINS - LOWEST_FREQUENCY_IDX);
    //avgVolume = Math.max(0, avgVolume + AGORA_COMPATIBILITY_OFFSET);
//
    //return avgVolume / 255;
//}
