import React from 'react';

import { ID, Invitation, ID_KEY, INVITATION_KEY } from '../../../models';
import { BackendRTCContext } from '../../../controllers/BackendRTC';
import { useBackendRTCBackedMap } from '../../../controllers/BackendRTCUtils';
import { UserContext } from '../../../controllers/UserController';


const INVITE_ACTION = "invite";
const ACCEPT_ACTION = "accept";
const REJECT_ACTION = "reject";

interface InvitationInfo {
    id: ID;
    senderId: ID;
    recipientIds: ID[];
    conversationId?: ID;
}

type SendInvitationCallback = (recipientIds: ID[], conversationId?: ID) => void;

export interface InvitationState {
    sentInvitation: Invitation|undefined;
    receivedInvitations: Invitation[];
    invitationSender: SendInvitationCallback;
}

export function useInvitations(): InvitationState {
    const backendRTC = React.useContext(BackendRTCContext);
    const user = React.useContext(UserContext);
    const userId = user!.participantIdentity.id;

    const respondToInvitation = React.useCallback((
        responseAction: ("accept" | "reject"),
        invitationId: ID,
    ) => {
        if (!backendRTC)
            return
        backendRTC.send(INVITATION_KEY, responseAction, {[ID_KEY]: invitationId});
    }, [backendRTC]);

    const receivedInvitationMap = useBackendRTCBackedMap(backendRTC, INVITATION_KEY, invitationParser);
    const [sentInvitation, receivedInvitations]: [Invitation|undefined, Invitation[]]
        = React.useMemo(() => {
            let sentInvitation: Invitation|undefined;
            const receivedInvitations: Invitation[] = [];

            [...receivedInvitationMap.values()].forEach(invitationInfo => {
                const invitationId = invitationInfo.id;
                const invitation = {
                    ...invitationInfo,
                    accept: () => respondToInvitation(ACCEPT_ACTION, invitationId),
                    reject: () => respondToInvitation(REJECT_ACTION, invitationId), 
                };
                if (invitation.senderId === userId) {
                    sentInvitation = invitation;
                } else {
                    receivedInvitations.push(invitation);
                }
            });

            return [sentInvitation, receivedInvitations];
        }, [userId, respondToInvitation, receivedInvitationMap]);
    
    const sendInvitation: SendInvitationCallback = React.useCallback((recipientIds, conversationId?) => {
        if (!backendRTC || !user)
            return

        // TODO: should the id be generated here or on the server?
        const invitationInfo = {
            senderId: user.participantIdentity.id,
            recipientIds: recipientIds,
            conversationId: conversationId,
        };
        backendRTC.send(INVITATION_KEY, INVITE_ACTION, invitationInfo);
    }, [backendRTC, user]);

    return React.useMemo<InvitationState>(() => ({
        sentInvitation: sentInvitation,
        receivedInvitations: receivedInvitations,
        invitationSender: sendInvitation,
    }), [sentInvitation, receivedInvitations, sendInvitation]);
}

//sendInvitation: (recipients: ID[], conversation?: ID) => void;

function invitationParser(data: any, prevInvitation?: InvitationInfo): InvitationInfo {
    return {
        id: data.id.toString(),
        senderId: data.senderId?.toString() ?? prevInvitation?.senderId,
        recipientIds: data.recipientIds?.map((recipientId: ID) => recipientId.toString())
            ?? prevInvitation?.recipientIds,
        conversationId: data.conversationId !== undefined
            ? data.conversationId?.toString() : prevInvitation?.conversationId,
    };
}

export const InvitationContext = React.createContext<InvitationState>({
    sentInvitation: undefined,
    receivedInvitations: [],
    invitationSender: (_, __) => { /* Placeholder */ },
});
InvitationContext.displayName = 'InvitationContext';
