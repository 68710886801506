import React from 'react';

import JoinIcon from 'material-icons-svg/components/baseline/ArrowUpward';
import LeaveIcon from 'material-icons-svg/components/baseline/Close';

import { ID, Conversation } from '../../../../models';
import { CONVERSATION_NODE_BASE_RADIUS } from '../../models';
import './style.scss';


export type JoinStatus = "out" | "in" | "joining" | "leaving";

interface ConversationGroupProps {
    conversation: Conversation;
    joinStatus: JoinStatus;
    joinConversation?: (conversationId: ID | null) => void;
    radius: number;
}

const ConversationGroup: React.FC<ConversationGroupProps> = (props) => {
    const { conversation, joinStatus, joinConversation, radius } = props;

    let joinStatusFeeback;
    switch (joinStatus) {
        case "in":
            joinStatusFeeback = <LeaveIcon className='join-icon'/>;
            break;
        case "out":
            joinStatusFeeback = <JoinIcon className='join-icon'/>;
            break;
        case "joining":
            joinStatusFeeback = <p className='join-message'>Joining</p>;
            break;
        case "leaving":
            joinStatusFeeback = <p className='join-message'>Leaving</p>;
            break;
    }
    
    return (
        <div className='conversation-group'>
            <div className={
                'join-feedback '
                + (joinStatus === 'joining' || joinStatus === 'leaving'
                ? 'visible' : 'hidden')
            }/>
            <div className='conversation-name-box'>
                {conversation.name}
            </div>
            <div
                className='join-button'
                onClick={
                    joinConversation
                    ? () => joinConversation(
                        joinStatus === "in" || joinStatus === "leaving"
                            ? null : conversation.id
                    )
                    : undefined
                }
            >
                {joinStatusFeeback}
            </div>
            <ConversationCircle
                radius={radius}
                conversationId={conversation.id}
            />
        </div>
    );
}



//const CIRCLE_RADIUS = 50;
const CIRCLE_THICKNESS = 10;
const BUTTON_RADIUS = 200;
const BUTTON_OFFSET = 150;


interface ConversationCircleProps {
    radius: number;
    fillFrac?: number;
    conversationId: ID;
}

const ConversationCircle: React.FC<ConversationCircleProps> = (props => {
    let { radius } = props;
    const { conversationId } = props;
    const emptyColor = 'rgb(128, 128, 128)';

    if (radius === 0) radius = CONVERSATION_NODE_BASE_RADIUS;
    // Circle parameters
    const circleCenter = {
        x: radius,
        y: radius,
    }

    // Subtract a very small offset, else the circle will wrap
    // around and not be closed.
    //const utilizationAngle = props.fillFrac * 2 * Math.PI - 0.00001;
    const utilizationAngle = 2 * Math.PI - 0.00001;
    const utilizationX = Math.sin(utilizationAngle) * radius + circleCenter.x;
    const utilizationY = -Math.cos(utilizationAngle) * radius + circleCenter.y;
    const largeArcFlag = utilizationAngle > Math.PI ? 1 : 0;

    const circleSize = 2 * radius;
    return (
        <svg
            className='circle'
            //width={`${props.width}px`} height={`${props.height}px`}
            width={`${circleSize}px`}
            height={`${circleSize}px`}
            viewBox={`0 0 ${circleSize} ${circleSize}`}
        >{/*> style={{ position: 'relative' }} ><]*/}
            <mask id={`c-${conversationId}-button-mask`}>
                <mask id={`c-${conversationId}-inner-button-mask`}>
                    <circle
                        cx={circleCenter.x}
                        cy={circleCenter.y}
                        r={radius}
                        fill='white'
                    />
                </mask>
                <circle
                    cx={circleCenter.x}
                    cy={circleSize + BUTTON_OFFSET}
                    r={BUTTON_RADIUS}
                    fill='white'
                    mask={`url(#c-${conversationId}-inner-button-mask)`}
                />
            </mask>
            <rect
                x={0}
                y={0}
                width={circleSize}
                height={circleSize}
                fill={'#888'}
                mask={`url(#c-${conversationId}-button-mask)`}
            />
            <mask id={`c-${conversationId}-maskCircle`}>
                <circle
                    cx={circleCenter.x}
                    cy={circleCenter.y}
                    r={radius}
                    fill='white'
                />
                <circle
                    cx={circleCenter.x}
                    cy={circleCenter.y}
                    r={radius - CIRCLE_THICKNESS}
                    fill='black'
                />
            </mask>
            <rect
                x={0}
                y={0}
                width={circleSize}
                height={circleSize}
                fill={emptyColor}
                mask={`url(#c-${conversationId}-maskCircle)`}
            />
            <path
                className='fill-indicator'
                d={`M ${circleCenter.x} ${circleCenter.y}
                    L ${circleCenter.x} ${circleCenter.y - radius}
                    A ${radius } ${radius}, 0, ${largeArcFlag}, 1, ${utilizationX} ${utilizationY}
                    Z`}
                //fill={accentColor1}
                mask={`url(#c-${conversationId}-maskCircle)`}
            />
        </svg>
    );
});


export default ConversationGroup;
