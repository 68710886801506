import React from 'react';

import { VideoCallPeer, SubscriptionLevel } from '../../controllers/models';
import PeerItem from '../PeerItem/PeerItem';
import './style.scss';


interface SelfViewProps {
    userPeer: VideoCallPeer;
}

const SelfView: React.FC<SelfViewProps> = (props => {
    //if (props.mediaState.video || props.mediaState.screen) {
    return (
        <div className='self-view-container' >
            <PeerItem
                peer={props.userPeer}
                videoQualityLevel={SubscriptionLevel.HIGH}
                isUser={true}
                isActiveSpeaker={false}
                hideFooter={true}
            />
        </div>
    );
    //} else {
        //return null;
    //}
});

export default SelfView;
