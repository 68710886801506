import React from 'react';

import './style.scss';


interface ControlButtonProps {
    onClick: () => void;
    icon: React.SFC<React.SVGProps<SVGSVGElement>>;
    disabled?: boolean;
    classes?: string;
    iconClasses?: string;
}

function ControlButton(props: ControlButtonProps): React.ReactElement {
    return (
        <button
            className={'control-button ' + (props.classes ?? '')}
            onClick={props.onClick}
            disabled={props.disabled !== undefined && props.disabled === true}
        >
            {<props.icon className={'button-icon ' + (props.iconClasses ?? '')} />}
        </button>
    );
}

export default ControlButton;

