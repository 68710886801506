import React from 'react';


function useDetectClickOutside(callback: () => void, ...refs: React.RefObject<any>[]) {
    React.useEffect(() => {
        function handleClickOutside(event: Event) {
            if (!refs.some(ref => ref.current && ref.current.contains(event.target))) {
                // Click outside
                callback();
            }
        }

        function handleEscapeKeypress(event: KeyboardEvent) {
            if (event.key === "Escape" || event.key === "Esc") {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keyup", handleEscapeKeypress);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keyup", handleEscapeKeypress);
        };
    }, [callback, refs]);
}


export default useDetectClickOutside;
