import React from 'react';
//import { CometChat } from '@cometchat-pro/chat';

import { ID, Meetup, User } from '../models';
import * as Backend from './BackendConnection';


export interface CheckinUser {
    password: string;
    name: string;
    avatarImage: string;
    isSelfie: boolean;
}

export interface UserStub {
    participantId: ID;

    // TODO: load after checkin
    chatId: number;
}

export function useUserStub(meetupId: ID | undefined): [UserStub|null, CheckinFunction] {
    const [userStub, setUserStub] = React.useState<UserStub|null>(null);

    // Try to load the user for the meetup without using the checking function,
    // i.e. when the user is already checked in, the session cookie will be set
    // and no additional checkin steps are needed
    React.useEffect(() => {
        if (!meetupId) {
            return;
        }
        Backend.loadUser(meetupId).then(
            result => setUserStub(result),
            _ => {
                //console.log("User is not checked in yet.");
            },
        );
    }, [meetupId]);

    const checkinUser: CheckinFunction = React.useCallback((userData: CheckinUser) => {
        if (!meetupId) {
            // TODO: emit error
            return;
        }
        Backend.checkInUser(meetupId, userData).then(
            //result => { processUserResult(result); },
            result => setUserStub(result),
            error => {
                console.log("Error checking in:", error);
            }
        );
        // TODO: setup other service identities here
    }, [meetupId]);

    return [userStub, checkinUser];
}


type CheckinFunction = (userData: CheckinUser) => void;

export function useUser(
    meetup: Meetup|null,
    userStub: UserStub|null,
): User|null {
    const [user, setUser] = React.useState<User|null>(null);
    //const peer = useVideoChatUser(userStub?.participantIdentity);
    //const [cometChatUserToken, setCometChatUserToken] = React.useState<string|undefined>();
    // TODO: use the result of the hook?
    //useTextChatUser(cometChatUserToken, user?.participantIdentity!);
    
    const userParticipant = userStub && meetup && meetup.participants.get(userStub.participantId);
    React.useEffect(() => {
        if (!userParticipant)
            return;
        //setCometChatUserToken(userStub.textChatToken);
        
        setUser({
            participantIdentity: userParticipant,
            chatId: userStub!.chatId,
        });
    }, [userParticipant, userStub]);

    return user;
}

export const UserContext = React.createContext<User|null>(null);
UserContext.displayName = 'UserContext';

