import React from 'react';


export type ConnectionState = "DISCONNECTED" | "CONNECTING" | "CONNECTED" | "DISCONNECTING" | "RECONNECTING";

export interface ConnectionMonitoredClient {
    getConnectionState: () => ConnectionState;
    isConnected: () => boolean;
    addConnectionStateListener: (callback: (ConnectionState: ConnectionState) => void) => void;
    deleteConnectionStateListener: (callback: (ConnectionState: ConnectionState) => void) => void;
}

export class ConnectionClient implements ConnectionMonitoredClient {
    private currentConnectionState: ConnectionState = "DISCONNECTED";
    private connectionStateChangeCallbacks: ((connectionState: ConnectionState) => void)[] = [];

    getConnectionState(): ConnectionState {
        return this.currentConnectionState;
    }

    isConnected(): boolean {
        return this.currentConnectionState === "CONNECTED";
    }

    addConnectionStateListener(
        callback: (connectionState: ConnectionState) => void
    ): void {
        this.connectionStateChangeCallbacks.push(callback);
        // TODO: is this necessary?
        callback(this.getConnectionState());
    }

    deleteConnectionStateListener(
        callback: (connectionState: ConnectionState) => void
    ): void {
        this.connectionStateChangeCallbacks = this.connectionStateChangeCallbacks.filter(
            item => item !== callback
        );
    }

    protected notifyConnectionStateChange(
        newConnectionState: ConnectionState
    ): void {
        this.currentConnectionState = newConnectionState;
        for (const callback of this.connectionStateChangeCallbacks) {
            callback(newConnectionState);
        }
    }
}

export function useConnectionState(client: ConnectionMonitoredClient|null): ConnectionState {
    const [connectionState, setConnectionState] = React.useState<ConnectionState>(
        client?.getConnectionState() ?? "DISCONNECTED"
    );

    React.useEffect(() => {
        if (!client) {
            setConnectionState("DISCONNECTED");
            return;
        }

        const connectionStateListener = (newConnectionState: ConnectionState) => {
            setConnectionState(newConnectionState);
        };
        client.addConnectionStateListener(connectionStateListener);
        setConnectionState(client.getConnectionState());
        return () => {
            client.deleteConnectionStateListener(connectionStateListener);
        }
    }, [client]);

    return connectionState;
}
