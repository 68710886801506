import React from 'react';
import { CSSTransition } from 'react-transition-group';
//import ChatBubble from 'material-icons-svg/components/baseline/ChatBubble';
//import NotificationsIcon from 'material-icons-svg/components/round/Notifications';
import CloseIcon from 'material-icons-svg/components/baseline/Close';

//import ParticipantView from '../ParticipantView/ParticipantView';
import ChatView from '../ChatView/ChatView';
import InvitationView from '../InvitationView/InvitationView';
import { SidebarContext } from './SidebarController';
//import SettingsView from '../SettingsView/SettingsView';
import './style.scss';


const TRANSITION_DURATION = 250;

const Sidebar: React.FC = (props) => {
    const { visibleTab, setVisibleTab } = React.useContext(SidebarContext);

    //let title: string | undefined = undefined;
    let title;
    let content;
    switch (visibleTab) {
        case "chat":
            title = (<h3 className="title">
                <i className="fas fa-comment-dots" /> Chat
            </h3>);
            content = <ChatView />;
            break;
        case "invitations":
            title = (<h3 className="title">
                <i className="fas fa-hand-point-right"/> Invitations
            </h3>);
            content = <InvitationView />;
            break;
        default:
            title = null;
            content = null;
            break;
    }

    const header = (
        <div className='sidebar-body-header'>
            <div
                className='close-button'
                onClick={() => setVisibleTab("none")}
                style={{visibility: visibleTab === "none" ? "hidden" : "visible"}}
            >
                <CloseIcon className='close-icon'/>
            </div>
            {title}
        </div>
    );

    return (
        <CSSTransition
            in={visibleTab !== "none"}
            appear
            timeout={TRANSITION_DURATION}
            mountOnEnter
            classNames='transition-slide'
        >
            <div className='sidebar-body'>
                {/*
                <CSSTransition
                    in={visibleTab !== "none"}
                    appear
                    timeout={TRANSITION_DURATION}
                    mountOnEnter
                    classNames='transition-right'
                >
                    <div className='sidebar-body-slider'>
                */}
                        {header}
                        <div className='sidebar-body-content'>
                            {content}
                        </div>
                {/*
                    </div>
                <CSSTransition
                </CSSTransition>
                */}
            </div>
        </CSSTransition>
    );
}

export default Sidebar;
