import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useForm } from 'react-hook-form';

import { nameConversation } from '../../controllers/BackendConnection';
import CancelIcon from 'material-icons-svg/components/baseline/Clear';
import SubmitIcon from 'material-icons-svg/components/baseline/Done';

import './style.scss';


interface NameConversationControlProps {
    visible: boolean;
    onClose: () => void;
    conversationId?: string;
}

interface NameConversationFormValues {
    conversationName: string;
}

const MAX_NAME_LENGTH = 100;

function NameConversationControl(props: NameConversationControlProps): React.ReactElement {
    const conversationId = props.conversationId;

    const { register, handleSubmit } = useForm<NameConversationFormValues>();

    const background = (
        <div
            className='name-conversation-background'
            onClick={props.onClose}
        ></div>
    );

    const controlPanel = (
        <div
            className='name-conversation-control'
            onClick={event => event.nativeEvent.stopPropagation()}
        >
            <div className='name-conversation-header'>
                {(conversationId === undefined)
                    // Create a new conversation
                    ? "Create a conversation"
                    : "Rename the conversation"
                }
            </div>

            <form
                className="name-conversation-form"
                onSubmit={handleSubmit(values => {
                    const trimmedName = values.conversationName.trim();
                    nameConversation(props.conversationId, trimmedName);
                    props.onClose();
                })}
            >
                <input
                    {...register("conversationName", { maxLength: MAX_NAME_LENGTH })}
                    className="name-input-field"
                    placeholder="Name (optional)"
                    maxLength={MAX_NAME_LENGTH}
                />
                <div className='name-conversation-buttons'>
                    <button
                        className='control-button'
                        type="button"
                        onClick={props.onClose}
                    >
                        <CancelIcon className='control-button-icon cancel'/>
                    </button>
                    <button
                        className='control-button'
                        type="submit"
                    >
                        <SubmitIcon className='control-button-icon submit'/>
                    </button>
                </div>
            </form>
        </div>
    );

    const duration = 250;

    return (
        <>
            <CSSTransition
                in={props.visible}
                timeout={duration}
                mountOnEnter
                unmountOnExit
                classNames='name-conversation'
            >
                {controlPanel}
            </CSSTransition>
            <CSSTransition
                in={props.visible}
                timeout={duration}
                mountOnEnter
                unmountOnExit
                classNames='name-conversation'
            >
                {background}
            </CSSTransition>
        </>
    );
}

export default NameConversationControl;
