import React from "react";
import ReactTooltip from "react-tooltip";

import { InvitationContext } from '../../controllers/InvitationController';
import { ChatContext } from '../../../ChatView/controllers/ChatController';
import { SidebarContext } from '../../../MeetupSidebar/SidebarController';

import "./style.scss";


interface HeaderButtonsProps {
    onCreateConversationClick?: () => void;
}

export const HeaderButtons: React.FC<HeaderButtonsProps> = (props) => {
    const { visibleTab, setVisibleTab } = React.useContext(SidebarContext);
    const onCreateConversationClick = props.onCreateConversationClick;
    const chatState = React.useContext(ChatContext);
    const invitationState = React.useContext(InvitationContext);

    const numUnreadMessages = React.useMemo(() => {
        let numUnreadMessages = 0;
        if (chatState.channels) {
            for (const channel of chatState.channels.values()) {
                numUnreadMessages += channel.msgState?.numUnread ?? 0;
            }
        }
        return numUnreadMessages;
    }, [chatState.channels]);
    const unreadChatCounter = numUnreadMessages > 0 && (
        <div className='unseen-notification-indicator'>
            {numUnreadMessages}
        </div>
    );

    const unreadInvitationsCounter = invitationState.receivedInvitations.length > 0 && (
        <div className='unseen-notification-indicator'>
            {invitationState.receivedInvitations.length}
        </div>
    );

    return (
        <div className='header-buttons'>
            {onCreateConversationClick && (<>
                <button
                    className="header-button"
                    onClick={props.onCreateConversationClick}
                    data-for="createConversationTooltip"
                    data-tip
                >
                    <i className="fas fa-plus header-icon" />
                </button>
                <ReactTooltip
                    id="createConversationTooltip"
                    place="bottom"
                    effect="solid"
                >
                    <span className="tooltip-content">Create a new convesation</span>
                </ReactTooltip>
            </>)}
            <button
                className={"header-button" + (visibleTab === "chat" ? " is-active" : "")}
                onClick={() => {
                    if (visibleTab === "chat") {
                        setVisibleTab("none");
                    } else {
                        setVisibleTab("chat");
                    }
                }}
                data-for="openChatTooltip"
                data-tip
            >
                <i className="fas fa-comment-dots header-icon" />
                {unreadChatCounter}
            </button>
            <ReactTooltip
                id="openChatTooltip"
                place="bottom"
                effect="solid"
            >
                <span className="tooltip-content">Toggle Chat</span>
            </ReactTooltip>
            <button
                className={"header-button" + (visibleTab === "invitations" ? " is-active" : "")}
                onClick={() => {
                    if (visibleTab === "invitations") {
                        setVisibleTab("none");
                    } else {
                        setVisibleTab("invitations");
                    }
                }}
                data-for="openInvitationTooltip"
                data-tip
            >
                <i className="fas fa-hand-point-right header-icon" />
                {unreadInvitationsCounter}
            </button>
            <ReactTooltip
                id="openInvitationTooltip"
                place="bottom"
                effect="solid"
            >
                <span className="tooltip-content">Toggle Invitations</span>
            </ReactTooltip>
        </div>
    );
}

export default HeaderButtons;
