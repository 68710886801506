import React from 'react';
//import twemoji from 'twemoji';
//import ReactHtmlParser from 'react-html-parser';
import Linkify from 'react-linkify';

import { Message, ChatUser } from '../../controllers/models';
//import { SvgAvatar } from '../../util/svgavatar';
//import Avatar from "../Avatar";
//import ParticipantAvatar from '../../../../ParticipantAvatar/ParticipantAvatar';
//import ToolTip from "../ToolTip";
//import ReplyCount from "../ReplyCount";
//import { MeetupContext } from '../../../../../controllers/MeetupController';

import './style.scss';
//import blueFile from './resources/file-blue.svg';
//import blueDoubleTick from './resources/blue-double-tick-icon.png';
//import greyDoubleTick from './resources/grey-double-tick-icon.png';
import greyTick from './resources/grey-tick-icon.png';


interface MessageBubbleProps {
    message: Message;
    sender: ChatUser,
    isSender: boolean;
    showHeader: boolean;
    visibilityObserver: IntersectionObserver;
}

const MessageBubble: React.FC<MessageBubbleProps> = (props) => {
    // TODO: avoid using the meetup context here and rather pass the
    // participant as the sender property of the message
    //const meetupContext = React.useContext(MeetupContext);
    //const participants = meetupContext.participants;
    
    const ticks = greyTick;

    //if (props.isSender) {
        //if (props.message.sentAt && !props.message.readAt && !props.message.deliveredAt){
            //ticks = greyTick;
        //} else if (props.message.sentAt && !props.message.readAt && props.message.deliveredAt){
            //ticks = greyDoubleTick
        //}
    //} else {
        //if (props.message.receiverType === 'group') {
            //if (!props.message.sender.avatar) {
                //const uid = props.message.sender.getUid();
                //const char = props.message.sender.getName().charAt(0).toUpperCase();
//
                //props.message.sender.setAvatar(SvgAvatar.getAvatar(uid, char));
            //} 
            //
            //avatar = (
                //<div className='chat-receiver-thumbnail-wrap'>
                    //<Avatar 
                        //cornerRadius="50%" 
                        //borderColor="#CCC" 
                        //borderWidth="1px"
                        //image={props.message.sender.avatar}>
                    //</Avatar>
                //</div>
            //);
            //const senderId = parseInt(props.message.sender.uid);
            //const participant = participants.get(senderId.toString());
            //if (participant) {
                //avatar = (
                    //<div className='chat-receiver-avatar-wrap'>
                        //<ParticipantAvatar
                            //participant={participant}
                        ///>
                    //</div>
                //);
            //}
        //}
    //}

    //const message = Object.assign({}, props.message, {messageFrom: "receiver"});
    const { message, sender, isSender, showHeader } = props;

    //let replies = null, tooltip = null;
    //if ((!props.widgetconfig && props.message.replyCount)
            //|| (props.widgetconfig && props.widgetconfig["threaded-chats"]
            //&& props.message.replyCount)) {
        //replies = (
            //<ReplyCount
                //message={message}
                //action="viewMessageThread"
                //actionGenerated={props.actionGenerated}
            ///>
        //);
    //}

    //if ((!props.widgetconfig) || (props.widgetconfig && props.widgetconfig["threaded-chats"])) {
        //tooltip = (
            //<ToolTip
                //message={message}
                //action="viewMessageThread"
                //actionGenerated={props.actionGenerated}
            ///>
        //);
    //}
    //

    // TODO: use fallback avatar icons
    const avatarIcon = !isSender && showHeader && (
        <img
            className='avatar-icon'
            src={sender.avatarUrl} alt="avatar url"
        />
    );

    //'en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const messageHeader = !isSender && showHeader && (
        <div className='message-header'>
            {<span className='sender-name'>{sender.name}</span>}
        </div>
    );

    const timestamp = new Date(message.sentAt).toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    });
    const messageFooter = (
        <div className='message-footer'>
            <span className='send-time'>{timestamp}</span>
            {isSender && <img className='message-status-indicator' src={ticks} alt="time"/>}
        </div>
    );

    let messageContent: React.ReactElement | undefined;
    if (message.content) {
        switch (message.type) {
            case 'text':
                //const emojiParsedMessage = twemoji.parse(message.content, {folder: 'svg',  ext: '.svg'});
                //const parsedMessage = ReactHtmlParser(emojiParsedMessage);
                //const emojiMessage = parsedMessage.filter(message => (message instanceof Object && message.type === 'img'));
                //const messageClassName = classNames({
                    //'chat-txt-msg': true,
                    //'size1x': (parsedMessage.length === emojiMessage.length && emojiMessage.length === 1),
                    //'size2x': (parsedMessage.length === emojiMessage.length && emojiMessage.length === 2),
                    //'size3x': (parsedMessage.length === emojiMessage.length && emojiMessage.length > 2)
                //});

                messageContent = (
                    <div className='message-text'>
                        <Linkify
                            componentDecorator={(href, text) => (
                                <a
                                    href={href}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                >
                                    {text}
                                </a>
                            )}
                        >
                            {message.content}
                        </Linkify>
                    </div>
                );
                break;

            //case 'image':
                //messageContent = (
                    //<div className='chat-img-wrap'>
                        //<img src={props.message.content} alt="receiver" />
                    //</div>
                //);
                //break;
//
            //case 'audio':
                //messageContent = (
                    //<div className='chat-audio-wrap'>
                        //<audio controls>
                            //<source src={props.message.content} />
                        //</audio>
                    //</div>
                //);
                //break;
//
            //case 'video':
                //messageContent = (
                    //<div className='chat-video-wrap'>
                        //<video controls>
                        //<source src={props.message.content} />
                        //</video>                        
                    //</div>
                //);
                //break;
//
            //case 'file':
                //// TODO: dispaly a file name
                //messageContent = (
                    //<div className='chat-file-wrap'>
                        //<a href={props.message.content} target="_blank" rel="noopener noreferrer">{props.message.content}
                            //<img src={blueFile} alt="file"/>
                        //</a>
                    //</div>
                //);
                //break;

            default:
                messageContent = (
                    <p>Unmatched message format</p>
                );
                break;
        }
    }

    //let chatRowClass, messageBody, messageFooter;
    //if (props.isSender) {
        //chatRowClass = 'chat-row-sender';
        //messageBody = (
            //<div className='chat-sender-msg-wrap'>
                //{tooltip}
                //{messageContent}
            //</div>
        //);
        //messageFooter = (
            //<div className='chat-msg-time-wrap'>
                //{replies}
                //{timestamp}
            //</div>
        //);
    //} else {
        //chatRowClass = 'chat-row-receiver';
        //messageBody = (
            //<>
                //<div className='chat-receiver-detail'>
                    //{avatar}
                //</div>
                //<div className='chat-receiver-msg-wrap'>
                    //{tooltip}
                    //{messageContent}
                //</div>
            //</>
        //);
        //messageFooter = (
            //<div className='chat-msg-time-wrap'>
                //{timestamp}
                //{replies}
            //</div>
        //);
    //}
    
    const [rowRef, setRowRef] = React.useState<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (rowRef) {
            props.visibilityObserver.observe(rowRef);
        }
        return () => {
            if (rowRef) {
                props.visibilityObserver.unobserve(rowRef);
            }
        };
    }, [props.visibilityObserver, rowRef]);

    const spacing = showHeader ? 'new-sender-row' : 'repeated-sender-row';
    const senderReceiverClass = isSender ? 'message-sender' : 'message-receiver';
    const offsetCompensation = (!isSender && !avatarIcon)
        ? 'avatar-compensation' : '';
    return (
        <div
            id={message.id.toString()}
            className={`message-row ${spacing}`}
            ref={setRowRef}
        >
            {avatarIcon}
            <div className={`message-bubble ${senderReceiverClass} ${offsetCompensation}`}>
                {messageHeader}
                <div className='message-body'>
                    {messageContent}
                    {messageFooter}
                </div>
            </div>
        </div>
    );
}

export default MessageBubble;
