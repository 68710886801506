import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { UID, SID } from '../../../models';
import { ChatUser } from './models';


const USERS_QUERY = gql`
    query GetChannels($chatspaceId: uuid!) {
        users: chat_user(
            where: {_and: [
                {chatspace_id: {_eq: $chatspaceId}},
            ]}
        ) {
            id
            name
            avatarUrl: avatar_url
        }
    }
`;

interface ChannelQueryResult {
    users: ChatUser[];
}

interface ChannelQueryVariables {
    chatspaceId: UID;
}

interface UserResult {
    users: Map<SID, ChatUser>;
    refreshUsers: (...userIds: SID[]) => void;
}

export function useChatUsers(chatspaceId: string): UserResult {
    const {
        data,
        refetch,
    } = useQuery<ChannelQueryResult, ChannelQueryVariables>(
        USERS_QUERY,
        {variables: {chatspaceId: chatspaceId}}
    );

    const users = React.useMemo<Map<SID, ChatUser>>(() => {
        if (!data)
            return new Map();

        return new Map(data.users.map(user => [user.id, user]));
    }, [data]);

    const refreshUsers = React.useCallback((...userIds: SID[]) => {
        refetch();
    }, [refetch]);

    const userResult = React.useMemo<UserResult>(() => ({
        users: users,
        refreshUsers: refreshUsers
    }), [users, refreshUsers]);

    return userResult;
}
