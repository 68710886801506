import React from 'react';
import { toast } from 'react-toastify';

import {
    MediaType,
    LocalMediaTrack,
    UserMediaState,
    hasMedia,
    isError,
    MediaTrack,
} from '../../VideoChatRoom/controllers/models';
import { useVolumeMeter } from '../../VideoChatRoom/controllers/VideoCallUtils';


// TODO: make it similar to Agora's "music_standard" profile
const AUDIO_SPECS = {
    //sampleRate: 48,
    //sampleSize: 24,
    channelCount: 1,
    //echoCancellation: true,
    //noiseSuppression: true,
};

const VIDEO_SPECS = {
    //width: 640,
    //height: 480,
    width: 848,
    height: 480,
    frameRate: 15,//24
}

const SCREEN_SHARE_SPECS = {
    width: 1280,
    height: 720,
    frameRate: 15,
}

interface UserMediaProps {
    video?: {
        width: number;
        height: number;
        frameRate: number;
    };
}

export enum UserMediaActionType {
    SET_AUDIO,
    SET_VIDEO,
    SET_SCREEN_SHARE,
    TOGGLE_AUDIO,
    TOGGLE_VIDEO,
    TOGGLE_SCREEN_SHARE,
}

interface UserMediaAction {
    actionType: UserMediaActionType;
    enabled?: boolean;
}

export type UserMediaDispatcher = (action: UserMediaAction) => void;

export function useUserMediaState(props?: UserMediaProps): [UserMediaState, UserMediaDispatcher] {
    const [userMediaState, setUserMediaState] = React.useState<UserMediaState>({});

    const [useAudio, setUseAudio] = React.useState(false);
    const [useVideo, setUseVideo] = React.useState(false);
    const [useScreen, setUseScreen] = React.useState(false);

    const audio = useUserMediaTrack("audio", useAudio, props);
    const video = useUserMediaTrack("video", useVideo, props);
    const screen = useUserMediaTrack("screen", useScreen, props);

    const audioOn = useAudio && hasMedia(audio);
    useMediaStateUpdate("audio", audio, audioOn, setUserMediaState);

    useMediaNotifications(video, useVideo, "video");
    const videoOn = useVideo && !hasMedia(screen) && hasMedia(video);
    useMediaStateUpdate("video", video, videoOn, setUserMediaState);

    useMediaNotifications(screen, useScreen, "screen");
    const sharingScreen = useScreen && hasMedia(screen);
    useMediaStateUpdate("screen", screen, sharingScreen, setUserMediaState);

    //const userMediaStateRef = React.useRef(userMediaState);
    //React.useEffect(() => {
        //userMediaStateRef.current = userMediaState;
    //}, [userMediaState]);
    const userMediaDispatcher = React.useCallback((action: UserMediaAction) => {
        switch (action.actionType) {
            case UserMediaActionType.SET_AUDIO:
                setUseAudio(action.enabled!);
                break;
            case UserMediaActionType.SET_VIDEO:
                if (action.enabled) setUseScreen(false);
                setUseVideo(action.enabled!);
                break;
            case UserMediaActionType.SET_SCREEN_SHARE:
                setUseScreen(action.enabled!);
                break;
            //case UserMediaActionType.TOGGLE_AUDIO:
                //setUseAudio(userMediaStateRef.current.audio === undefined);
                //break;
            //case UserMediaActionType.TOGGLE_VIDEO:
                //setUseVideo(userMediaStateRef.current.video === undefined);
                //break;
            //case UserMediaActionType.TOGGLE_SCREEN_SHARE:
                //setUseScreen(userMediaStateRef.current.screen === undefined);
                //break;
            case UserMediaActionType.TOGGLE_AUDIO:
                setUseAudio(useAudio => !useAudio);
                break;
            case UserMediaActionType.TOGGLE_VIDEO:
                setUseVideo(useVideo => {
                    if (!useVideo) {
                        setUseScreen(false);
                    }
                    return !useVideo;
                });
                break;
            case UserMediaActionType.TOGGLE_SCREEN_SHARE:
                setUseScreen(useScreen => !useScreen);
                break;
        }
    }, []);

    return [userMediaState, userMediaDispatcher];
}

interface TrackState<T extends MediaTrack> {
    track?: LocalMediaTrack<T>;
    requestPending: boolean;
}

function useUserMediaTrack<T extends MediaTrack>(
    mediaType: MediaType,
    useMedia: boolean,
    mediaProps?: UserMediaProps,
): LocalMediaTrack<T> | undefined {
    const [trackState, setTrackState] = React.useState<TrackState<T>>({
        requestPending: false,
    });

    const volumeMonitorCreator = useVolumeMeter();

    function setMediaError() {
        // TODO: make sure it actually is a permission error
        // check for media capabilities before
        setTrackState({
            track: "NOT_ALLOWED",
            requestPending: false
        });
    }

    if (useMedia && !trackState.track && !isError(trackState.track) && !trackState.requestPending) {
        setTrackState({ ...trackState, requestPending: true });

        switch (mediaType) {
            case "audio":
                navigator.mediaDevices.getUserMedia({
                    audio: AUDIO_SPECS,
                }).then(
                    stream => {
                        const audioTrack = stream.getAudioTracks()[0];
                        //audioTrack.enabled = false;
                        const volumeMonitor = volumeMonitorCreator(audioTrack);

                        setTrackState({
                            track: {
                                mediaStreamTrack: audioTrack,
                                // TODO: revisit this and add a volume meter
                                volume: volumeMonitor,
                            } as T,
                            requestPending: false
                        });
                    },
                    error => {
                        console.log("Error getting audio stream:", error);
                        setMediaError();
                    }
                );
                break;
            case "video":
                navigator.mediaDevices.getUserMedia({
                    video: mediaProps?.video ?? VIDEO_SPECS,
                }).then(
                    stream => {
                        const videoTrack = stream.getVideoTracks()[0];
                        //videoTrack.enabled = false;
                        setTrackState({
                            track: {
                                mediaStreamTrack: videoTrack,
                                isScreen: false,
                            } as T,
                            requestPending: false
                        });
                    },
                    error => {
                        console.log("Error getting video stream:", error);
                        setMediaError();
                    }
                );
                break;
            case "screen":
                (navigator.mediaDevices as any).getDisplayMedia({
                    video: SCREEN_SHARE_SPECS,
                }).then(
                    (stream: MediaStream) => {
                        const screenTrack = stream.getVideoTracks()[0];
                        //screenTrack.enabled = false;
                        setTrackState({
                            track: {
                                mediaStreamTrack: screenTrack,
                                isScreen: true,
                            } as T,
                            requestPending: false
                        });
                    },
                    (error: MediaError) => {
                        console.log("Error getting screen stream:", error);
                        setMediaError();
                    }
                );
                break;
        }
    } else if (
        mediaType === "screen"
        && !useMedia
        && trackState.track
        && !isError(trackState.track)
        && !trackState.requestPending
    ) {
        // Stop the screen track if it is no longer requested. Otherwise the
        // same screen selection will be shared again next time without the
        // option to select another one.
        trackState.track.mediaStreamTrack.stop();
        setTrackState({
            track: undefined,
            requestPending: false,
        })
    }

    // Stop requesting media on unmount
    const trackRef = React.useRef(trackState.track);
    React.useEffect(() => {
        trackRef.current = trackState.track;
    }, [trackState.track]);
    React.useEffect(() => {
        return () => {
            if (hasMedia(trackRef.current)) {
                trackRef.current.mediaStreamTrack.stop();
            }
        }
    }, []);

    return trackState.track;
}


function useMediaNotifications(mediaTrack: LocalMediaTrack<any> | undefined, useMedia: boolean, mediaType: MediaType) {
    React.useEffect(() => {
        if (useMedia && mediaTrack !== undefined) {
            if (mediaTrack === "NOT_ALLOWED") {
                toast.warn(`Permission to access ${mediaType} not granted.\nTo share video, please enable video permissions for this website and reload.`);
            } else if (mediaTrack === "NOT_SUPPORTED") {
                toast.warn(`${mediaType} sharing not supported.`);
            }
        }
    }, [mediaTrack, useMedia, mediaType]);
}


function useMediaStateUpdate(
    mediaType: MediaType,
    mediaTrack: LocalMediaTrack<any> | undefined,
    mediaOn: boolean,
    setUserMediaState: (callback: ((userMediaState: UserMediaState) => UserMediaState)) => void
) {
    React.useEffect(() => {
        if (hasMedia(mediaTrack))
            mediaTrack.mediaStreamTrack.enabled = mediaOn;
        const mediaStateTrack = mediaOn ? mediaTrack : undefined;
        setUserMediaState(userMediaState => ({
            ...userMediaState,
            [mediaType]: mediaStateTrack,
        }));
    }, [mediaTrack, mediaOn, mediaType, setUserMediaState]);
}


export function useConversationMedia(): [UserMediaState, UserMediaDispatcher] {
    const [userMediaState, userMediaDispatcher] = React.useContext(UserMediaContext);

    React.useEffect(() => {
        userMediaDispatcher({
            actionType: UserMediaActionType.SET_AUDIO,
            enabled: true,
        });
        userMediaDispatcher({
            actionType: UserMediaActionType.SET_VIDEO,
            enabled: true,
        });

        return () => {
            userMediaDispatcher({
                actionType: UserMediaActionType.SET_AUDIO,
                enabled: false,
            });
            userMediaDispatcher({
                actionType: UserMediaActionType.SET_VIDEO,
                enabled: false,
            });
            userMediaDispatcher({
                actionType: UserMediaActionType.SET_SCREEN_SHARE,
                enabled: false,
            });
        };
    }, [userMediaDispatcher]);

    return [userMediaState, userMediaDispatcher];
}


export function useStreamFromMediaState(mediaState: UserMediaState): MediaStream {
    const [peerStream, setPeerStream] = React.useState(new MediaStream());
    React.useEffect(() => {
        const tracks: MediaStreamTrack[] = [];
        if (hasMedia(mediaState.audio)) {
            tracks.push(mediaState.audio.mediaStreamTrack);
        }
        if (hasMedia(mediaState.screen)) {
            tracks.push(mediaState.screen.mediaStreamTrack);
        } else if (hasMedia(mediaState.video)) {
            tracks.push(mediaState.video.mediaStreamTrack);
        }
        setPeerStream(new MediaStream(tracks));

    }, [mediaState]);

    return peerStream;
}


export const UserMediaContext = React.createContext<[UserMediaState, UserMediaDispatcher]>([{}, () => { /* Placeholder */ }]);
UserMediaContext.displayName = 'UserMediaContext';
