import React from 'react';

import StartFullscreenIcon from 'material-icons-svg/components/outline/Fullscreen';
import StopFullscreenIcon from 'material-icons-svg/components/outline/FullscreenExit';
import CloseIcon from 'material-icons-svg/components/baseline/Close';

import { useConversationNavigation } from '../../../MeetupView/controllers/ConversationController';
import ControlButton from '../ControlButton/ControlButton';
import './style.scss';


interface FooterControlsProps {
    onClose: () => void;
    isFullscreen: boolean;
    toggleFullscreen: () => void;
}

const FooterControls: React.FC<FooterControlsProps> = (props) => {
    const [, navigateToConversation] = useConversationNavigation();

    return (
        <div className='video-room-controls-container' >
            <ControlButton
                onClick={props.toggleFullscreen}
                icon={props.isFullscreen ? StopFullscreenIcon : StartFullscreenIcon}
            />
            <ControlButton
                onClick={() => {
                    props.onClose()
                    navigateToConversation(null);
                }}
                icon={CloseIcon}
                classes={'termination-button'}
            />
        </div>
    );
}

export default FooterControls;
