import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    isMobile,
    isChrome,
    isChromium,
    isFirefox,
    isOpera,
    isSafari,
    isIOS,
    browserVersion,
} from 'react-device-detect';
import AgoraRTC from 'agora-rtc-sdk-ng';

//import { ID } from '../../models';
import { CheckinUser } from '../../controllers/UserController';
import { MeetupInfo } from '../../controllers/MeetupController';
import { verifyPassword } from '../../controllers/BackendConnection';
import { PasswordInputView } from './components/PasswordInputView/PasswordInputView';
import { CustomizationView } from './components/CustomizationView/CustomizationView';
//import { CheckinForm } from './components/CheckinForm/CheckinForm';
//import { MessageView, MessageType } from '../MessageView/MessageView';
//import osmoteLogo from '../MeetupView/images/logo_osmote_v1.png';
import osmoteLogo from '../MeetupView/images/logo_bright.png';
import './style.scss';

//import Twigs from './resources/christmas_elements/christmas_1.png';
////import Bells from './resources/christmas_elements/christmas_2.png';
//import Tree from './resources/christmas_elements/christmas_3.png';
//import Twigs2 from './resources/christmas_elements/christmas_4.png';


interface CheckinViewProps {
    meetupStub: MeetupInfo;
    checkinUser: (userData: CheckinUser) => void;
}

export function CheckinView(props: CheckinViewProps) {
    const [password, setPassword] = React.useState("");
    const [passwordSuccessful, setPasswordSuccessful] = React.useState(!props.meetupStub.hasPassword);

    const [compatibilityWarningEnabled, setCompatibilityWarningEnabled]
        = React.useState(true);
    const compatibilityWarning = useCompatibilityWarning(compatibilityWarningEnabled);

    function submitPassword(password: string) {
        //toast.info("Verifying password...");

        verifyPassword(props.meetupStub.id, password).then(
            result => {
                if (result) {
                    setPassword(password);
                    setPasswordSuccessful(true);
                    setCompatibilityWarningEnabled(false);
                    toast.success("Password correct!");
                } else {
                    toast.error("Password incorrect, please try again.");
                }
            },
            error => {
                toast.error("Couldn't verify password, please try again.");
            },
        );
    }

    function submitCheckin(username: string, avatarImage: string, isSelfie: boolean) {
        toast.info("Checking in...");
        props.checkinUser({
            password: password,
            name: username,
            avatarImage: avatarImage,
            isSelfie: isSelfie,
        });
    }

    const startTime = Date.parse(props.meetupStub.startTime);
    const startDate = new Date(startTime)
    const now = Date.now();

    let checkingForm;
    if (startTime > now) {
        checkingForm = (
            <h3 className='start-notice'>
                The meetup has not started yet.<br/>
                It will begin on {startDate.toLocaleString("en-GB")}.
            </h3>
        );
    } else if (!passwordSuccessful || compatibilityWarning) {
        checkingForm = (
            <>
                {compatibilityWarning}
                {passwordSuccessful 
                    ? <button
                        className='submit-button'
                        onClick={() => setCompatibilityWarningEnabled(false)}
                    >
                        Next
                    </button>
                    : <PasswordInputView
                        key={'password'}
                        passwordRequired={props.meetupStub.hasPassword}
                        submit={submitPassword}
                    />
                }
            </>
        );
    } else {
        checkingForm = (
            <CustomizationView
                key={'name'}
                submit={submitCheckin}
            />
        );
    }

    return (
        <div className='checkin-container' >
            <div className='checkin-view' >
                <div className='logo'>
                    <ToastContainer
                        position='top-center'
                        autoClose={5000}
                    />
                    <img
                        className='logo-image'
                        src={osmoteLogo}
                        alt={"Osmote logo"}
                    />
                    {/*<p className="logo-subtitle">All you can meet</p>*/}
                    {/*
                    <div className='decoration-elements'>
                        <img className='image-element' src={Twigs2} alt={"Christmas twigs"}/>
                        <img className='image-element' src={Twigs} alt={"Christmas twigs"}/>
                        <img className='image-element' src={Tree} alt={"Christmas tree"}/>
                    </div>
                    */}
                </div>
                <h1 className='meetup-title'>{props.meetupStub.name}</h1>
                {checkingForm}
            </div>
        </div>
    );
}


function useCompatibilityWarning(enabled: boolean): React.ReactElement | null {
    const systemSupportsVideoCalls = React.useMemo(() => AgoraRTC.checkSystemRequirements(), []);
    const [isFullyCompatible, setFullyCompatible] = React.useState(false);

    let compatibilityWarning = null;
    if (!enabled || isFullyCompatible) {
        compatibilityWarning = null;
    } else if (!systemSupportsVideoCalls || (isSafari && Number(browserVersion) <= 12)) {
        compatibilityWarning = <>
            <h3>There is a problem...</h3>
            <p>
                It looks like <i>your browser does not support video calling</i>.
                Please use an up-to-date version of Chrome or Firefox on a desktop computer and make sure you have audio and video recording enabled.
            </p>
        </>
    } else if (!(isFirefox || isChrome || isChromium || isOpera || (isSafari && isIOS))) {
        compatibilityWarning = <>
            <h3>This might not work in your browser...</h3>
            <p>
                For the best experience, please consider using an <i>up-to-date version of Chrome or Firefox on a desktop computer</i>.
            </p>
        </>
    } else if (isMobile) {
        compatibilityWarning = <>
            <h3>Just in case...</h3>
            <p>
                It looks like you are using a <i>mobile device</i>. If you experience issues during video calls, please consider using an <i>up-to-date version of Chrome or Firefox on a desktop computer</i>.
            </p>
        </>
    } else {
        setFullyCompatible(true);
        compatibilityWarning = null;
    }

    if (compatibilityWarning !== null) {
        return (
            <div className='compatibility-notice'>
                {compatibilityWarning}
            </div>
        );
    } else {
        return null;
    }
}
