import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { UID, SID } from '../../../models';


const READ_STATUS_QUERY = gql`
    query GetReadStatus($chatspaceId: uuid!) {
        channelReadStates: chat_channel(
            where: {_and: [
                {chatspace_id: {_eq: $chatspaceId}},
            ]}
        ) {
            channelId: id
            lastReadMessageIds: read_statuses {
                lastReadMessageId: last_read_message_id
            }
        }
    }
`;

interface ReadStatusQueryResult {
    channelReadStates: {
        channelId: SID;
        lastReadMessageIds: {lastReadMessageId: SID}[];
    }[];
}

interface ReadStatusQueryVariables {
    chatspaceId: UID;
}


const UPDATE_READ_STATUS_MUTATION = gql`
    mutation UpdateReadStatus(
        $chatspaceId: uuid!,
        $userId: Int!,
        $channelId: Int!,
        $lastReadMessageId: Int!,
    ) {
        update_chat_read_status_by_pk(
            pk_columns: {
                chatspace_id: $chatspaceId,
                user_id: $userId,
                channel_id: $channelId,
            },
            _set: {last_read_message_id: $lastReadMessageId},
        ) {
            lastReadMessageId: last_read_message_id
        }
    }
`;

interface ReadStatusUpdateResult {
    lastReadMessageId: SID;
}

interface ReadStatusUpdateArguments{
    chatspaceId: UID;
    userId: SID,
    channelId: SID;
    lastReadMessageId: SID;
}


type ReadStatusUpdater = (channelId: SID, lastReadMessageId: SID) => void;

interface ReadStates {
    lastReadIds: Map<SID, SID>;
    setLastReadId: ReadStatusUpdater;
    refreshReadStates: (...channelIds: SID[]) => void;
}


export function useChannelReadStates(
    chatspaceId: string,
    userId: number,
): ReadStates {
    const [readStates, setReadStates] = React.useState<Map<SID, SID>>(new Map());

    const updateLocalReadStatus = React.useCallback((channelId: SID, lastReadMessageId: SID) => {
        setReadStates(readStates => {
            const channelReadLastReadMessageId = readStates.get(channelId);
            if (!channelReadLastReadMessageId || lastReadMessageId > channelReadLastReadMessageId) {
                const newReadStates = new Map(readStates);
                newReadStates.set(channelId, lastReadMessageId);
                return newReadStates;
            } else {
                return readStates;
            }
        });
    }, []);

    const onReadStatesLoaded = React.useCallback((data?: ReadStatusQueryResult) => {
        if (!data)
            return;

        data.channelReadStates.forEach(readStatus => {
            const lastReadMessageId = readStatus.lastReadMessageIds[0]?.lastReadMessageId ?? 0;
            updateLocalReadStatus(readStatus.channelId, lastReadMessageId);
        });
    }, [updateLocalReadStatus]);
    
    const { error, refetch } = useQuery<ReadStatusQueryResult, ReadStatusQueryVariables>(
        READ_STATUS_QUERY,
        {
            variables: {chatspaceId: chatspaceId},
            onCompleted: onReadStatesLoaded,
        },
    );


    const [updateRemoteReadStatus] = useMutation<ReadStatusUpdateResult, ReadStatusUpdateArguments>(UPDATE_READ_STATUS_MUTATION);

    const readStatusResult = React.useMemo<ReadStates>(() => ({
        lastReadIds: readStates,
        setLastReadId: (channelId: SID, lastReadMessageId: SID) => {
            updateRemoteReadStatus({variables: {
                chatspaceId: chatspaceId,
                userId: userId,
                channelId: channelId,
                lastReadMessageId: lastReadMessageId,
            }});
            updateLocalReadStatus(channelId, lastReadMessageId);
        },
        // TODO: use channelId
        refreshReadStates: (...channelIds: SID[]) => {refetch()},
    }), [
        chatspaceId,
        userId,
        readStates,
        updateRemoteReadStatus,
        updateLocalReadStatus,
        refetch,
    ]);

    return readStatusResult;
}
