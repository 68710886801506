import React from 'react';

import { UID, SID } from '../../../models';
import { Message, ChannelMessageState, MessageSenderCallback } from './models';
import { usePreviousMessages, useNewMessages } from './MessageReceiverController';
import { useChannelReadStates } from './ReadStatusController';
import { useMessageSender } from './SenderController';


export function useMessages(
    chatspaceId: UID,
    chatUserId: SID,
    selectedChannelId?: SID,
    onNewMessages?: (messages: Message[]) => void,
): Map<SID, ChannelMessageState> {
    const [initTime, ] = React.useState(new Date());
    const [messages, setMessages]
        = React.useState<Map<SID, Message[]>>(new Map());

    // TODO: trigger channel and user updates if necessary
    const updateMessages = React.useCallback((newMessages: Message[], channelId?: SID) => {
        // Sort the new messages into channels
        const newMessagesPerChannel = getPerChannelMessages(newMessages);
        setMessages(messages => {
            const newMessages = mergeMessages(messages, newMessagesPerChannel);

            if (channelId && !newMessages.has(channelId)) {
                // Loading previous messages for a channel has completed but
                // there are no messages. If this wasn't set the channel
                // would be shown as loading.
                newMessages.set(channelId, []);
            }
            return newMessages;
        });

        if (onNewMessages !== undefined) onNewMessages(newMessages);
    }, [onNewMessages]);
    const updateMessagesRef = React.useRef(updateMessages);
    React.useEffect(() => {
        updateMessagesRef.current = updateMessages;
    }, [updateMessages]);

    usePreviousMessages(chatspaceId, selectedChannelId, initTime, updateMessagesRef);
    useNewMessages(chatspaceId, initTime, updateMessagesRef);


    // Recompute the number of unread messages per channel when either
    // new messages arrive or the channel read status changes
    const readStates = useChannelReadStates(chatspaceId, chatUserId);
    const [unreadMessageCounts, setUnreadMessageCounts] = React.useState<Map<SID, number>>(new Map());
    React.useEffect(() => {
        const unreadMessageCounts = new Map();

        for (const [channelId, channelMessages] of messages.entries()) {
            const channelLastReadId = readStates.lastReadIds.get(channelId);
            if (!channelLastReadId)
                continue;

            let channelUnreadCount = 0;
            // Iterate from back to front, because unread messages will be
            // found at the back. This way we can do early stopping.
            for (let i = channelMessages.length - 1; i >= 0; i--) {
                const message = channelMessages[i];
                if (message.id > channelLastReadId) {
                    if (message.senderId !== chatUserId) {
                        // Only count messages sent by others as unread
                        channelUnreadCount++;
                    }
                } else {
                    // The messages are sorted by id, so as soon as one
                    // has an id that is not larger than the last read
                    // message id, we can stop.
                    break;
                }
            }
            unreadMessageCounts.set(channelId, channelUnreadCount);
        }
        setUnreadMessageCounts(unreadMessageCounts);
    }, [chatUserId, messages, readStates.lastReadIds]);


    const loadPreviousMessages = React.useCallback((channelId: SID) => {
        // TODO: implement
    }, []);

    const sendMessageCallback = useMessageSender(chatspaceId, chatUserId);
    const sendMessage: MessageSenderCallback = React.useCallback((
        channelId, messageType, messageContent
    ) => {
        sendMessageCallback(channelId, messageType, messageContent);
        // TODO: update read status and add the message to the list
    },[sendMessageCallback]);

    const [messageStates, setMessageStates] = React.useState<Map<SID, ChannelMessageState>>(new Map());
    React.useEffect(() => {
        setMessageStates(messageStates => {
            const newMessageStates: Map<SID, ChannelMessageState> = new Map();

            for (const [channelId, channelMessages] of messages.entries()) {
                const prevState = messageStates.get(channelId);
                const channelLastReadId = readStates.lastReadIds.get(channelId);
                const channelUnreadCount = unreadMessageCounts.get(channelId);

                if (
                    !prevState
                    || prevState.messages !== channelMessages
                    || prevState.lastReadId !== channelLastReadId
                    || prevState.numUnread !== channelUnreadCount
                    || prevState.send !== sendMessage
                ) {
                    newMessageStates.set(channelId, {
                        messages: channelMessages,
                        loadPrevious: () => loadPreviousMessages(channelId),
                        send: sendMessage,
                        lastReadId: channelLastReadId,
                        setLastRead: (messageId: SID) => readStates.setLastReadId(channelId, messageId),
                        numUnread: channelUnreadCount,
                    });
                } else {
                    newMessageStates.set(channelId, prevState);
                }
            }

            return newMessageStates;
        });
    }, [
        messages,
        loadPreviousMessages,
        sendMessage,
        readStates,
        unreadMessageCounts,
    ]);

    return messageStates;
}

/*
 * Sort messages into channels
 */
function getPerChannelMessages(messages: Message[]): Map<SID, Message[]> {
    const messagesPerChannel = new Map<SID, Message[]>();

    messages.forEach(message => {
        const channelMessages = messagesPerChannel.get(message.channelId);
        if (channelMessages) {
            if (channelMessages.length > 0) {
                console.assert(
                    message.id > channelMessages[channelMessages.length - 1].id,
                    "Channel messages are not properly sorted."
                );
            }
            channelMessages.push(message);
        } else {
            messagesPerChannel.set(message.channelId, [message]);
        }
    });
    return messagesPerChannel;
}

function mergeMessages(existingMessages: Map<SID, Message[]>, newMessages: Map<SID, Message[]>): Map<SID, Message[]> {
    const messages = new Map<SID, Message[]>();

    for (const [channelId, newChannelMessages] of newMessages.entries()) {
        const existingChannelMessages = existingMessages.get(channelId);
        messages.set(channelId, mergeChannelMessages(
            existingChannelMessages, newChannelMessages,
        ));
    }

    // Add channels that didn't receive new messages
    for (const [channelId, existingChannelMessages] of existingMessages.entries()) {
        if (!messages.has(channelId)) {
            messages.set(channelId, existingChannelMessages);
        }
    }

    return messages;
}

/* 
 * Assumes that the input arrays are sorted by message id
 */
function mergeChannelMessages(existingMessages?: Message[], newMessages?: Message[]): Message[] {
    const nExistingMessages = existingMessages?.length ?? 0;
    const nNewMessages = newMessages?.length ?? 0;

    if (!existingMessages || nExistingMessages === 0) {
        return newMessages ?? [];
    } else if (!newMessages || nNewMessages === 0) {
        return existingMessages;
    } else if (existingMessages[nExistingMessages - 1].id < newMessages[0].id) {
        // All new messages have larger ids than the existing ones,
        // append
        return existingMessages.concat(newMessages);
    } else if (newMessages[nNewMessages - 1].id < existingMessages[0].id) {
        // All new messages have smaller ids than the existing ones,
        // prepend
        return newMessages.concat(existingMessages);
    } else {
        console.error("Heterogeneous message ids");
        return [];
    }
}
