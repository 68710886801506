import React from 'react';
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    split,
    HttpLink
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws/';

import { getChatToken } from '../../../controllers/BackendConnection';


export const WithChatClient: React.FC = (props) => {
    // TODO: specify the cache type
    const [chatClient, setChatClient] = React.useState<ApolloClient<any>>();

    // TODO: retry if this fails
    React.useEffect(() => {
        getChatToken().then(token => {
            const httpLink = new HttpLink({
                uri: process.env.REACT_APP_CHAT_HTTP_BACKEND_URL!,
            });

            const wsLink = new WebSocketLink({
                uri: process.env.REACT_APP_CHAT_WS_BACKEND_URL!,
                options: {
                    reconnect: true,
                    connectionParams: {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },
                },
            });

            const authLink = setContext((_, { headers }) => {
                return {
                    headers: {
                        ...headers,
                        authorization: `Bearer ${token}`,
                    }
                };
            });

            const splitLink = split(
                ({ query }) => {
                    const definition = getMainDefinition(query);
                    return (
                        definition.kind === 'OperationDefinition'
                        && definition.operation === 'subscription'
                    );
                },
                wsLink,
                authLink.concat(httpLink),
            );

            const client =new ApolloClient({
                cache: new InMemoryCache(),
                link: splitLink,
            });
            setChatClient(client);
        });
    }, []);

    if (chatClient !== undefined) {
        return (
            <ApolloProvider client={chatClient}>
                {props.children}
            </ApolloProvider>
        );
    } else {
        return (
            <p>Loading...</p>
        );
    }
};
