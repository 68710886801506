import React from 'react';
import { CSSTransition } from 'react-transition-group';
//import MessageListView from './chat_ui/components/MessageListView/MessageListView';

import MessageView from './components/MessageView/MessageView';
import ChannelList from './components/ChannelList/ChannelList';
import { ChatContext } from './controllers/ChatController';
import './style.scss';


const ChatView: React.FC = () => {
    const chatState = React.useContext(ChatContext);
    const channelSelector = chatState.onSelectChannel;
    React.useEffect(() => {
        return () => channelSelector(undefined);
    }, [channelSelector]);

    return (
        <div className='chat-view'>
            <ChannelList />
            <CSSTransition
                in={chatState.channelCategories.selectedChannelId !== undefined}
                appear
                timeout={250}
                mountOnEnter
                // TODO: this is here to prevent the autofocus of the composer
                // from messing up the animation when the component is already
                // mounted. Is there a better way to achieve this?
                unmountOnExit
                classNames='transition-slide'
                //classNames='message-view-container'
            >
                <div className='message-view-container'>
                    <MessageView />
                </div>
            </CSSTransition>
        </div>
    );
}

export default ChatView;
