import React from 'react';
import { useForm } from "react-hook-form";

import SelfieView from '../SelfieView/SelfieView';
import Grid from '../../../Grid/Grid';
import AvatarTemplates from '../../avatarTemplates.json';
import './style.scss';
import ParticipantAvatar from '../../../MingleArea/components/ParticipantAvatar/ParticipantAvatar';


interface CustomizationViewProps {
    submit: (name: string, avatarImage: string, isSelfie: boolean) => void;
}

interface FormValues {
    name: string;
}

type LooksPopupState = "hidden" | "avatar" | "selfie";

export const CustomizationView: React.FC<CustomizationViewProps> = (props => {
    const {
        register, handleSubmit, setValue, watch, formState: { errors }
    } = useForm<FormValues>();

    const [avatarUrl, setAvatarUrl] = React.useState<string>();
    const [isSelfie, setSelfie] = React.useState(false);
    const [isAvatarUrlMissing, setAvatarUrlMissing] = React.useState(false);

    const [looksPopupState, setLooksPopupState] = React.useState<LooksPopupState>(
        "hidden"
    );
    let looksPopup;
    if (looksPopupState === "hidden") {
        looksPopup = null;
    } else {
        looksPopup = (
            <LooksPopup>
                {looksPopupState == "avatar"
                    ? (<>
                        <label className='form-label' >
                            Select an Avatar
                        </label>
                        <AvatarSelection
                            avatarImage={avatarUrl}
                            setAvatarImageUrl={imageUrl => {
                                setAvatarUrlMissing(false);
                                setSelfie(false);
                                setAvatarUrl(imageUrl);
                                setLooksPopupState("hidden");
                            }}
                        />
                        <button
                            className="checkin-button"
                            onClick={() => {
                                setLooksPopupState("hidden");
                            }}
                        >
                            Close
                        </button>
                    </>)
                    : (<>
                        <label className='form-label' >
                            Smile!
                        </label>
                        <SelfieView
                            imageUrlCallback={imageUrl => {
                                setAvatarUrlMissing(false);
                                setSelfie(true);
                                setAvatarUrl(imageUrl);
                            }}
                            onClose={() => setLooksPopupState("hidden")}
                        />
                    </>)
                }
            </LooksPopup>
        );
    }

    const name = watch("name", "");
    return (
        <form
            className="customization-form"
            onSubmit={handleSubmit(values => {
                const trimmedName = values.name.trim();
                if (trimmedName.length == 0) {
                    setValue("name", trimmedName, { shouldValidate: true });
                } else if (!avatarUrl) {
                    setAvatarUrlMissing(true);
                } else {
                    props.submit(values.name, avatarUrl, isSelfie);
                }
            })}
        >
            <div className='checkin-text-input-container'>
                <label
                    htmlFor='name'
                    className='form-label'
                >
                    Name
                </label>
                <input
                    {...register("name", { required: true })}
                    placeholder={"Name"}
                    type="text"
                    className="form-input"
                />
                {errors.name && (
                    <div className='form-error-feedback'>
                        Please enter your name.
                    </div>
                )}
            </div>
            <div>
                <label className='form-label' >
                    Looks
                </label>
                <button
                    className="checkin-button"
                    type="button"
                    onClick={() => setLooksPopupState("avatar")}
                >
                    Choose Avatar
                </button>
                <button
                    className="checkin-button"
                    type="button"
                    onClick={() => setLooksPopupState("selfie")}
                >
                    Take Selfie
                </button>
            </div>
            {isAvatarUrlMissing && (
                <div className='form-error-feedback'>
                    Please select an avatar image or take a selfie.
                </div>
            )}
            {looksPopup}
            
            <div className="avatar-preview">
                <ParticipantAvatar
                    participant={{
                        id: "",
                        conversationId: "",
                        name: name,
                        avatarUrl: avatarUrl ?? "",
                    }}
                />
            </div>
            
            <button
                type="submit"
                className={"checkin-button" + (
                    !name || !avatarUrl ? " disabled-button" : ""
                )}
            >
                Check In
            </button>
        </form>
    );
});




const LooksPopup: React.FC = (props) => {
    return (
        <div className="looks-popup">
            {props.children}
        </div>
    );
};


interface AvatarSelectionProps {
    avatarImage?: string;
    setAvatarImageUrl: (avatarUrl: string) => void;
}

const AvatarSelection: React.FC<AvatarSelectionProps> = (props) => {
    const baseUrl = process.env.REACT_APP_ASSETS_URL;
    const urlPostfix = AvatarTemplates[0].url;
    const items = AvatarTemplates[0].items;
    const avatarImages = items.map(item => baseUrl + urlPostfix + item);

    return (
        <div
            className='avatar-selection-group'
            role='group'
        >
            <Grid horizontal={false} small={true} >
                {avatarImages.map((avatarImage, i) => (
                    <AvatarChoice
                        key={i}
                        index={i}
                        isSelected={props.avatarImage === avatarImage}
                        avatarImage={avatarImage}
                        onSelect={() => props.setAvatarImageUrl(avatarImage)}
                    />
                ))}
            </Grid>
        </div>
    );
};


interface AvatarChoiceProps {
    index: number;
    isSelected: boolean;
    // TODO: change type
    avatarImage: string;
    onSelect: () => void;
}

const AvatarChoice: React.FC<AvatarChoiceProps> = (props => {
    const id = `avatar-radio-${props.index}`;
    return (
        <label
            className={'avatar-option' + (props.isSelected ? ' selected' : '')}
            onClick={props.onSelect}
        >
            {/*
            <Field
                className='radio-button'
                type='radio'
                name='avatarImage'
                value={props.avatarImage}
                id={id}
            />
              */}
            <img
                className='avatar-image'
                alt={`avatar ${props.index}`}
                src={props.avatarImage}
            />
        </label>
    );
});
