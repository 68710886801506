import React from 'react';
import { gql, useMutation } from '@apollo/client';

import { UID, SID } from '../../../models';
import {
    MessageType, Message, MessageSenderCallback,
} from './models';


const SEND_MESSAGE = gql`
    mutation SendMessage(
        $chatspaceId: uuid!,
        $channelId: Int!,
        $senderId: Int!,
        $type: chat_message_type_enum!,
        $content: String!,
    ){
        insert_chat_message(objects: [{
            chatspace_id: $chatspaceId,
            channel_id: $channelId,
            sender_id: $senderId,
            type: $type,
            content: $content,
        }]) {
            returning {
                id
                sent_at
            }
        }
    }
`;

interface MessageSendVariables {
    chatspaceId: string;
    senderId: number;
    channelId: number;
    type: MessageType;
    content: string;
}

export function useMessageSender(chatspaceId: UID, chatUserId: SID): MessageSenderCallback {
    const [sendMessage] = useMutation<Message, MessageSendVariables>(SEND_MESSAGE);

    const messageSender: MessageSenderCallback = React.useCallback((
        channelId: SID, messageType: MessageType, content: string,
    ) => {
        sendMessage({variables: {
            chatspaceId: chatspaceId,
            senderId: chatUserId,
            channelId: channelId,
            type: messageType,
            content: content,
        }});
    }, [chatspaceId, chatUserId, sendMessage]);

    return messageSender;
}
