import React from 'react';

import { UserContext } from '../../../controllers/UserController';
import { ConversationContext } from '../../MeetupView/controllers/ConversationController';
import { UserMediaState, VideoCallPeer } from './models';
import { getVideoCallClient, useVideoCallConversation } from './clients/VideoCallClient';
import { useConnectionState, ConnectionState } from '../../../utils/ConnectionMonitoredClient';
import usePeerSender from './PeerSenderController';
import usePeerReceiver from './PeerReceiverController';
import useScreenShareState from './ScreenShareController';


interface VideoCallProps {
    mediaState: UserMediaState;
}

/*
 * Call model:
 * Media is always sent by initiating calls. I.e. if A and B are in a conversation,
 * A starts a call to B to send their media and vice versa. I.e. B doesn't send
 * media by answering A's call with a media stream.
 * This makes it possible for each participant to decide and update what media
 * they want to send and simplifies state management.
 * When a participant leaves the conversation, they clean up their own outgoing calls.
 */
function useVideoCall(props: VideoCallProps): [ConnectionState, VideoCallPeer[]] {
    const selfMediaState = props.mediaState;

    const user = React.useContext(UserContext)!;
    const conversationContext = React.useContext(ConversationContext);
    const conversationState = conversationContext;

    const videoCallClient = getVideoCallClient();
    const clientConnectionState = useConnectionState(videoCallClient);

    usePeerSender(conversationContext, videoCallClient, selfMediaState);
    const [receiverInitialized, peerMedia] = usePeerReceiver(
        conversationContext, videoCallClient
    );
    useScreenShareState();
    const serializedVideoCallParticipants = useSerialized(peerMedia);

    useVideoCallConversation(
        videoCallClient,
        user.participantIdentity.id,
        conversationState,
        receiverInitialized,
    );

    return [clientConnectionState, serializedVideoCallParticipants];
}


function useSerialized<K, V>(map: Map<K, V>): V[] {
    const [serialized, setSerialized] = React.useState<V[]>([]);
    React.useEffect(() => {
        setSerialized(new Array(...map.values()));
    }, [map]);
    return serialized;
}


export default useVideoCall;
