import React from 'react';
import { CSSTransition } from 'react-transition-group';

import OpenSidebarIcon from 'material-icons-svg/components/baseline/Maximize';
import CloseSidebarIcon from 'material-icons-svg/components/baseline/Minimize';
import PrevPeersIcon from 'material-icons-svg/components/baseline/ArrowLeft';
import NextPeersIcon from 'material-icons-svg/components/baseline/ArrowRight';

import { ID } from '../../../../models';
import { VideoCallPeer, SubscriptionLevel } from '../../controllers/models';
import PeerItem, { AudioOnlyPeerItem, computePeerItemProps } from '../PeerItem/PeerItem';
import './style.scss';


interface SpeakerViewProps {
    userId: ID;
    activeSpeakerId: ID;
    screenSharingPeerId?: ID;
    peers: VideoCallPeer[];
    controlsVisible: boolean;
}

const MAX_VISIBLE_SIDEBAR_PEERS = 3;

const SpeakerView: React.SFC<SpeakerViewProps> = (props) => {
    const {
        activeSpeakerId,
        screenSharingPeerId,
        peers,
        controlsVisible,
    } = props;

    const speaker = React.useMemo(() => {
        let speaker = peers[0];
        for (const peer of peers) {
            // screen sharing takes precedence over active speaker
            if (
                screenSharingPeerId !== undefined
                && peer.userId === screenSharingPeerId
            ) {
                speaker = peer;
                break;
            } else if (
                screenSharingPeerId === undefined
                && peer.userId === activeSpeakerId
            ) {
                speaker = peer;
                break;
            }
        }
        return speaker;
    }, [activeSpeakerId, screenSharingPeerId, peers]);

    const [firstVisibleSidebarPeerIdx, setFirstVisibleSidebarPeerIdx] = React.useState(0);
    const nPeers = peers.length;
    // Reset the index if people join or leave
    React.useEffect(() => (
        setFirstVisibleSidebarPeerIdx(firstVisibleSidebarPeerIdx => clipPeerIdx(
            nPeers, firstVisibleSidebarPeerIdx
        ))
    ), [nPeers]);

    const [isSidebarVisible, setSidebarVisible] = React.useState(true);
    const sidebarToggle = (
        <CSSTransition
            in={isSidebarVisible || controlsVisible}
            appear
            timeout={500}
            classNames='transition-fade'
        >
            <div className='peers-sidebar-toggle'>
                <div
                    className='peers-sidebar-control-button'
                    onClick={() => setSidebarVisible(!isSidebarVisible)}
                >
                    {isSidebarVisible
                        ? <CloseSidebarIcon className='peers-sidebar-button-icon'/>
                        : <OpenSidebarIcon className='peers-sidebar-button-icon'/>
                    }
                </div>
            </div>
        </CSSTransition>
    );

    const hasPrevPeers = firstVisibleSidebarPeerIdx > 0;
    const hasNextPeers = firstVisibleSidebarPeerIdx
            < (nPeers - MAX_VISIBLE_SIDEBAR_PEERS);
    const sidebarControls = (
        <div className='peers-sidebar-controls'>
            <div
                className={'peers-sidebar-control-button' + (
                    hasPrevPeers ? '' : ' disabled'
                )}
                onClick={() => setFirstVisibleSidebarPeerIdx(clipPeerIdx(
                    nPeers,
                    firstVisibleSidebarPeerIdx - MAX_VISIBLE_SIDEBAR_PEERS
                ))}
            >
                <PrevPeersIcon className='peers-sidebar-button-icon'/>
            </div>
            <div
                className={'peers-sidebar-control-button' + (
                    hasNextPeers ? '' : ' disabled'
                )}
                onClick={() => setFirstVisibleSidebarPeerIdx(clipPeerIdx(
                    nPeers,
                    firstVisibleSidebarPeerIdx + MAX_VISIBLE_SIDEBAR_PEERS
                ))}
            >
                <NextPeersIcon className='peers-sidebar-button-icon'/>
            </div>
        </div>
    );

    const sidebar = (
        <CSSTransition
            in={peers.length > 1 && isSidebarVisible}
            appear
            timeout={250}
            classNames='transition-size'
        >
            <div className='peers-sidebar'>
                {sidebarControls}
                {peers.map((peer, i) => (
                    (firstVisibleSidebarPeerIdx <= i
                            && i < firstVisibleSidebarPeerIdx
                            + MAX_VISIBLE_SIDEBAR_PEERS)
                        ? (
                            <PeerItem
                                key={peer.userId}
                                {...computePeerItemProps(
                                    props,
                                    peer,
                                    // TODO: this shouldn't be necessary
                                    peer.userId === speaker.userId
                                        ? SubscriptionLevel.HIGH : SubscriptionLevel.LOW,
                                    true,
                                )}
                            />
                        ) : (
                            <AudioOnlyPeerItem
                                key={peer.userId}
                                {...computePeerItemProps(
                                    props, peer, SubscriptionLevel.LOW
                                )}
                            />
                        )
                    )
                )}
            </div>
        </CSSTransition>
    );

    return (
        <div className='speaker-view'>
            <div className='speaker-area'>
                <PeerItem
                    key={speaker.userId}
                    {...computePeerItemProps(
                        props, speaker, SubscriptionLevel.HIGH
                    )}
                />
            </div>
            {sidebar}
            {sidebarToggle}
        </div>
    );
};


function clipPeerIdx(nPeers: number, peerIdx: number): number {
    return Math.max(0, Math.min(peerIdx, nPeers - MAX_VISIBLE_SIDEBAR_PEERS));
}


export default SpeakerView;
