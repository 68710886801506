import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    useRouteMatch,
} from 'react-router-dom';

import { UserContext, useUserStub, useUser } from './controllers/UserController';
import { MeetupContext, useMeetupInfo, useMeetup } from './controllers/MeetupController';
import { useSetupBackendRTC, BackendRTCContext } from './controllers/BackendRTC';
import MeetupView from './components/MeetupView/MeetupView';
import { CheckinView } from './components/CheckingView/CheckinView';
import { InfoScreen, InfoType } from './components/InfoScreen/InfoScreen';
import './style.scss';


function MeetupApp(): React.ReactElement {
    return (
        <BrowserRouter>
            <LandingPage />
        </BrowserRouter>
    );
}

function LandingPage(): React.ReactElement {
    const match: any = useRouteMatch('/:url_id');
    //console.log("match:", match);
    const urlId = match?.params.url_id;

    const meetupInfo = useMeetupInfo(urlId);
    const meetupEndTime = meetupInfo && Date.parse(meetupInfo.endTime);
    const currentTime = Date.now();
    const [userStub, checkinUser] = useUserStub(meetupInfo?.id);

    //console.log("user:", user);
    const backendRTC = useSetupBackendRTC(meetupInfo?.id, userStub?.participantId);
    const meetup = useMeetup(meetupInfo, backendRTC);
    const user = useUser(meetup, userStub);

    // TODO: add error handing here and separate errors from loading status
    let content;
    if (!match) {
        content = <InfoScreen
            message={"Please enter a valid meetup URL."}
            infoType={InfoType.ERROR}
        />
    } else if (!meetupInfo) {
        content = <InfoScreen
            message={"Cannot find a meetup under this URL.\nPlease make sure you are using the correct URL."}
            infoType={InfoType.ERROR}
        />
    } else if (meetupEndTime && meetupEndTime < currentTime) {
        content = <InfoScreen
            message={"This meetup is over :(\n\nUntil next time!"}
            infoType={InfoType.NOTICE}
        />
    } else if (!user) {
        content = <CheckinView
            meetupStub={meetupInfo}
            checkinUser={checkinUser}
        />
    } else if (!meetup) {
        content = <InfoScreen
            message={"Checking in..."}
            infoType={InfoType.LOADING}
        />
    } else {
        content = (
            <BackendRTCContext.Provider value={backendRTC}>
                <MeetupContext.Provider value={meetup}>
                    <UserContext.Provider value={user}>
                        <MeetupView />
                    </UserContext.Provider>
                </MeetupContext.Provider>
            </BackendRTCContext.Provider>
        );
    }

    //let checkinSwitch;
    //if (!match || !meetupStub) {
        //checkinSwitch = content;
    //} else {
    return (
        <div className='meetup-container' >
            <Switch>
                <Route path={`/${urlId}/checkin/`} >
                    {user
                        ? <Redirect to={`/${urlId}/`} />
                        : content
                    }
                </Route>
                <Route path={`/${urlId}/`} >
                    {meetupInfo && !user
                        ? <Redirect to={`/${urlId}/checkin/`} />
                        : content
                    }
                </Route>
                <Route path={'/'} >
                    {content}
                </Route>
            </Switch>
        </div>
    );
}

export default MeetupApp;
