import { ID } from '../../../models';


export type MediaType = "audio" | "video" | "screen";

export type MediaErrorType = "NOT_ALLOWED" | "NOT_SUPPORTED";


export interface AudioTrack {
    mediaStreamTrack: MediaStreamTrack;
    volume: () => number;
}

export interface VideoTrack {
    mediaStreamTrack: MediaStreamTrack;
    isScreen: boolean;
}

export type MediaTrack = AudioTrack | VideoTrack;

export function isVideo(mediaTrack: MediaTrack): mediaTrack is VideoTrack {
    return mediaTrack.hasOwnProperty('isScreen');
}

export function getMediaType(mediaTrack: MediaTrack): MediaType {
    if (isVideo(mediaTrack)) {
        if (mediaTrack.isScreen) {
            return "screen";
        } else {
            return "video";
        }
    } else {
        return "audio";
    }
}


export type LocalMediaTrack<T extends MediaTrack> = T | MediaErrorType;

export function hasMedia(media: LocalMediaTrack<any> | undefined): media is MediaTrack {
    if (!media)
        return false;
    return media.hasOwnProperty('mediaStreamTrack');
}

export function isError(media: LocalMediaTrack<any> | undefined): media is MediaErrorType {
    if (!media)
        return false;
    return media === "NOT_ALLOWED" || media === "NOT_SUPPORTED";
}

export interface UserMediaState {
    audio?: LocalMediaTrack<AudioTrack>;
    video?: LocalMediaTrack<VideoTrack>;
    screen?: LocalMediaTrack<VideoTrack>;
}

export interface MediaState {
    audio?: AudioTrack,
    video?: VideoTrack,
    screen?: VideoTrack,
}


export type PeerMediaType = "audio" | "video";

//export type VideoSubscriptionInfo = {
    //readonly displayWidth: number;
    //readonly displayHeight: number;
//}

// 1: high quality, 0: low quality
export type VideoSubscriptionInfo = MediaQualityLevel;

export interface VideoCallPeer {
    readonly userId: ID;
    media: MediaState;
    subscribeAudio: () => number;
    subscribeVideo: (subscriptionInfo: VideoSubscriptionInfo) => number;
    unsubscribeAudio: (subscriptionId: number) => void;
    unsubscribeVideo: (subscriptionId: number) => void;
}

//export type MediaQualityLevel = "off" | "low" | "high";
export enum SubscriptionLevel {
    OFF = 0,
    LOW,
    HIGH,
}

export type MediaQualityLevel = SubscriptionLevel.LOW | SubscriptionLevel.HIGH;
