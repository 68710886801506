//import { CometChat } from '@cometchat-pro/chat';


export const ID_KEY = "id";

export type UID = string;
export type ID = string;
export type SID = number;


export const PARTICIPANT_KEY = "participants";

export interface Participant {
    id: ID;
    name: string;
    avatarUrl: string;
    conversationId: ID | null;
}


export const CONVERSATION_KEY = "conversations";

export interface Conversation {
    id: ID;
    name?: string;
    capacity: number;
    participantIds: ID[];

    screenSharingParticipantId: ID | null;

    chatChannelId: number;
}


export interface User {
    participantIdentity: Participant;

    chatId: number;
    //videoChatUser: VideoChatUser;
    //backendToken: string;
}


export interface Meetup {
    id: ID;
    name: string;
    urlId: string;
    startTime: string;
    endTime: string;
    conversations: Map<ID, Conversation>;
    participants: Map<ID, Participant>;

    chatspaceId: string;
    rootChatChannelId: number;
    //setConversations: (conversations: Map<ID, Conversation>) => void;
}


export const INVITATION_KEY = "invitations";

export interface Invitation {
    id: ID;
    senderId: ID;
    recipientIds: ID[];
    conversationId?: ID;
    accept: () => void;
    reject: () => void;
}
