import React from 'react';

import {
    useUserMediaState,
    UserMediaActionType,
    useStreamFromMediaState
} from '../../../MeetupView/controllers/UserMediaController';
import './style.scss';


interface SelfieViewProps {
    imageUrlCallback: (imageUrl: string) => void;
    onClose: () => void;
}

const SELFIE_WIDTH = 640;
const SELFIE_HEIGHT = 480;
const SELFIE_FRAMERATE = 15;

const FRAME_INTERVAL = 1000 / SELFIE_FRAMERATE;
const SELFIE_CANVAS_SIZE = 480;

const SelfieView: React.FC<SelfieViewProps> = (props) => {
    const videoElement = React.useRef<HTMLVideoElement>(null);
    const canvasElement = React.useRef<HTMLCanvasElement>(null);

    const [userMediaState, userMediaDispatcher] = useUserMediaState({video: {
        width: SELFIE_WIDTH,
        height: SELFIE_HEIGHT,
        frameRate: SELFIE_FRAMERATE,
    }});
    React.useEffect(() => {
        userMediaDispatcher({
            actionType: UserMediaActionType.SET_VIDEO,
            enabled: true,
        });
        return () => {
            userMediaDispatcher({
                actionType: UserMediaActionType.SET_VIDEO,
                enabled: false,
            });
        };
    }, [userMediaDispatcher]);

    const userVideoStream = useStreamFromMediaState(userMediaState);
    React.useEffect(() => {
        if (!videoElement.current || !userVideoStream.active)
            return;
        videoElement.current.srcObject = userVideoStream;
        startDrawing();
    }, [userVideoStream]);

    const [imageUrl, setImageUrl] = React.useState("");

    const drawInterval = React.useRef<NodeJS.Timeout>();
    function startDrawing() {
        if (drawInterval.current)
            // already running
            return;

        const interval = setInterval(
            () => drawFrame(videoElement, canvasElement),
            FRAME_INTERVAL
        );
        drawInterval.current = interval;
    }
    function stopDrawing() {
        if (!drawInterval.current)
            return;
        clearInterval(drawInterval.current);
        drawInterval.current = undefined;
    }
    React.useEffect(() => {
        return () => stopDrawing();
    }, []);

    const imageUrlCallback = props.imageUrlCallback;
    const takeSelfie = React.useCallback(() => {
        if (!canvasElement.current)
            return;

        // Get an image dataURL from the canvas.
        const imageDataURL = canvasElement.current.toDataURL('image/jpeg', 0.9);

        // Set the dataURL as source of an image element, showing the captured photo.
        setImageUrl(imageDataURL);
        imageUrlCallback(imageDataURL);
    }, [imageUrlCallback]);

    let selfieContent;
    if (userMediaState.video) {
        selfieContent = (<>
            <div>
                <video
                    className='selfie-display'
                    style={{display: 'none'}}
                    width={SELFIE_WIDTH}
                    height={SELFIE_HEIGHT}
                    ref={videoElement}
                    autoPlay
                />
                <canvas
                    className='selfie-display'
                    ref={canvasElement}
                />
            </div>
            <div>
                <button
                    className="checkin-button"
                    onClick={props.onClose}
                >
                    Close
                </button>
                {imageUrl === ""
                    ? <button
                        className='checkin-button'
                        type='button'
                        onClick={() => {
                            stopDrawing();
                            takeSelfie();
                        }}
                    >
                        Take Selfie
                    </button>
                    : <button
                        className='checkin-button'
                        type='button'
                        onClick={() => {
                            setImageUrl("");
                            startDrawing();
                        }}
                    >
                        Retry
                    </button>
                }
            </div>
        </>);
    } else {
        selfieContent = (<>
            <div className="selfie-placeholder">
                <h3 className="selfie-prompt">
                    Please enable camera access.
                </h3>
            </div>
            <button
                className="checkin-button"
                onClick={props.onClose}
            >
                Close
            </button>
        </>);
    }
    return (
        <div className='selfie-container'>
            {selfieContent}
        </div>
    );
}


function drawFrame(
    videoElement: React.RefObject<HTMLVideoElement>,
    canvasElement: React.RefObject<HTMLCanvasElement>,
) {
    if (!videoElement.current || !canvasElement.current)
        return;

    const imageWidth = videoElement.current.videoWidth;
    const imageHeight = videoElement.current.videoHeight;

    // Set the canvas to the intended target dimensions
    canvasElement.current.width = SELFIE_CANVAS_SIZE;
    canvasElement.current.height = SELFIE_CANVAS_SIZE;

    const minImageDimension = Math.min(imageWidth, imageHeight);
    const xCrop = (imageWidth - minImageDimension) / 2;
    const yCrop = (imageHeight - minImageDimension) / 2;

    // Draw the current frame from the video on the canvas.
    // We crop out a square part from the video and render it on the
    // (square) canvas.
    const ctx = canvasElement.current.getContext('2d');
    ctx!.drawImage(
        videoElement.current,
        xCrop, yCrop,
        minImageDimension, minImageDimension,
        0, 0,
        SELFIE_CANVAS_SIZE, SELFIE_CANVAS_SIZE,
    );
}


export default SelfieView;
