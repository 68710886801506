import React from 'react';

import { ID } from '../../../models';
import { UserContext } from '../../../controllers/UserController';
import { ConversationContext } from '../../MeetupView/controllers/ConversationController';
import { VideoCallPeer, UserMediaState, hasMedia } from './models';


interface PlaybackControllerProps {
    userMedia: UserMediaState;
    peers: VideoCallPeer[];
}

export interface PlaybackState {
    activeSpeakerId: ID;
    screenSharingPeerId?: ID;
    userId: ID;
    peers: VideoCallPeer[];
    isSpeakerView: boolean;
    toggleSpeakerView?: () => void;
}


const MIN_USERS_FOR_ACTIVE_SPEAKER_VIEW = 3;

export function usePlaybackState(props: PlaybackControllerProps): PlaybackState {
    const peers = props.peers;
    const userMedia = props.userMedia;
    const user = React.useContext(UserContext);

    const userId = user!.participantIdentity.id;
    const userPeer = React.useMemo<VideoCallPeer>(() => ({
        userId: userId,
        media: {
            audio: hasMedia(userMedia.audio) ? userMedia.audio : undefined,
            video: hasMedia(userMedia.video) ? userMedia.video: undefined,
            screen: hasMedia(userMedia.screen) ? userMedia.screen: undefined,
        },
        subscribeAudio: () => 0,
        subscribeVideo: (_) => 0,
        unsubscribeAudio: (_) => 0,
        unsubscribeVideo: (_) => 0,
    }), [userId, userMedia]);
    const callMembers = React.useMemo(() => (
        [userPeer].concat(peers)
    ), [userPeer, peers]);
    
    const isSpeakerViewEnabled = callMembers.length >= MIN_USERS_FOR_ACTIVE_SPEAKER_VIEW;
    const [showSpeakerView, setShowSpeakerView] = React.useState(false);
    const activeSpeakerId = useActiveSpeaker(callMembers);

    const [screenSharingPeers, screenSharingPeerId] = useScreenSharingPeers(
        callMembers
    );
    const isScreenShareActive = screenSharingPeerId !== undefined;

    const isSpeakerView = isSpeakerViewEnabled
            && (showSpeakerView || isScreenShareActive);
    const speakerViewToggle = (isSpeakerViewEnabled && !isScreenShareActive)
        ? () => setShowSpeakerView(showSpeakerView => !showSpeakerView)
        : undefined;
    return {
        activeSpeakerId: activeSpeakerId,
        screenSharingPeerId: screenSharingPeerId,
        userId: userId,
        peers: screenSharingPeers,
        isSpeakerView: isSpeakerView,
        toggleSpeakerView: speakerViewToggle,
    }
}


const MAX_VOLUME_HISTORY_LENGTH = 8;
const VOLUME_MONITORING_INTERVAL = 100;
const ACTIVE_SPEAKER_DETECTION_INTERVAL = 1000;

// Inspired by https://github.com/otalk/hark/blob/master/hark.js
function useActiveSpeaker(peers: VideoCallPeer[]): ID {
    const peersRef = React.useRef(peers);
    React.useEffect(() => {
        peersRef.current = peers;
    }, [peers]);

    const volumeHistory = React.useRef<Map<ID, number[]>>(new Map());
    //const [maxVolumeHistory, setMaxVolumeHistory] = React.useState<Map<ID, number[]>>(new Map());
    function maxVolumeMonitoringLoop() {
        if (!peersRef.current || !volumeHistory.current)
            return;

        let maxVolume = -Infinity;
        let speakerId = "";
        peersRef.current.forEach(peer => {
            const peerId = peer.userId;
            const peerVolume = peer.media.audio?.volume() ?? 0;
            if (peerVolume > maxVolume) {
                maxVolume = peerVolume;
                speakerId = peerId;
            }
        });
        
        const maxVolumeHistory = volumeHistory.current;
        // TODO: don't create a new map at each step
        volumeHistory.current = new Map(peersRef.current.map(peer => {
            const peerHistory = maxVolumeHistory.get(peer.userId) ?? [];
            if (peerHistory.length >= MAX_VOLUME_HISTORY_LENGTH) peerHistory.shift();
            peerHistory.push(speakerId === peer.userId ? 1 : 0);
            return [peer.userId, peerHistory];
        }))
    }
    // Start and stop volume monitoring
    React.useEffect(() => {
        const interval = setInterval(
            maxVolumeMonitoringLoop, VOLUME_MONITORING_INTERVAL,
        );
        return () => clearInterval(interval);
    }, []);

    // Determine the active speaker as the one having the highest maxVolume
    // counts throughout the history window
    const [speakerId, setSpeakerId] = React.useState("");

    function speakerDetectionLoop() {
        let maxTotalVolumeCount = -1;
        let maxTotalVolumeUserId = "";

        const maxVolumeHistory = volumeHistory.current;
        for (const [userId, peerVolumeHistory] of maxVolumeHistory.entries()) {
            const maxVolumeCount = peerVolumeHistory.reduce(
                ((count, maxValue) => count + maxValue), 0,
            );
            if (maxVolumeCount > maxTotalVolumeCount) {
                maxTotalVolumeCount = maxVolumeCount;
                maxTotalVolumeUserId = userId;
            }
        }

        setSpeakerId(maxTotalVolumeUserId);
    }
    // Start and stop active speaker detection
    React.useEffect(() => {
        const interval = setInterval(
            speakerDetectionLoop, ACTIVE_SPEAKER_DETECTION_INTERVAL,
        );
        return () => clearInterval(interval);
    }, []);

    return speakerId;
}


function useScreenSharingPeers(peers: VideoCallPeer[]):
        [VideoCallPeer[], ID | undefined] {
    const conversation = React.useContext(ConversationContext).conversation;
    const screenSharingPeerId = conversation?.screenSharingParticipantId
            ?? undefined;

    const peersWithScreenSharing = React.useMemo(() =>
        peers.map(peer => {
            let media = peer.media;
            if (peer.userId === screenSharingPeerId && media.video) {
                media = {
                    ...media,
                    video: undefined,
                    screen: {
                        ...media.video,
                        isScreen: true,
                    },
                };
                return {
                    ...peer,
                    media: media,
                };
            } else {
                return peer;
            }
        }),
        [peers, screenSharingPeerId],
    );

    return [peersWithScreenSharing, screenSharingPeerId];
}
