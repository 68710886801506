import React from 'react';

import BackIcon from 'material-icons-svg/components/baseline/KeyboardArrowLeft';
import EveryoneIcon from 'material-icons-svg/components/baseline/AllInclusive';

import { SID } from '../../../../models';
import { Channel, ChannelType, ChatUser } from '../../controllers/models';

import './style.scss';


type ChannelDisplayType = "list" | "header";

interface ChannelItemProps {
    displayType: ChannelDisplayType;
    channel: Channel;
    chatUserId: number;
    users: Map<SID, ChatUser>;
    isGlobalChannel: boolean;
    isCurrentConversationChannel: boolean;
    isSelectedChannel: boolean;
    onSelectChannel: (channelId: number | undefined) => void;
}

const ChannelItem: React.FC<ChannelItemProps> = (props) => {
    const channel = props.channel;

    const filteredMembers: ChatUser[] = channel.memberIds
        .filter(memberId => memberId !== props.chatUserId)
        .map(memberId => {
            const memberUser = props.users.get(memberId);
            return memberUser ? memberUser : { id: memberId };
        });
    const membershipDescription = filteredMembers
        .map(member => member.name)
        .join(", ");
    let title;
    if (channel.type === "global") {
        title = "Everyone";
    } else if (channel.name) {
        title = channel.name;
    } else if (channel.type === "conversation") {
        title = `Conversation: ${membershipDescription}`;
    } else {
        title = membershipDescription;
    }


    let itemClickCallback = undefined;
    let backButton = undefined;
    if (props.displayType === "list") {
        itemClickCallback = () => props.onSelectChannel(channel.id);
    } else if (props.displayType === "header") {
        backButton = (
            <div
                className='back-button'
                onClick={() => props.onSelectChannel(undefined)}
            >
                <BackIcon className='back-button-icon'/>
            </div>
        );
    }
    
    const isCurrentConversationChannel = channel.type === "conversation"
            && props.isCurrentConversationChannel;
    let channelClass = 'channel-item';
    if (channel.type === "global") {
        channelClass += ' global-channel';
    } else if (isCurrentConversationChannel) {
        channelClass += ' current-conversation-channel';
    } else {
        channelClass += ' other-channel';
    }
    return (
        <div
            className={channelClass}
            onClick={itemClickCallback}
        >
            {backButton}
            <ChannelIcon
                channelType={channel.type}
                numUnreadMessages={channel.msgState?.numUnread ?? 0}
                members={filteredMembers}
            />
            <div className='description-block'>
                <div className='title-row'>
                    <span className='title'>{title}</span>
                    <ChannelLabel
                        channelType={channel.type}
                        isJoined={
                            channel.type !== "conversation"
                            || props.isCurrentConversationChannel
                        }
                    />
                </div>
                {/*
                <div className='detail-row'>
                    {membershipDescription}
                </div>
                */}
            </div>
        </div>
    );
}


interface ChannelIconProps {
    channelType: ChannelType;
    numUnreadMessages: number;
    members: ChatUser[];
}

const ChannelIcon: React.FC<ChannelIconProps> = (props) => {
    let icons;
    if (props.channelType === "global") {
        icons = (
            <div className='icon-svg-container'>
                <EveryoneIcon className='icon-svg'/>
            </div>
        );
    } else {
        icons = (<>
            {props.members.slice(0, Math.min(3, props.members.length)).map((member, i) => (
                <img
                    key={member.id}
                    className='icon-img'
                    style={{left: `${i}rem`}}
                    src={member.avatarUrl}
                    alt={member.name}
                />
            ))}
        </>);
    }

    const unreadMessagesIndicator = props.numUnreadMessages > 0 && (
        <div className='unseen-notification-indicator'>
            {props.numUnreadMessages}
        </div>
    );

    return (
        <div className='channel-icon'>
            {icons}
            {unreadMessagesIndicator}
        </div>
    );
}


interface ChannelLabelProps {
    channelType: ChannelType;
    isJoined: boolean;
}

const ChannelLabel: React.FC<ChannelLabelProps> = (props) => {
    let content;
    let labelClass;
    if (props.channelType === "global") {
        return null;
    } else if (props.channelType === "conversation") {
        if (props.isJoined) {
            content = "this conversation";
            labelClass = "active-conversation";
        } else {
            content = "left";
            labelClass = "inactive-conversation";
        }
    } else {
        content = "private";
        labelClass= "private";
    }

    return (
        <span className={`channel-label ${labelClass}`}>
            {content}
        </span>
    );
}


export default ChannelItem;
