import React from "react";


type SidebarTabState = "none" | "chat" | "invitations";

export type SetSidebarCallback = (newTabState: SidebarTabState) => void;

interface SidebarState {
    visibleTab: SidebarTabState;
    setVisibleTab: SetSidebarCallback;
}

export function useSidebarState(): SidebarState {
    const [visibleTab, setVisibleTab] = React.useState<SidebarTabState>("none");
    return {
        visibleTab: visibleTab,
        setVisibleTab: setVisibleTab,
    };
}

export const SidebarContext = React.createContext<SidebarState>({
    visibleTab: "none", setVisibleTab: () => {/*empty*/}
});
