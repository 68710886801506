import React from "react";

import { UID, SID } from '../../../../models';
import { Channel, ChatUser } from '../../controllers/models';
import MessageBubble from '../MessageBubble/MessageBubble';

import "./style.scss";


interface MessageListProps {
    chatspaceId: UID;
    chatUserId: SID;
    channel: Channel;
    users: Map<SID, ChatUser>;
}

const MessageList: React.FC<MessageListProps> = (props) => {
    const { chatspaceId, chatUserId, channel, users } = props;
    const messages = channel.msgState?.messages;
    //const chatState = React.useContext(ChatContext);
    //const channelMessages = useChannelMessages();

    const [lastMessageId, lastMessageIdString] = React.useMemo(() => {
        const id = (messages && messages.length > 0)
        ? messages[messages.length - 1].id
        : undefined;
        return id === undefined ? [undefined, undefined] : [id, id.toString()];
    }, [messages]);
    const messageListRef = React.useRef<HTMLDivElement>(null);
    const [isInitialRender, setInitialRender] = React.useState(true);
    const [isViewingListEnd, setViewingListEnd] = React.useState(false);
    // Scroll to the bottom initially and to new messages if they are
    // sent by the user or the user is viewing the bottom of the list
    React.useEffect(() => {
        if (isInitialRender && messageListRef.current && lastMessageId) {
            messageListRef.current.scroll({top: messageListRef.current.scrollHeight});
            setInitialRender(false);
        } else if (
            messageListRef.current
            && lastMessageId
            && (
                isViewingListEnd
                || lastMessageId === chatUserId
            )
        ) {
            messageListRef.current.scroll({
                top: messageListRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [
        isInitialRender,
        lastMessageId,
        isViewingListEnd,
        chatUserId,
    ]);

    const { lastReadId, setLastRead } = channel.msgState ?? { lastReadId: 0, setLastRead: () => { /* Placeholder */ } };
    const messageObserver = React.useMemo<IntersectionObserver>(() => {
        return new IntersectionObserver(
            messages => messages.forEach(message => {
                // Update whether the user is viewing the end of the list.
                // If they are, we need to scroll in new messages when they
                // arrive.
                if (message.target.id === lastMessageIdString) {
                    setViewingListEnd(message.isIntersecting);
                }
                if (message.isIntersecting) {
                    const messageId = parseInt(message.target.id);
                    if (lastReadId && messageId > lastReadId) {
                        setLastRead(messageId);
                    }
                }
            }),
            {threshold: 0.75}
        );
    }, [lastMessageIdString, lastReadId, setLastRead]);

    let messagesList;
    if (messages && messages.length > 0) {
        messagesList = messages.map((message, i, messages) => (
            <MessageBubble
                key={message.id}
                message={message}
                sender={users.get(message.senderId) ?? { id: message.senderId }}
                isSender={message.senderId === chatUserId}
                showHeader={i === 0 || messages[i - 1].senderId !== message.senderId}
                visibilityObserver={messageObserver}
            />
        ));
    } else if (messages) {
        messagesList = (
            <div className='no-content-placeholder'>
                <h3>No messages (yet).</h3>
            </div>
        );
    } else {
        messagesList = (
            <div className='no-content-placeholder'>
                <h3>Loading...</h3>
            </div>
        );
    }

    return (
        <div
            className='message-list'
            ref={messageListRef}
        >
            {messagesList}
        </div>
    );
}


export default MessageList;
