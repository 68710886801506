import React from 'react';
//import { CSSTransition } from 'react-transition-group';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import NotificationsIcon from 'material-icons-svg/components/round/NotificationsNone';
//import CloseIcon from 'material-icons-svg/components/baseline/Close';

import { BackendRTCContext } from '../../controllers/BackendRTC';
import { useConnectionState } from '../../utils/ConnectionMonitoredClient';
import { MeetupContext } from '../../controllers/MeetupController';
import { Message } from '../ChatView/controllers/models';
import { ChatContext } from '../ChatView/controllers/ChatController';
import { InvitationContext } from '../MeetupView/controllers/InvitationController';
import { SidebarContext, SetSidebarCallback } from '../MeetupSidebar/SidebarController';
//import { UserContext } from '../../controllers/UserController';
//import ParticipantAvatar from '../ParticipantAvatar/ParticipantAvatar';

import './style.scss';


const NotificationsView: React.FC = (props => {
    useConnectionStateNotifications();
    const { setVisibleTab } = React.useContext(SidebarContext);
    useChatMessageNotifications(setVisibleTab);
    useInvitationNotifications(setVisibleTab);

    return (
        <ToastContainer
            className='toast-container'
            //toastClassName='toast-item'
            position='top-center'
            autoClose={5000}
            transition={Slide}
            limit={5}
            hideProgressBar
            pauseOnHover
            pauseOnFocusLoss={false}
        />
    );
});


function useConnectionStateNotifications() {
    const backendRTC = React.useContext(BackendRTCContext);
    const backendConnectionState = useConnectionState(backendRTC!);
    React.useEffect(() => {
        if (backendConnectionState !== "CONNECTED") {
            const toastId = toast.error(
                "Lost connection to server, trying to reconnect...\n"
                    + "If that doesn't work, please reload the page.",
                {
                    autoClose: false,
                    closeOnClick: false,
                }
            );
            return () => {
                toast.dismiss(toastId);
                toast.success(
                    "Connection established!",
                    {autoClose: 2000},
                );
            }
        }
    }, [backendConnectionState]);
}


function useChatMessageNotifications(setVisibleTab: SetSidebarCallback) {
    const {
        chatUserId,
        channelCategories,
        users,
        onSelectChannel,
        addNewMessageCallback,
        removeNewMessageCallback,
    } = React.useContext(ChatContext);

    const notifyNewMessage = React.useCallback((messages: Message[]) => {
        messages.forEach(message => {
            if (
                //message.channelId === chatState.globalChannel?.id
                message.senderId === chatUserId
                || message.channelId === channelCategories.selectedChannelId
            ) {
                return;
            }

            const sender = users.get(message.senderId)
                ?? { id: message.senderId };
            toastNotification({
                icon: "fa-comment-dots",
                avatarUrl: sender.avatarUrl,
                messages: [
                    `${sender.name}`,// to ${message.channelId}`,
                    message.content
                ],
                onClick: () => {
                    setVisibleTab("chat");
                    onSelectChannel(message.channelId);
                },
            });
        });
    }, [
        chatUserId,
        channelCategories.selectedChannelId,
        users,
        onSelectChannel,
        setVisibleTab,
    ]);
    React.useEffect(() => {
        addNewMessageCallback(notifyNewMessage);
        return () => removeNewMessageCallback(notifyNewMessage);
    }, [
        addNewMessageCallback,
        removeNewMessageCallback,
        notifyNewMessage,
    ]);
}


function useInvitationNotifications(setVisibleTab: SetSidebarCallback) {
    const meetup = React.useContext(MeetupContext);
    const invitationState = React.useContext(InvitationContext);
    const [, setNotifiedInvitationIds]
            = React.useState<Set<string>>(new Set());
    React.useEffect(() => {
        setNotifiedInvitationIds(notifiedInvitationIds => {
            const updatedNotifiedInvitationIds = new Set<string>();
            for (const invitation of invitationState.receivedInvitations) {
                if (!notifiedInvitationIds.has(invitation.id)) {
                    const sender = meetup.participants.get(invitation.senderId);
                    if (!sender)
                        continue;
                    //toast.dark(`Invitation from ${sender?.name}`);
                    //
                    toastNotification({
                        icon: "fa-hand-point-right",
                        avatarUrl: sender.avatarUrl,
                        messages: [
                            `${sender.name} is inviting you`,
                            "to join a conversation."
                        ],
                        onClick: () => {
                            setVisibleTab("invitations");
                        },
                    });
                }
                updatedNotifiedInvitationIds.add(invitation.id);
            }
            return updatedNotifiedInvitationIds;
        });
    }, [invitationState.receivedInvitations, meetup.participants, setVisibleTab]);
}


interface NotificationProps {
    icon: string;
    avatarUrl: string | undefined;
    messages: string[];
    onClick: () => void;
}

function toastNotification(props: NotificationProps) {
    const notification = <Notification {...props} />;
    toast(notification, {
        className: 'toast-item',
    });
}

const Notification: React.FC<NotificationProps> = (props) => {
    return (
        <div
            className='notification'
            onClick={props.onClick}
        >
            <img
                className='avatar-icon'
                src={props.avatarUrl}
                alt={"Avatar icon"}
            />
            <i className={`fas ${props.icon} avatar-icon`} />
            <div className='message-list'>
                {props.messages.map((message, i) => (
                    <div
                        className='message-line'
                        key={i}
                    >
                        {message}
                    </div>
                ))}
            </div>
        </div>
    );
}


export default NotificationsView;
