import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { UID, SID } from '../../../models';
import { ChannelType } from './models';


const CHANNELS_QUERY = gql`
    query GetChannels($chatspaceId: uuid!, $userId: Int!) {
        channels: chat_channel(
            where: {_and: [
                {chatspace_id: {_eq: $chatspaceId}},
                {memberships: {user_id: {_eq: $userId}}},
                {_or: [
                    {messages: {id: {_gte: 0}}},
                    {type: {_eq: global}},
                    {_and: [
                        {type: {_eq: conversation}},
                        {memberships: {_and: [
                            {user_id: {_eq: $userId}},
                            {end_time: {_is_null: true}},
                        ]}},
                    ]},
                ]},
            ]}
            order_by: [
                {messages_aggregate: {max: {sent_at: asc}}},
                {created_at: asc}
            ],
        ) {
            id
            type
            name
            memberIds: memberships(
                distinct_on: [channel_id, user_id],
            ) {
                userId: user_id
            }
            lastReadMessageIds: read_statuses {
                lastReadMessageId: last_read_message_id
            }
        }
    }
`;

interface ChannelStub {
    readonly id: SID;
    readonly type: ChannelType;
    readonly name: string;
    readonly memberIds: SID[];
    readonly lastReadMessageId: SID;
}

interface ChannelQueryResult {
    channels: {
        id: SID;
        type: ChannelType;
        name: string;
        memberIds: {userId: SID}[];
        lastReadMessageIds: {lastReadMessageId: SID}[];
    }[];
}

interface ChannelQueryVariables {
    chatspaceId: UID;
    userId: SID;
}

//interface ChannelResult {
    //globalChannel?: Channel;
    //currentConversationChannel?: Channel;
    //selectedChannel?: Channel;
    //otherChannels?: Channel[];
//}

interface ChannelResult {
    channelStubs?: Map<SID, ChannelStub>;
    refreshChannels: (...channelIds: SID[]) => void;
}

export function useChannels(
    chatspaceId: string,
    userId: number,
    //globalChannelId: number | undefined,
    //currentConversationChannelId: number | undefined,
): ChannelResult {
    const {
        data,
        refetch,
    } = useQuery<ChannelQueryResult, ChannelQueryVariables>(
        CHANNELS_QUERY,
        {variables: {chatspaceId: chatspaceId, userId: userId}}
    );

    const channelStubs = React.useMemo<Map<SID, ChannelStub> | undefined>(() => {
        if (!data)
            return undefined;

        return new Map<SID, ChannelStub>(data.channels.map(channelItem => [
            channelItem.id, {
                id: channelItem.id,
                type: channelItem.type,
                name: channelItem.name,
                memberIds: channelItem.memberIds.map(member => member.userId),
                lastReadMessageId: channelItem.lastReadMessageIds[0]?.lastReadMessageId ?? 0,
            }
        ]));
    }, [data]);

    const refreshChannels = React.useCallback((...channelIds: SID[]) => {
        refetch();
    }, [refetch]);

    const channelResult = React.useMemo(() => ({
        channelStubs: channelStubs,
        refreshChannels: refreshChannels,
    }), [channelStubs, refreshChannels]);

    return channelResult;
}
