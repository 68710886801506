import React from 'react';

import { ID } from '../../../../models';
import { ConnectionMonitoredClient } from '../../../../utils/ConnectionMonitoredClient';
import { PeerMediaType, MediaTrack, MediaQualityLevel } from '../models';
import AgoraClient from './AgoraClient';
import { CurrentConversationState } from '../../../MeetupView/controllers/ConversationController';


export type PeerMediaAvailabilityCallback = (userId: ID, mediaType: PeerMediaType, available: boolean) => void;
export type PeerMediaCallback = (userId: ID, mediaType: PeerMediaType, media: MediaTrack | undefined) => void;

interface CallClient {
    join: (userId: ID, conversationId: ID) => Promise<void>;
    leave: () => void;

    publishUserMedia: (mediaTrack: MediaTrack) => void;
    unpublishUserMedia: (mediaTrack: MediaTrack) => void;
    
    onPeerMediaAvailabiiltyChanged: (callback: PeerMediaAvailabilityCallback | null) => void;
    onPeerMediaChanged: (callback: PeerMediaCallback | null) => void;
    subscribePeerMedia: (
        userId: ID, mediaType: PeerMediaType, level: MediaQualityLevel,
    ) => void;
    unsubscribePeerMedia: (userId: ID, mediaType: PeerMediaType) => void;
}

export type VideoCallClient = CallClient & ConnectionMonitoredClient;

let _videoCallClient: VideoCallClient | undefined = undefined;

export function getVideoCallClient(): VideoCallClient {
    if (!_videoCallClient) {
        _videoCallClient = new AgoraClient();
    }
    return _videoCallClient!;
}

// TODO: join and leave only once there is someone else to save on connection time
export function useVideoCallConversation(
    client: VideoCallClient,
    userId: ID,
    conversationState: CurrentConversationState,
    receiverInitialized: boolean,
): VideoCallClient {
    const conversationId = conversationState.conversation?.id ?? null;
    const joinStatus = conversationState.joinStatus;

    React.useEffect(() => {
        if (conversationId && joinStatus === "success" && receiverInitialized) {
            client.join(userId, conversationId);
            return () => {
                client.leave();
            }
        }
    }, [client, userId, conversationId, joinStatus, receiverInitialized]);

    // TODO: enable this once it becomes relevant
    //React.useEffect(() => {
        //client?.updateConversationParticipants(conversationParticipants);
    //}, [client, conversationParticipants]);

    return client;
}

