import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { MeetupContext } from '../../controllers/MeetupController';
import { useCurrentConversation, ConversationContext } from './controllers/ConversationController';
import { useUserMediaState, UserMediaContext } from './controllers/UserMediaController';
import { useInvitations, InvitationContext } from './controllers/InvitationController';
//import ConversationArea from '../ConversationArea/ConversationArea';
import MingleArea from '../MingleArea/MingleArea';
import VideoChatRoom from '../VideoChatRoom/VideoChatRoom';
import { SidebarContext, useSidebarState } from '../MeetupSidebar/SidebarController';
import Sidebar from '../MeetupSidebar/MeetupSidebar';
import HeaderButtons from './components/HeaderButtons/HeaderButtons';
import { WithChatClient } from '../ChatView/controllers/ChatClient';
import { useChat, ChatContext } from '../ChatView/controllers/ChatController';
import NotificationsView from '../NotificationsView/NotificationsView';
import NameConversationControl from '../NameConversationControl/NameConversationControl';

import './style.scss';
//import osmoteLogo from './images/logo_osmote_v1.png';
//import osmoteLogo from './images/logo_full.png';
import osmoteLogo from './images/logo_bright.png';


const MeetupView: React.FC = () => {
    const meetupContext = React.useContext(MeetupContext);
    const currentConversationState = useCurrentConversation();

    return (
        <Switch>
            <Route
                path={`/${meetupContext.urlId}`}
            >
                <WithChatClient>
                <ConversationContext.Provider value={currentConversationState}>
                    <WrappedMeetupView />
                </ConversationContext.Provider>
                </WithChatClient>
            </Route>
            <Route path={'/'} >
                <p>404 Not found.</p>
            </Route>
        </Switch>
    );
}

const WrappedMeetupView: React.FC = () => {
    //const userContext = React.useContext(UserContext);
    const userMedia = useUserMediaState();
    const invitationState = useInvitations();
    const chatState = useChat();
    const currentConversation = React.useContext(ConversationContext);
    const sidebarState = useSidebarState();

    const [
        isCreateConversationPopupVisible,
        setCreateConversationPopupVisible
    ] = React.useState(false);

    const header = (
        <div className='meetup-header'>
            <div className='logo-container'>
                <img
                    className='logo-image'
                    src={osmoteLogo}
                    //width={'100%'}
                    alt={"Osmote logo"}
                />
            </div>
            <HeaderButtons
                {...sidebarState}
                onCreateConversationClick={
                    currentConversation.conversation === null
                        ? () => setCreateConversationPopupVisible(true)
                        : undefined
                }
            />
        </div>
    );

    const notificationsView = (
        <NotificationsView />
    )

    const content = (
        <div className='meetup-content'>
            <CSSTransition
                in={currentConversation.conversation !== null}
                appear
                timeout={500}
                mountOnEnter
                unmountOnExit
                classNames='transition-size'
                // The user left the conversation, reset the fullscreen state
            >
                <VideoChatRoom />
            </CSSTransition>
            <MingleArea />
        </div>
    );

    const sidebar = (
        <Sidebar />
    );

    const body = (
        <div className='meetup-body'>
            {content}
            {sidebar}
            <NameConversationControl
                visible={isCreateConversationPopupVisible}
                onClose={() => setCreateConversationPopupVisible(false)}
            />
        </div>
    );

    return (
        <SidebarContext.Provider value={sidebarState}>
        <InvitationContext.Provider value={invitationState}>
        <UserMediaContext.Provider value={userMedia}>
        <ChatContext.Provider value={chatState}>
            <div className='meetup-view'>
                {header}
                {notificationsView}
                {body}
            </div>
        </ChatContext.Provider>
        </UserMediaContext.Provider>
        </InvitationContext.Provider>
        </SidebarContext.Provider>
    );
}

export default MeetupView;
