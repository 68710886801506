import React from 'react';

import { Selection, select } from 'd3-selection';
import {
    Simulation,
    forceSimulation,
    forceY,
    //forceManyBody,
} from 'd3-force';
import {
    D3DragEvent,
    drag,
} from 'd3-drag';

import { ID, Participant, Conversation } from '../../../../models';
import {
    SimNode,
    SimLink,
    Position,
    CONVERSATION_NODE_BASE_RADIUS,
} from '../../models';
import { forceCollide, forceConversationCluster } from './forces';
import './style.scss';


const ALPHA_DECAY = 0.1;
const CENTER_FORCE_STRENGTH = 0.005;
const COLLISION_STRENGTH = 0.4;
const CONVERSATION_CLUSTER_STRENGTH = 0.01;
//const CHARGE_STRENGTH = -10;
const DRAGGING_TARGET_ALPHA = 0.15;

const USER_NODE_RADIUS = 60;
//const CONVERSATION_NODE_RADIUS = 150;
const NODE_SEPARATION = 5;


interface NodeSimulationProps {
    participants: Participant[];
    conversations: Conversation[];
    selfUserId: ID;
    width: number;
    height: number;
    setUserNodes: (callback: (nodes: Map<ID, SimNode>) => Map<ID, SimNode>) => void;
    setConvNodes: (callback: (nodes: Map<ID, SimNode>) => Map<ID, SimNode>) => void;
    setConv: (convId: ID | null) => void;
}


function getConversationRadius(nParticipants: number): number {
    return Math.sqrt(Math.max(1, nParticipants)) * CONVERSATION_NODE_BASE_RADIUS;
}


const MingleSimulation: React.FC<NodeSimulationProps> = (props) => {
    const {
        participants,
        conversations,
        selfUserId,
        width,
        height,
        setUserNodes,
        setConvNodes,
        setConv,
    } = props;

    const areaRef = React.useRef<SVGSVGElement>(null);

    const [, setSimulation] = React.useState(
        forceSimulation<SimNode, SimLink>([])
            .force("collision", forceCollide(COLLISION_STRENGTH, NODE_SEPARATION))
            .force("convCluster", forceConversationCluster(
                CONVERSATION_CLUSTER_STRENGTH
            ))
            //.force("charge", forceManyBody().strength(CHARGE_STRENGTH))
    )

    React.useEffect(() => {
        if (!areaRef.current || width === 0 || height === 0) return;

        setUserNodes(userNodes => {
            const newUserNodes = updateUserNodes(
                participants, userNodes, width, height
            );

            setConvNodes(convNodes => {
                const newConvNodes = updateConversationNodes(
                    conversations, convNodes, newUserNodes, width, height,
                );

                const serializedNodes = new Array(...newUserNodes.values())
                    .concat(new Array(...newConvNodes.values()));

                const meetupArea = select(areaRef.current);
                const nodeSelection = meetupArea
                    .selectAll("circle")
                    .data(serializedNodes)
                    .join("circle")
                    .attr("r", item => item.r)
                    .attr("class", "simulation-item")
                    .attr("pointer-events", item => item.id === selfUserId ? "auto" : "none")
                    .attr("key", item => item.id);

                setSimulation(simulation => {
                    nodeSelection.call(dragItem(
                        simulation, newConvNodes, setConv,
                    ));

                    return simulation
                        .nodes(serializedNodes)
                        .force("y", forceY(0).strength(CENTER_FORCE_STRENGTH))
                        .on("tick", getTickCallback(
                            nodeSelection as any, width, height, setUserNodes, setConvNodes
                        ))
                        .alphaDecay(ALPHA_DECAY)
                        // "Reheat and restart the simulation
                        .alpha(1)
                        .restart();
                });

                return newConvNodes;
            });
            
            return newUserNodes;
        });
    }, [
        participants,
        conversations,
        selfUserId,
        width,
        height,
        setConvNodes,
        setUserNodes,
        setConv,
    ]);

    return (
        <svg
            className={'simulation-svg'}
            width={width}
            height={height}
            ref={areaRef}
        />
    );
}


function updateUserNodes(
    participants: Participant[],
    prevUserNodes: Map<ID, SimNode>,
    width: number,
    height: number,
): Map<ID, SimNode> {
    const newUserNodes = new Map();
    participants.forEach((participant) => {
        let node = prevUserNodes.get(participant.id);
        if (node === undefined) {
            node = {
                id: participant.id,
                type: "user",
                conversationId: participant.conversationId,
                r: USER_NODE_RADIUS,
                // Add a small perturbation to avoid complete overlap
                // which prevents proper collision detection
                x: Math.random() * width,
                //y: width / 2 + Math.random() * 0.01,
                y: Math.random() * 0.3 * height,
            }
        } else {
            node = {
                ...node,
                conversationId: participant.conversationId,
            }
        }

        newUserNodes.set(participant.id, node);
    });
    return newUserNodes;
}


function updateConversationNodes(
    conversations: Conversation[],
    prevConvNodes: Map<ID, SimNode>,
    userNodes: Map<ID, SimNode>,
    width: number,
    height: number,
): Map<ID, SimNode> {
    const newConvNodes = new Map();
    conversations.forEach((conversation) => {
        let node = prevConvNodes.get(conversation.id);

        if (node === undefined) {
            const avgUserPos = getAvgParticipantPos(
                conversation.participantIds, userNodes,
            );
            node = {
                id: conversation.id,
                type: "conv",
                conversationId: conversation.id,
                r: getConversationRadius(
                    conversation.participantIds.length
                ),
                // Add a small perturbation to avoid complete overlap
                // which prevents proper collision detection
                x: avgUserPos.x || Math.random() * width,
                y: avgUserPos.y || Math.random() * 0.3 * height,
                posInitialized: conversation.participantIds.length > 0,
            }
        } else {
            let avgPos: Position;
            let posInitialized: boolean;
            if (
                !node.posInitialized
                && conversation.participantIds.length > 0
            ) {
                avgPos = getAvgParticipantPos(
                    conversation.participantIds, userNodes,
                );
                posInitialized = true;
            } else {
                avgPos = {x: node.x ?? 0, y: node.y ?? 0};
                posInitialized = false;
            }
            node = {
                ...node,
                r: getConversationRadius(
                    conversation.participantIds.length
                ),
                x: avgPos.x,
                y: avgPos.y,
                posInitialized: posInitialized,
            }
        }

        newConvNodes.set(conversation.id, node);
    });
    return newConvNodes;
}

function getAvgParticipantPos(
    participantIds: ID[], userNodes: Map<ID, SimNode>,
): Position {
    // Compute the average position of the conversation
    // participants and center the conversation there
    let avgX = 0, avgY = 0, nUsers = 0;
    for (const userId of participantIds) {
        const userNode = userNodes.get(userId);
        if (
            !userNode
            || userNode.x === undefined
            || userNode.y === undefined
        ) continue;
        avgX += userNode.x;
        avgY += userNode.y;
        nUsers++;
    }
    nUsers = Math.max(nUsers, 1);
    avgX /= nUsers;
    avgY /= nUsers;

    return {
        x: avgX,
        y: avgY,
    };
}


type DragEvent = D3DragEvent<SVGCircleElement, SimNode, any>;

function dragItem(
    simulation: Simulation<SimNode, SimLink>,
    conversationNodes: Map<ID, SimNode>,
    setConversation: (convId: ID | null) => void,
) {
    function dragStarted(event: DragEvent, node: SimNode) {
        if (!event.active) simulation.alphaTarget(DRAGGING_TARGET_ALPHA).restart();
        node.fx = node.x;
        node.fy = node.y;
    }

    function dragged(event: DragEvent, node: SimNode) {
        node.fx = event.x;
        node.fy = event.y;
    }

    function dragEnded(event: DragEvent, node: SimNode) {
        if (!event.active) simulation.alphaTarget(0);
        const fx = node.fx;
        const fy = node.fy;
        node.fx = null;
        node.fy = null;

        if (!fx || !fy) return;
        for (const [id, convNode] of conversationNodes.entries()) {
            if (!convNode.x || !convNode.y) continue;

            const dist = Math.hypot(
                fx - convNode.x, fy - convNode.y,
            );
            if (dist < convNode.r) {
                if (node.conversationId !== id) {
                    setConversation(id);
                }
                return;
            }
        }
        setConversation(null);
    }

    return (drag() as any)
        .on("start", dragStarted)
        .on("drag", dragged)
        .on("end", dragEnded);
}


function getTickCallback(
    nodeSelection: Selection<SVGCircleElement | null, SimNode, SVGSVGElement | null, any>,
    width: number,
    height: number,
    setUserNodes: (callback: (nodes: Map<ID, SimNode>) => Map<ID, SimNode>) => void,
    setConvNodes: (callback: (nodes: Map<ID, SimNode>) => Map<ID, SimNode>) => void,
): () => void {
    return () => {
        const newUserNodes = new Map();
        const newConvNodes = new Map();

        nodeSelection
            .attr("cx", (node: SimNode) => {
                const posX = Math.max(
                    node.r, Math.min(
                        width - node.r, node.x ?? width / 2,
                    )
                );
                node.x = posX;
                return posX;
            })
            .attr("cy", (node: SimNode) => {
                const posY = Math.max(
                    node.r, node.y ?? height / 2,
                );
                node.y = posY;
                return posY;
            })
            .each(node => {
                if (node.type === "user") {
                    newUserNodes.set(node.id, node);
                } else {
                    newConvNodes.set(node.id, node);
                }
            });

        setUserNodes(_ => newUserNodes);
        setConvNodes(_ => newConvNodes);
    }
}


export default MingleSimulation;
