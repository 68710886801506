import React from "react";
import { Formik, Form, Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import "emoji-mart/css/emoji-mart.css";

import { SID } from '../../../../models';
import { MessageSenderCallback } from '../../controllers/models';
import useDetectClickOutside from '../../../../utils/OutsideClickDetector';

import { ReactComponent as InsertEmoji } from "./resources/insert_emoticon.svg"
//import { ReactComponent as RoundedPlus } from "./resources/rounded-plus-grey-icon.svg";
import { ReactComponent as SendBlue } from "./resources/send-blue-icon.svg";
import "./style.scss";


interface MessageComposerProps {
    //isEnabled: boolean;
    channelId: SID;
    sendMessage?: MessageSenderCallback;
}

interface ComposerFormValues {
    message: string;
}

const MessageComposer: React.FC<MessageComposerProps> = (props) => {
    const { channelId, sendMessage } = props;

    const [isEmojiPickerShown, setEmojiPickerShown] = React.useState(false);
    const emojiPickerRef = React.useRef<HTMLDivElement>(null);
    const emojiPickerButtonRef = React.useRef<HTMLButtonElement>(null);
    useDetectClickOutside(() => setEmojiPickerShown(false), emojiPickerRef);

    if (!sendMessage) {
        return (
            <div className='message-composer'>
                <div className='left-channel-notification'>
                    You cannot send messages anymore because you left this channel.
                </div>
            </div>
        );
    }

    const initialValues: ComposerFormValues = { message: "" };
    return (
        <div className='message-composer'>
            <Formik<ComposerFormValues>
                initialValues={initialValues}
                validate={ values => {values.message.length > 0} }
                onSubmit={ async (values, actions) => {
                    const trimmedMessage = values.message.trim();
                    if (trimmedMessage.length > 0) {
                        sendMessage(channelId, 'text', trimmedMessage);
                    }
                    actions.resetForm({values: {message: ""}});
                    setEmojiPickerShown(false);
                }}
            >
                {({ handleSubmit, setValues }) => (<>
                    <Form className='message-form'>
                        <button
                            className='emoji-button'
                            ref={emojiPickerButtonRef}
                            type='button'
                            onClick={() => {
                                setEmojiPickerShown(isShown => !isShown);
                            }}
                        >
                            <InsertEmoji className='insert-emoji-icon' />
                        </button>
                        <Field
                            className='message-text-field'
                            name="message"
                            placeholder="Write a message"
                            maxRows={8}
                            autoFocus
                            as={TextareaAutosize}
                            onKeyDown={(event: React.KeyboardEvent) => {
                                if (event.keyCode === 13 && !event.shiftKey) {
                                    event.preventDefault();
                                    handleSubmit();
                                    return true;
                                }
                            }}
                        />
                        <button
                            className='submit-button'
                            type="submit"
                        >
                            <SendBlue />
                        </button>
                    </Form>
                    {isEmojiPickerShown && (
                        <div
                            className='emoji-picker'
                            ref={emojiPickerRef}
                        >
                            <Picker
                                theme='dark'
                                emoji=''
                                native={true}
                                onSelect={(emoji) => setValues(values => ({
                                    ...values,
                                    message: values.message + (emoji as any).native
                                }))}
                            />
                        </div>
                    )}
                </>)}
            </Formik>
        </div>
    );
}

//const MessageComposer: React.FC<{}> = (props) => {
    //const imageUploaderRef = React.createRef();
    //const fileUploaderRef = React.createRef();
    //const audioUploaderRef = React.createRef();
    //const videoUploaderRef = React.createRef();
    //const messageInputRef = React.createRef();
    //const messageSending = false;
//
    //const node = React.createRef();
//
    //const [isFilePickerShown, setFilePickerShown] = React.useState(false);
    //const [isEmojiViewerShown, setEmojiViewerShown] = React.useState(false);
    //const [messageInput, setMessageInput] = React.useState("");
    //const [messageType, setMessageType] = React.useState<MessageType>('text');
//
    //function pasteHtmlAtCaret(html: string, selectPastedContent: boolean) {
        //const sel = window.getSelection();
        //if (sel && sel.getRangeAt && sel.rangeCount) {
            //let range = sel.getRangeAt(0);
            //range.deleteContents();
//
            //// Range.createContextualFragment() would be useful here but is
            //// only relatively recently standardized and is not supported in
            //// some browsers (IE9, for one)
            //var el = document.createElement("div");
            //el.innerHTML = html;
            //var frag = document.createDocumentFragment(), node, lastNode;
            //while ( (node = el.firstChild) ) {
                //lastNode = frag.appendChild(node);
            //}
            //var firstNode = frag.firstChild;
            //range.insertNode(frag);
            //
            //// Preserve the selection
            //if (lastNode) {
                //range = range.cloneRange();
                //range.setStartAfter(lastNode);
                //if (selectPastedContent) {
                    //range.setStartBefore(firstNode!);
                //} else {
                    //range.collapse(true);
                //}
                //sel.removeAllRanges();
                //sel.addRange(range);
            //}
        //}
    //}
//
    //const emojiClicked = (emoji, event) => {
        //const element = messageInputRef.current;
        //element.focus();
        //pasteHtmlAtCaret(emoji.native, false);
        //this.setState({"messageInput": element.innerText, "messageType": "text"});
    //}
    //
    //const changeHandler = (event) => {
        //const elem = event.currentTarget;
        //let messageInput = elem.textContent.trim();
        //
        //if(!messageInput.length) {
        //event.currentTarget.textContent = messageInput;
        //return false;
        //}
//
        //this.setState({"messageInput": elem.innerText, "messageType": "text"});
    //}
//
    ////toggleFilePicker = () => {
        ////const currentState = !this.state.showFilePicker;
        ////this.setState({ showFilePicker: currentState });
    ////}
////
    ////openFileDialogue = (fileType) => {
////
        ////switch (fileType) {
        ////case "image":
            ////this.imageUploaderRef.current.click();
            ////break;
        ////case "file":
            ////this.fileUploaderRef.current.click();
            ////break;
        ////case "audio":
            ////this.audioUploaderRef.current.click();
            ////break;
        ////case "video":
            ////this.videoUploaderRef.current.click();
            ////break;
        ////default:
            ////break;
        ////}
    ////}
////
    ////onImageChange = (e, messageType) => {
////
        ////if(!e.target.files[0]) {
        ////return false;
        ////}
        ////
        ////const imageInput = e.target.files[0];
        ////this.sendMediaMessage(imageInput, messageType)   
    ////}
////
    ////onFileChange = (e, messageType) => {
////
        ////if(!e.target.files[0]) {
        ////return false;
        ////}
////
        ////const fileInput = e.target.files[0];
        ////this.sendMediaMessage(fileInput, messageType)   
    ////}
////
    ////onAudioChange = (e, messageType) => {
////
        ////if(!e.target.files[0]) {
        ////return false;
        ////}
////
        ////const audioInput = e.target.files[0];
        ////this.sendMediaMessage(audioInput, messageType)   
    ////}
////
    ////onVideoChange = (e, messageType) => {
////
        ////if(!e.target.files[0]) {
        ////return false;
        ////}
////
        ////const videoInput = e.target.files[0];
        ////this.sendMediaMessage(videoInput, messageType)   
    ////}
////
    ////sendMediaMessage = (messageInput, messageType) => {
////
        ////this.toggleFilePicker();
////
        ////if(this.messageSending) {
        ////return false;
        ////}
////
        ////this.messageSending = true;
////
        ////let receiverId;
        ////let receiverType = this.props.type;
        ////if (this.props.type === "user") {
        ////receiverId = this.props.item.uid;
        ////} else if (this.props.type === "group") {
        ////receiverId = this.props.item.guid;
        ////}
////
        ////let message = new CometChat.MediaMessage(receiverId, messageInput, messageType, receiverType);
        ////if(this.props.parentMessageId) {
        ////message.setParentMessageId(this.props.parentMessageId);
        ////}
////
        ////CometChat.sendMessage(message).then(message => {
        ////this.messageSending = false;
        ////this.props.actionGenerated("messageComposed", [message])
        ////}).catch(error => {
        ////console.log("Message sending failed with error:", error);
        ////this.messageSending = false;
        ////});
    ////}
//
    //const sendMessageOnEnter = (event: Event) => {
        //if(event.keyCode === 13 && !event.shiftKey) {
            //event.preventDefault();
            //messageInputRef.current.textContent = "";
            //sendTextMessage();
            //return true;
        //}
    //}
//
    //const sendTextMessage = () => {
        //if (isEmojiViewerShown) {
            //setEmojiViewerShown(false);
        //}
//
        //if (messageInput.trim().length === 0) {
            //return false;
        //}
//
        //if (messageSending) {
            //return false;
        //}
//
        //messageSending = true;
        //let messageInput = messageInput.trim();
        //
        //let receiverId;
        //let receiverType = props.type;
        //if (props.type === "user") {
            //receiverId = props.item.uid;
        //} else if (props.type === "group") {
            //receiverId = props.item.guid;
        //}
//
        //let textMessage = new CometChat.TextMessage(receiverId, messageInput, receiverType);
        //if(this.props.parentMessageId) {
            //textMessage.setParentMessageId(this.props.parentMessageId);
        //}
        //
        //CometChat.sendMessage(textMessage).then(message => {
            //this.setState({messageInput: ""});
            //this.messageSending = false;
            //this.messageInputRef.current.textContent = "";
            //this.props.actionGenerated("messageComposed", [message]);
        //}).catch(error => {
            //console.log("Message sending failed with error:", error);
            //this.messageSending = false;
        //});
    //}
//
    //const toggleEmojiPicker = () => {
        //if (!isEmojiViewerShown) {
            //// attach/remove event handler
            //document.addEventListener('click', handleOutsideClick, false);
        //} else {
            //document.removeEventListener('click', handleOutsideClick, false);
        //}
//
        //setEmojiViewerShown(!isEmojiViewerShown);
    //}
    //
    //const handleOutsideClick = (event: Event) => {
        //// ignore clicks on the component itself
        //
        //if (node && node.contains(event.target)) {
            //return;
        //}
        //
        //toggleEmojiPicker();
    //}
//
    //let emojiPicker = null;
    //if (isEmojiViewerShown) {
        //emojiPicker = (
            //<Picker 
                //title="Pick your emoji" 
                //emoji="point_up"
                //native
                //onClick={emojiClicked}
                //style={{
                    //position: "absolute",
                    //bottom: "20px",
                    //right: "50px",
                    //zIndex: "2",
                    //width: "280px"
                //}}
            ///>
        //);
    //}
        //
//
    //const disabled = props.item.blockedByMe;
//
    //const filePickerClassName = classNames({
        //"cc1-chat-win-file-popup": true,
        //"active": (isFilePickerShown)
    //});
//
    //const inputClassName= classNames({
        //"cc1-chat-win-inpt-box": true,
        //"selectable-text": true,
        //"disabled": disabled
    //})
//
    //return (
        //<div className="cc1-chat-win-inpt-ext-wrap">
            //<div className="cc1-chat-win-inpt-int-wrap">
            //<div tabIndex={-1} className="cc1-chat-win-inpt-wrap">
                //<div
                    //className={inputClassName}
                    //contentEditable="true"
                    //placeholder="Enter your message here"
                    //dir="ltr"
                    //onInput={changeHandler}
                    //onKeyDown={sendMessageOnEnter}
                    //ref={messageInputRef}
                //></div>
                //<div className="cc1-chat-win-inpt-box-sticky">
                    //<div className="cc1-chat-win-inpt-attach-wrap">
                    //<div className="cc1-chat-win-inpt-attach" onClick={toggleFilePicker}>
                        //{[><span><img src={roundedPlus} alt="Click to upload a file" /></span><]}
                        //<span><RoundedPlus /></span>
                    //</div>
                    //<div className={filePickerClassName}>
                        //<div className="cc1-chat-win-file-type-list">
                        //<span className="cc1-chat-win-file-type-listitem video" onClick={() => { openFileDialogue("video") }}>
                        //<input onChange={(e) => onVideoChange(e, "video")} accept="video/*" type="file" ref={videoUploaderRef} />
                        //</span>
                        //<span className="cc1-chat-win-file-type-listitem audio" onClick={() => { openFileDialogue("audio") }}>
                        //<input onChange={(e) => onAudioChange(e, "audio")} accept="audio/*" type="file" ref={audioUploaderRef} />
                        //</span>
                        //<span className="cc1-chat-win-file-type-listitem image" onClick={() => { openFileDialogue("image") }}>
                            //<input onChange={(e) => onImageChange(e, "image")} accept="image/*" type="file" ref={imageUploaderRef} />
                        //</span>
                        //<span className="cc1-chat-win-file-type-listitem file" onClick={() => { openFileDialogue("file") }}>
                        //<input onChange={(e) => onFileChange(e, "file")} type="file" id="file" ref={fileUploaderRef} />
                        //</span>
                        //</div>
                    //</div>
                    //</div>
                    //<div className="cc1-chat-win-inpt-icon-wrap" ref={node => {node = node;}}>
                        //{emojiPicker}
                        //<div 
                        //className="cc1-chat-win-inpt-insert-emoji"
                        //onClick={toggleEmojiPicker}>
                            //{[><img src={insertEmoticon} alt="Insert Emoticon" /><]}
                            //<InsertEmoticon />
                        //</div>
                        //<div className="cc1-chat-win-inpt-send-btn" onClick={sendTextMessage}>
                            //{[><img src={sendBlue} alt="Send Message" /><]}
                            //<SendBlue />
                        //</div>
                    //</div>
                //</div>
            //</div>
            //</div>
        //</div>
    //);
//}

export default MessageComposer;
