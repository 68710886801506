import React from 'react';
//import { CSSTransition } from 'react-transition-group';

//import CloseIcon from 'material-icons-svg/components/baseline/Close';

import { MeetupContext } from '../../controllers/MeetupController';
//import { UserContext } from '../../controllers/UserController';
import { InvitationContext, InvitationState } from '../MeetupView/controllers/InvitationController';
import { SidebarContext } from '../MeetupSidebar/SidebarController';

import './style.scss';


const InvitationView: React.FC = (props) => {
    const meetup = React.useContext(MeetupContext);
    //const user = React.useContext(UserContext);
    const invitationState = React.useContext(InvitationContext);
    const { setVisibleTab } = React.useContext(SidebarContext);

    const closeIfNoInvitationsLeft = React.useCallback(() => {
        // This function is called when an invitation is deleted so if 1
        // invitation is left it will be deleted afterwards
        if (getTotalInvitationCount(invitationState) <= 1) {
            setVisibleTab("none");
        }
    }, [invitationState, setVisibleTab]);

    const sentInvitation = invitationState.sentInvitation;
    let sentInvitationItem;
    if (sentInvitation) {
        //const userParticipant = meetup.participants.get(sentInvitation.senderId);
        const recipientParticipants = sentInvitation.recipientIds.map(
            recipientId => meetup.participants.get(recipientId)
        );
        sentInvitationItem = (
            <div
                key={sentInvitation.id}
                className='invitation'
            >
                <div className='message'>
                    <AvatarIcon
                        avatarUrl={recipientParticipants[0]?.avatarUrl ?? ""}
                        avatarName={recipientParticipants[0]?.name ?? ""}
                    />
                    <span>
                        <p>
                            <span className='emphasis'>
                                You
                            </span> are inviting {recipientParticipants.map(participant => participant?.name).join(", ")} to join a conversation.
                        </p>
                    </span>
                </div>
                <div className='response'>
                    <button
                        className='response-button'
                        onClick={() => {
                            closeIfNoInvitationsLeft();
                            sentInvitation.reject();
                        }}
                    >
                        Cancel invitation
                    </button>
                </div>
            </div>
        );
    }

    const receivedInvitationItems = invitationState.receivedInvitations.map(invitation => {
        const senderParticipant = meetup.participants.get(invitation.senderId)!;
        return (
            <div
                key={invitation.id}
                className='invitation'
            >
                <div className='message'>
                    <AvatarIcon
                        avatarUrl={senderParticipant.avatarUrl}
                        avatarName={senderParticipant.name}
                    />
                    <span>
                        <p>
                            <span className='emphasis'>
                                {senderParticipant.name}
                            </span> is inviting you to join a conversation.
                        </p>
                    </span>
                </div>
                <div className='response'>
                    <button
                        className='response-button'
                        onClick={() => {
                            closeIfNoInvitationsLeft();
                            invitation.accept()
                        }}
                    >
                        Join
                    </button>
                    <button
                        className='response-button'
                        onClick={() => {
                            closeIfNoInvitationsLeft();
                            invitation.reject()
                        }}
                    >
                        Decline
                    </button>
                </div>
            </div>
        );
    });

    return (
        <div className='invitations-view'>
            {sentInvitationItem || receivedInvitationItems.length > 0
                ? [sentInvitationItem, receivedInvitationItems]
                : <span className='no-content-placeholder'>
                    <h3>No invitations (yet).</h3>
                </span>
            }
        </div>
    );
};


interface AvatarIconProps {
    avatarUrl: string;
    avatarName: string;
}

const AvatarIcon: React.FC<AvatarIconProps> = (props) => {
    return (
        <img
            className='avatar-icon-img'
            src={props.avatarUrl}
            alt={props.avatarName + " icon"}
        />
    );
}


function getTotalInvitationCount(invitationState: InvitationState) {
    return (invitationState.sentInvitation === undefined ? 0 : 1)
        + invitationState.receivedInvitations.length;
}

export default InvitationView;
