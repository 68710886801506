import React from 'react';

import './style.scss';


interface GridProps {
    horizontal?: boolean;
    small?: boolean;
    showScrollbars?: boolean;
    children: React.ReactNode;
}

function Grid(props: GridProps): React.ReactElement {
    let classes = 'grid-container vertical-grid';
    //if (props.horizontal !== undefined && props.horizontal === true) {
        //classes += ' horizontal-grid';
    //} else {
        //classes += ' vertical-grid';
    //}
    if (props.small !== undefined && props.small === true) {
        classes += ' small';
    } else {
        classes += ' big';
    }

    return (
        <div
            className={classes}
            style={
                props.showScrollbars !== undefined
                && props.showScrollbars === false
                    ? {'overflow': 'visible'}
                    : {}
            }
        >
            {props.children}
        </div>
    );
}

export default Grid;

