import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { MeetupContext } from '../../../controllers/MeetupController';
import { UserContext } from '../../../controllers/UserController';
import { useBackendRTC } from '../../../controllers/BackendRTC';
import { ID, Conversation, ID_KEY, CONVERSATION_KEY } from '../../../models';


const JOIN_ACTION = "join";


export type ConversationJoinStatus = "joining" | "success";

interface ConversationStatus {
    currentConversationId: ID|null;
    joinStatus: ConversationJoinStatus;
}

export interface CurrentConversationState {
    joinStatus: ConversationJoinStatus;
    conversation: Conversation|null;
}

export function useCurrentConversation(): CurrentConversationState {
    // TODO: handle the case where users join conversations while being in
    // the process of joining other ones
    // TODO: send incremental version numbers with join requests that
    // need to be returned from the server and only update if the number
    // is up to date
    const backendRTC = useBackendRTC();
     
    const userContext = React.useContext(UserContext);
    const serverConversationId = userContext!.participantIdentity.conversationId;

    const [clientConversationId, navigateToConversation] = useConversationNavigation();

    React.useEffect(() => {
        // The client changed the conversation, update the server
        if (backendRTC && clientConversationId !== serverConversationId) {
            backendRTC.send(CONVERSATION_KEY, JOIN_ACTION, {[ID_KEY]: clientConversationId});
        }
    // We only want this hook to run when the server-side conversation id changes, not any of the
    // other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientConversationId]);

    React.useLayoutEffect(() => {
        // The server changed the users's conversation, update the client url
        navigateToConversation(serverConversationId);
    // We only want this hook to run when the server-side conversation id changes, not any of the
    // other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverConversationId]);

    const conversationStatus = React.useMemo<ConversationStatus>(() => ({
        currentConversationId: clientConversationId,
        joinStatus: clientConversationId === serverConversationId
                ? "success" : "joining",
    }), [clientConversationId, serverConversationId]);

    const meetup = React.useContext(MeetupContext);
    const conversations = meetup.conversations;
    const currentConversationState = React.useMemo<CurrentConversationState>(() => ({
        joinStatus: conversationStatus.joinStatus,
        conversation: conversationStatus.currentConversationId
            ? conversations.get(conversationStatus.currentConversationId)!
            : null,
    }), [conversationStatus, conversations]);

    return currentConversationState;
}


export function useConversationNavigation(): [ID|null, (targetConversationId: ID|null) => void] {
    const meetup = React.useContext(MeetupContext);
    const match: any = useRouteMatch(`/${meetup.urlId}/conversation/:id`);
    const history = useHistory();

    const matchId = match?.params.id ?? null;
    const navigateToConversation = React.useCallback((targetConversationId: ID | null) => {
        // The server changed the users's conversation, update the client url
        if (targetConversationId) {
            const targetUrl = `/${meetup.urlId}/conversation/${targetConversationId}`;
            if (matchId) {
                // Already in a /conversation/:id location, just replace the current one
                history.replace(targetUrl);
            } else {
                // Still at the root location, push the conversation to be able to use
                // back-navigation to leave
                history.push(targetUrl);
            }
        } else {
            history.replace(`/${meetup.urlId}/`);
        }
    }, [
        matchId,
        history,
        meetup.urlId,
    ])

    return React.useMemo(() => {
        return [matchId, navigateToConversation];
    }, [matchId, navigateToConversation]);
}


export const ConversationContext = React.createContext<CurrentConversationState>({
    joinStatus: "success",
    conversation: null,
});
ConversationContext.displayName = 'ConversationContext';
