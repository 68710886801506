import React from 'react';
//import { useDetectClickOutside } from 'react-detect-click-outside';

import NoAvatarIcon from 'material-icons-svg/components/outline/AccountCircle';

import { ID, Participant } from '../../../../models';
import { UserContext } from '../../../../controllers/UserController';
import './style.scss';

interface ParticipantAvatarProps {
    participant: Participant;
    includeName?: boolean;
    setMenu?: (participantId: ID | null) => void;
    avatarRef?: React.RefObject<HTMLDivElement>;
}

const ParticipantAvatar: React.FC<ParticipantAvatarProps> = (props) => {
    const { participant, includeName, setMenu, avatarRef } = props;

    const user = React.useContext(UserContext);
    const isUserParticipant = participant.id === user?.participantIdentity.id;

    const participantAvatar = (
        <>
            <div
                key={participant.id + "_icon"}
                className='participant-avatar'
                onClick={setMenu && !isUserParticipant
                    ? () => setMenu(avatarRef === undefined ? participant.id : null)
                    : undefined
                }
                ref={avatarRef}
            >
                {participant.avatarUrl
                    ? <img
                        className={'participant-icon' + (isUserParticipant ? ' participant-halo' : '')}
                        src={participant.avatarUrl}
                        //src={imgUrl}
                        alt='participant avatar'
                        draggable={false}
                    />
                    : <NoAvatarIcon className='participant-icon' />
                }
                {(includeName ?? true)
                    && <div
                        className='participant-label'
                        draggable={false}
                    >
                        {participant.name}
                    </div>
                    //&& <div className='participant-label'>Alexander</div>
                }
            </div>
            <div
                key={participant.id + "_shadow"}
                className={'participant-shadow '
                    + (isUserParticipant ? 'is-self-user' : 'is-other-user')
                }
            />
        </>
    );

    return (
        <div className='participant-avatar-container'>
            {participantAvatar}
        </div>
    );
}


export default ParticipantAvatar;
