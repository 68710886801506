import React from 'react';

import { SID } from '../../../models';
import { MeetupContext } from '../../../controllers/MeetupController';
import { UserContext } from '../../../controllers/UserController';
import { ConversationContext } from '../../MeetupView/controllers/ConversationController';
import { Channel, Message, ChatUser } from './models';
import { useMessages } from './MessageController';
import { useChannels } from './ChannelController';
import { useChatUsers } from './ChatUserController';
//import { useUnreadMessages } from './UnreadMessageController';


interface ChannelCategories {
    globalChannelId?: SID;
    currentConversationChannelId?: SID;
    selectedChannelId?: SID;
    otherChannelIds?: SID[],
}

type NewMessagesCallback = (messages: Message[]) => void;

interface ChatState {
    chatspaceId: string;
    chatUserId: number;
    channelCategories: ChannelCategories;
    channels?: Map<SID, Channel>;
    users: Map<SID, ChatUser>;
    //unreadMessages?: Message[];
    onSelectChannel: (channelId: number | undefined) => void;
    addNewMessageCallback: (callback: NewMessagesCallback) => void;
    removeNewMessageCallback: (callback: NewMessagesCallback) => void;
}


// TODO: possibly refactor chat state handling to more heavily rely on
// the Apollo cache
export function useChat(): ChatState {
    const meetup = React.useContext(MeetupContext);
    const chatspaceId = meetup.chatspaceId;
    const user = React.useContext(UserContext);
    const chatUserId = user!.chatId;

    const { channelStubs, refreshChannels } = useChannels(
        chatspaceId, chatUserId,
    );
    const { users, refreshUsers } = useChatUsers(chatspaceId);


    const [, setMessageCallbacks] = React.useState<Set<NewMessagesCallback>>(new Set());
    const onNewMessages = React.useCallback((messages: Message[]) => {
        setMessageCallbacks(messageCallbacks => {
            messageCallbacks.forEach(callback => callback(messages))
            return messageCallbacks;
        });

        const channelsToUpdate: SID[] = [];
        const usersToUpdate: SID[] = [];
        messages.forEach(message => {
            if (channelStubs && !channelStubs.has(message.channelId)) {
                // A message in a new channel that is not being tracked was
                // received, refresh the channel list
                channelsToUpdate.push(message.channelId);
            }
            if (users && !users.has(message.senderId)) {
                usersToUpdate.push(message.senderId);
            }
        });
        if (channelsToUpdate.length > 0) {
            refreshChannels(...channelsToUpdate);
        }
        if (usersToUpdate.length > 0) {
            refreshUsers(...usersToUpdate);
        }
    }, [channelStubs, refreshChannels, users, refreshUsers]);


    const [selectedChannelId, setSelectedChannelId] = React.useState<number>();
    const messageState = useMessages(
        chatspaceId, chatUserId, selectedChannelId, onNewMessages,
    );


    const [channels, setChannels] = React.useState<Map<SID, Channel> | undefined>();
    React.useEffect(() => {
        if (!channelStubs) {
            setChannels(undefined);
            return;
        }

        setChannels(prevChannels => {
            const channels: Map<SID, Channel> = prevChannels === undefined
                ? new Map() : new Map(prevChannels);

            channelStubs.forEach((channelStub, channelId) => {
                //const channelConversation = channelConversations.get(channelStub.id);
                const prevChannel = channels.get(channelId);
                const channelMessageState = messageState.get(channelId);

                if (
                    prevChannel
                    && prevChannel.name === channelStub.name
                    && prevChannel.type === channelStub.type
                    && prevChannel.memberIds === channelStub.memberIds
                    && prevChannel.msgState === channelMessageState
                ) {
                    channels.set(channelId, prevChannel);
                } else {
                    channels.set(channelId, {
                        ...channelStub,
                        msgState: channelMessageState,
                    });
                }
            });

            return channels;
        });
    }, [channelStubs, messageState]);


    // Refresh the list of channels if the user joins a new conversation that's
    // not in the channels list or if the user leaves a conversation without
    // messages
    const currentConversation = React.useContext(ConversationContext);
    const currentConversationChannelId = currentConversation.conversation?.chatChannelId;
    React.useEffect(() => {
        if (
            channels
            && currentConversationChannelId
            && !channels.has(currentConversationChannelId)
        ) {
            refreshChannels(currentConversationChannelId);
        } else if (channels && !currentConversationChannelId) {
            for (const channel of channels.values()) {
                if (
                    channel.type === "conversation"
                    && channel.msgState
                    && channel.msgState.messages.length === 0
                ) {
                    // TODO: try to do this update only locally, no need to refetch
                    refreshChannels(channel.id);
                }
            }
        }
    }, [channels, currentConversationChannelId, refreshChannels]);


    const globalChannelId = meetup.rootChatChannelId;
    const channelCategories = React.useMemo(() => ({
        globalChannelId: globalChannelId,
        currentConversationChannelId: currentConversationChannelId,
        selectedChannelId: selectedChannelId,
        otherChannelIds: (channelStubs ? [...channelStubs.values()] : [])
            ?.filter(({ id }) => (
                id !== globalChannelId && id !== currentConversationChannelId
            )).map(({ id }) => id),
    }), [
        globalChannelId,
        currentConversationChannelId,
        selectedChannelId,
        channelStubs,
    ]);


    const addNewMessageCallback = React.useCallback((callback: NewMessagesCallback) => {
        setMessageCallbacks(messageCallbacks => {
            const newMessageCallbacks = new Set(messageCallbacks);
            newMessageCallbacks.add(callback);
            return newMessageCallbacks;
        });
    }, []);
    const removeNewMessageCallback = React.useCallback((callback: NewMessagesCallback) => {
        setMessageCallbacks(messageCallbacks => {
            const newMessageCallbacks = new Set(messageCallbacks);
            newMessageCallbacks.delete(callback);
            return newMessageCallbacks;
        });
    }, []);

    const chatState: ChatState = React.useMemo<ChatState>(() => {
        return {
            chatspaceId: chatspaceId,
            chatUserId: chatUserId,
            channelCategories: channelCategories,
            channels: channels,
            users: users,
            onSelectChannel: setSelectedChannelId,
            addNewMessageCallback: addNewMessageCallback,
            removeNewMessageCallback: removeNewMessageCallback,
        };
    }, [
        chatspaceId,
        chatUserId,
        channelCategories,
        channels,
        users,
        addNewMessageCallback,
        removeNewMessageCallback,
    ]);

    return chatState;
}

export const ChatContext = React.createContext<ChatState>({
    chatspaceId: "",
    chatUserId: 0,
    channelCategories: {},
    users: new Map(),
    onSelectChannel: () => { /* Placeholder */ },
    addNewMessageCallback: () => { /* Placeholder */ },
    removeNewMessageCallback: () => { /* Placeholder */ },
});
ChatContext.displayName = "ChatContext";
