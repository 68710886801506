import React from 'react';


const INACTIVITY_TIMEOUT = 5000;

function useUserActivity(timeout?: number): [boolean, () => void] {
    const timeoutId = React.useRef<number>();
    const [userActive, setUserActive] = React.useState(true);

    const resetTimer = React.useCallback(async () => {
        window.clearTimeout(timeoutId.current);
        const timerId = window.setTimeout(
            () => { setUserActive(false); },
            timeout ? timeout : INACTIVITY_TIMEOUT
        );
        timeoutId.current = timerId;

        setUserActive(true);
    }, [timeout]);

    function activityListener() {
        resetTimer();
    }

    React.useEffect(() => {
        resetTimer()
        return () => window.clearTimeout(timeoutId.current);
    }, [resetTimer]);

    return [userActive, activityListener];
}

export default useUserActivity;
