import React from 'react';

import MicrophoneIcon from 'material-icons-svg/components/baseline/Mic';
import MicrophoneOffIcon from 'material-icons-svg/components/baseline/MicOff';
import CameraIcon from 'material-icons-svg/components/baseline/Videocam';
import CameraOffIcon from 'material-icons-svg/components/baseline/VideocamOff';
import ScreenShareIcon from 'material-icons-svg/components/baseline/ScreenShare';
import StopScreenShareIcon from 'material-icons-svg/components/baseline/StopScreenShare';
import SpeakerViewIcon from 'material-icons-svg/components/baseline/Portrait';
import GridViewIcon from 'material-icons-svg/components/baseline/Group';

import { UserMediaState, hasMedia, } from '../../controllers/models';
import { UserMediaActionType, UserMediaDispatcher } from '../../../MeetupView/controllers/UserMediaController';
import ControlButton from '../ControlButton/ControlButton';
import './style.scss';


interface MediaControlProps {
    mediaState: UserMediaState;
    mediaDispatcher: UserMediaDispatcher;
    isSpeakerView: boolean;
    toggleSpeakerView?: () => void;
}

function MediaControls(props: MediaControlProps): React.ReactElement {
    const {
        mediaState,
        mediaDispatcher,
        isSpeakerView,
        toggleSpeakerView,
    } = props;

    function triggerAction(actionType: UserMediaActionType) {
        return () => {
            mediaDispatcher({
                actionType: actionType,
            });
        }
    }

    return (
        <div className='video-chat-footer' >
            <ControlButton
                onClick={triggerAction(UserMediaActionType.TOGGLE_AUDIO)}
                icon={hasMedia(mediaState.audio)
                    ? MicrophoneIcon
                    : MicrophoneOffIcon
                }
            />
            <ControlButton
                onClick={triggerAction(UserMediaActionType.TOGGLE_VIDEO)}
                icon={hasMedia(mediaState.video)
                    ? CameraIcon
                    : CameraOffIcon
                }
                disabled={mediaState.screen !== undefined}
            />
            <ControlButton
                onClick={triggerAction(UserMediaActionType.TOGGLE_SCREEN_SHARE)}
                icon={hasMedia(mediaState.screen)
                    ? ScreenShareIcon
                    : StopScreenShareIcon
                }
            />
            {toggleSpeakerView !== undefined && (
                <ControlButton
                    onClick={() => toggleSpeakerView && toggleSpeakerView()}
                    icon={isSpeakerView
                        ? SpeakerViewIcon
                        : GridViewIcon
                    }
                    disabled={toggleSpeakerView === undefined}
                />
            )}
        </div>
    );
}

export default MediaControls;

