import React from 'react';

import { Channel } from '../../controllers/models';
import { ChatContext } from '../../controllers/ChatController';
import ChannelItem from '../ChannelItem/ChannelItem';
import MessageList from '../MessageList/MessageList';
import MessageComposer from '../MessageComposer/MessageComposer';
//import MessageComposer from '../MessageComposer/MessageComposer.jsx';
import './style.scss';


const MessageView: React.FC = (props) => {
    const chatState = React.useContext(ChatContext);
    const channels = chatState.channels;
    const channelCategories = chatState.channelCategories;
    const selectedChannelId = channelCategories.selectedChannelId;
    const channel = channels?.get(selectedChannelId ?? -1);
    if (channel === undefined) {
        return <div>Loading Channel...</div>;
    }

    const channelHeader = (
        <ChannelItem
            displayType="header"
            channel={channel}
            users={chatState.users}
            chatUserId={chatState.chatUserId}
            isGlobalChannel={channel.id === channelCategories.globalChannelId}
            isCurrentConversationChannel={
                channel.id === channelCategories.currentConversationChannelId
            }
            isSelectedChannel={true}
            onSelectChannel={chatState.onSelectChannel}
        />
    );

    return (
        <div className='message-view' key={selectedChannelId}>
            {channelHeader}
            {/*
            <div className='message-view-header'>
                <button
                    className='button'
                    onClick={() => chatState.onSelectChannel(undefined)}
                >
                    Back
                </button>
                <h3>Channel</h3>
            </div>
            */}
            <div className='message-list-container'>
                <MessageList
                    chatspaceId={chatState.chatspaceId}
                    chatUserId={chatState.chatUserId}
                    channel={channel}
                    users={chatState.users}
                />
            </div>
            <div className='message-composer-container'>
                <MessageComposer
                    channelId={channel.id}
                    sendMessage={
                        (channel.type !== 'conversation'
                        || channel.id === channelCategories.currentConversationChannelId)
                            ? channel.msgState?.send : undefined
                    }
                />
            </div>
        </div>
    );
}


export default MessageView;
